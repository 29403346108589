import { createSlice } from "@reduxjs/toolkit";

type HoroscopePredictionState = {
  [period in Period]: {
    type: string;
    sign: string;
    date: string;
    prediction: {
      predictionText?: string;
      love: Prediction;
      health: Prediction;
      career: Prediction;
      luckyColorCode: string;
      luckyColorName: string;
      luckyNumbers: string[];
      luckyAlphabets: string[];
    };
  };
};

type Period = "daily" | "weekly" | "monthly" | "yearly";

type Prediction = {
  percent: number;
  text: string;
};

const initialState: HoroscopePredictionState = {
  daily: {
    type: "daily",
    sign: "",
    date: "",
    prediction: {
      predictionText:
        "Embrace the power of productivity with today's horoscope. Channel your energy into strategic planning and efficient organization. A focused mind, coupled with disciplined actions, can lead to significant accomplishments.",
      love: { percent: 0, text: "" },
      health: { percent: 0, text: "" },
      career: { percent: 0, text: "" },
      luckyColorCode: "",
      luckyNumbers: [],
      luckyAlphabets: [],
      luckyColorName: "",
    },
  },
  weekly: {
    type: "weekly",
    sign: "",
    date: "",
    prediction: {
      love: { percent: 0, text: "" },
      health: { percent: 0, text: "" },
      career: { percent: 0, text: "" },
      luckyColorCode: "",
      luckyNumbers: [],
      luckyAlphabets: [],
      luckyColorName: "",
    },
  },
  monthly: {
    type: "monthly",
    sign: "",
    date: "",
    prediction: {
      love: { percent: 0, text: "" },
      health: { percent: 0, text: "" },
      career: { percent: 0, text: "" },
      luckyColorCode: "",
      luckyNumbers: [],
      luckyAlphabets: [],
      luckyColorName: "",
    },
  },
  yearly: {
    type: "yearly",
    sign: "",
    date: "",
    prediction: {
      love: { percent: 0, text: "" },
      health: { percent: 0, text: "" },
      career: { percent: 0, text: "" },
      luckyColorCode: "",
      luckyNumbers: [],
      luckyAlphabets: [],
      luckyColorName: "",
    },
  },
};

export const horoscopeSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setHoroscopeData: (state, action) => {
      state.daily.sign = action.payload.dailyRes.astrologicData.sign;
      state.daily.prediction = {
        ...action.payload.dailyRes.astrologicData.prediction,
        predictionText:
          action.payload.dailyRes.astrologicData.prediction.personal,
      };
      state.daily.date = action.payload.dailyRes.astrologicData.date;
      state.weekly.prediction = {
        ...action.payload.weeklyRes.astrologicData.weekly_horoscope,
      };
      state.daily.date = action.payload.weeklyRes.date;

      state.monthly.prediction = {
        ...action.payload.monthlyRes.astrologicData.monthly_horoscope,
      };
      state.daily.date = action.payload.monthlyRes.date;
      state.yearly.prediction = {
        ...action.payload.yearlyRes.astrologicData.yearly_horoscope,
      };
      state.daily.date = action.payload.yearlyRes.date;
    },
  },
});

export const { setHoroscopeData } = horoscopeSlice.actions;

export default horoscopeSlice.reducer;
