import React, { useEffect, useRef, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import { useNavigate } from "react-router-dom";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import DocumentIcon from "assets/svg/document";
import DefaultButton from "components/buttons/DefaultButton";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import { addClassToHiddenModalDiv } from "utils/defaults";
import CloseBtnIcon from "assets/svg/close-btn";
import ArrowLeft from "assets/svg/arrow left.svg";
import classNames from "classnames";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
// import { Bounce, toast } from "react-toastify";
import http from "../../helpers/http";
import { clearPersistor } from "../../store";

const Settings = () => {
  const navigate = useNavigate();
  const navigateToPurchases = () => navigate("/full-compatibility-report");
  const navigateToManageSubscription = () => navigate("/change-subscription");

  const [isDeleteBottomModalOpen, toggleDeleteBottomModal] = useState(false);
  const [reasonForCancellationType, setReasonType] = useState("");

  useEffect(() => {
    addClassToHiddenModalDiv();
    ReactPixel.track("Settings Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/settings",
      title: "Settings Page",
    });
    window.scrollTo(0, 0);
  }, []);

  // const notify = () =>
  //   toast("🦄 Wow so easy!", {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //     transition: Bounce,
  //   });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSelectReasonType = (reason: string) => {
    if (reason === "tooExpensive") {
      navigate("/cancel-subscription-discount", {
        state: { from: "delete", deleteReason: "too expensive" },
      });
    } else if (reason === "notExcited") {
      navigate("/cancel-subscription-not-excited", {
        state: {
          from: "delete",
          deleteReason: "Not excited with provided services",
        },
      });
    } else if (reason === "technicalProblems") {
      navigate("/cancel-subscription-technical-problems", {
        state: { from: "delete", deleteReason: "I have technical problems" },
      });
    } else {
      setReasonType(reason);
    }
  };

  const [isContentFit, setISContentFit] = useState(true);

  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentWrapperRef.current instanceof HTMLElement) {
      const pageHeight = document.documentElement.clientHeight;
      console.log(
        "pageHeight",
        contentWrapperRef.current.offsetHeight,
        pageHeight,
      );
      const contentHeight =
        contentWrapperRef.current.offsetHeight / (pageHeight / 100);
      console.log("contentHeight", contentHeight);
      setISContentFit(contentHeight <= 60);
    }
  }, []);

  const headerDeleteBottomModal = () => {
    return (
      <div className="flex i-align-s justify-content-space-between mb24">
        {reasonForCancellationType === "other" ? (
          <h2>Please let us know the reason for deleting your account</h2>
        ) : (
          <h2>Please let us know the reason for deleting your account</h2>
        )}
        <div
          className="flex i-align-c pointer"
          style={{ paddingTop: 4 }}
          onClick={() => {
            setReasonType("");
            toggleDeleteBottomModal((prev) => !prev);
          }}
        >
          <CloseBtnIcon />
        </div>
      </div>
    );
  };

  const deleteAccount = async () => {
    try {
      await http.delete("/settings/delete-account");
      navigate("/account-deleted");
      await clearPersistor();
    } catch (e) {
      console.log(e);
    }
  };

  const contentCancelBottomModal = () => {
    return (
      <>
        {reasonForCancellationType === "other" ? (
          <>
            <textarea
              wrap={"hard"}
              placeholder="Share your cancellation reason..."
            />
          </>
        ) : (
          <>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 pointer"
              onClick={() => onSelectReasonType("tooExpensive")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Too expensive
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("notExcited")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Not excited with provided services
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("technicalProblems")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                I have technical problems
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("other")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Other
              </span>
            </div>
          </>
        )}
        {reasonForCancellationType && (
          <div
            className=" mt16 flex position-absolute justify-content-space-between full-width"
            style={{ bottom: 32, left: 0 }}
          >
            <button
              className="linkBtn font-s13 fMontserrat font-w500"
              onClick={() => setReasonType("")}
            >
              <img src={ArrowLeft} alt="" /> Back
            </button>
            <button
              className="linkBtn font-s13 fMontserrat font-w500 i-align-self-end"
              onClick={deleteAccount}
            >
              Delete account
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <Layout>
      <div className="container">
        <div className="t-align-l position-relative" style={{ height: "94%" }}>
          <HeaderWithBack />
          <div ref={contentWrapperRef}>
            <h1 className="yellow-text">Settings</h1>
            <div className="padding-h18 padding-v18 t-align-c custom-border-gradient mt16">
              <h3>
                Unlock
                <span className="yellow-text">
                  {" "}
                  Personalized Compatibility{" "}
                </span>
                Insights
              </h3>
              <p className="fRoboto font-s16 font-w400 text-white mt8">
                Discover unique connections with a detailed report tailored to
                your specifications.
              </p>
              <div className="flex mt16">
                <button
                  className="linkBtn font-s13 fMontserrat font-w500"
                  onClick={navigateToPurchases}
                >
                  Unlock Insights
                  <BigArrowRightIcon />
                </button>
              </div>
            </div>
            <div className="flex gap8 mt24 fMontserrat text-color-silver font-s14">
              <ElBgContainer customClassNames="full-width padding-v16 padding-h18">
                <div
                  onClick={() => navigate("/privacy-policy")}
                  className="gap8 i-align-c flex flex-column pointer"
                >
                  <DocumentIcon fill={"#3F5497"} />
                  <p>Privacy Policy</p>
                  <div className=" mt8 flex i-align-self-end">
                    <button
                      className="linkBtn font-s13 fMontserrat font-w500"
                      onClick={() => console.log("click")}
                    >
                      Read <BigArrowRightIcon />
                    </button>
                  </div>
                </div>
              </ElBgContainer>
              <ElBgContainer customClassNames="full-width padding-v16 padding-h12 gap8 i-align-c flex flex-column">
                <DocumentIcon fill={"#5A8C8D"} />
                <p>Terms of Use</p>
                <div className=" mt8 flex i-align-self-end">
                  <button
                    className="linkBtn font-s13 fMontserrat font-w500"
                    onClick={() => console.log("click")}
                  >
                    Read <BigArrowRightIcon />
                  </button>
                </div>
              </ElBgContainer>
            </div>
          </div>
          <div
            className={classNames({
              ["text bottom-container-absolute"]: isContentFit,
              ["padding-b40"]: !isContentFit,
            })}
          >
            <DefaultButton
              text="Manage Subscription"
              customStyles="mt32"
              onClick={navigateToManageSubscription}
            />
            <DefaultButton
              text="Delete Account"
              customStyles="blue-border primary-bg text-color-silver mt24"
              onClick={() => toggleDeleteBottomModal(true)}
            />
          </div>
        </div>
        <CustomHeightBottomSheet
          header={headerDeleteBottomModal}
          isModalOpen={isDeleteBottomModalOpen}
          setOpen={() => console.log("open")}
          content={contentCancelBottomModal}
          modalHeight={windowWidth > 358 ? 440 : windowWidth > 342 ? 461 : 490}
        />
      </div>
    </Layout>
  );
};

export default Settings;
