import React, { useEffect, useRef } from "react";
import Layout from "components/Layout/Layout";
import HeaderWithBack from "components/headers/HeaderWithBack";
import { handleScrollToSection } from "utils/defaults";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const PrivacyPolicy = () => {
  const informationCollectionRef = useRef(null);
  const useOfInformationRef = useRef(null);
  const sharingOfInformationRef = useRef(null);
  const dataSecurityRef = useRef(null);
  const childrensPrivacyRef = useRef(null);
  const yourRightsRef = useRef(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/privacy-policy",
      title: "Privacy Policy Page",
    });
    ReactPixel.track("Privacy Policy Page");
  }, []);

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <h1 className="yellow-text">Privacy Policy</h1>
        <p className="fRoboto text-color-silver font-w400 font-s16 mt16">
          Last Updated: 04.12.2023
        </p>
        <div className="t-align-l mt40 flex gap8 justify-content-start">
          <div className="flex flex-column i-align-c gap8">
            <h3 className="text-color-blue">1.</h3>
            <h3 className="text-color-blue">2.</h3>
            <h3 className="text-color-blue">3.</h3>
            <h3 className="text-color-blue">4.</h3>
            <h3 className="text-color-blue">5.</h3>
            <h3 className="text-color-blue">6.</h3>
          </div>
          <div className="flex flex-column gap8">
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(informationCollectionRef)}
            >
              Information Collection
            </h3>
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(useOfInformationRef)}
            >
              Use of Information
            </h3>
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(sharingOfInformationRef)}
            >
              Sharing of Information
            </h3>
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(dataSecurityRef)}
            >
              Data Security
            </h3>
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(childrensPrivacyRef)}
            >
              Children's Privacy
            </h3>
            <h3
              className="text-color-blue"
              onClick={() => handleScrollToSection(yourRightsRef)}
            >
              Your Rights
            </h3>
          </div>
        </div>
        <h3
          className="yellow-text t-align-l"
          style={{ marginTop: 80 }}
          ref={informationCollectionRef}
        >
          Information Collection
        </h3>
        <div className="fRoboto text-color-silver font-s16 font-w400 mt16">
          <table>
            <tbody>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>1.</td>
                <td
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                  className="t-align-l"
                >
                  We collect personal information that you provide directly to
                  us, such as your name, date of birth, email address, and
                  location. This information is essential for providing
                  personalized astrological insights.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>2.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  We may collect information about the device you use to access
                  our app, including the hardware model, operating system,
                  unique device identifiers, and mobile network information.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>3.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  We collect information about how you interact with our app,
                  such as the features you use and the time spent on the app.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="yellow-text t-align-l mt12" ref={useOfInformationRef}>
          Use of Information
        </h3>
        <p className="text-color-silver font-s18 fRoboto font-w400 t-align-l mt16">
          We use the information we collect to:
        </p>
        <div className="fRoboto text-color-silver font-s16 font-w400 mt8">
          <table>
            <tbody>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>1.</td>
                <td
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                  className="t-align-l"
                >
                  Provide personalized astrological readings and forecasts.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>2.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  Improve and optimize the performance and features of the app.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>3.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  Communicate with you about app updates, offers, and
                  promotional content.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>4.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  Monitor and analyze trends, usage, and activities in
                  connection with our app.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3
          className="yellow-text t-align-l mt12"
          ref={sharingOfInformationRef}
        >
          Sharing of Information
        </h3>
        <p className="text-color-silver font-s18 fRoboto font-w400 t-align-l mt16">
          We do not sell or rent your personal data to third parties.
          Information may be shared in the following circumstances:
        </p>
        <div className="fRoboto text-color-silver font-s16 font-w400 mt8">
          <table>
            <tbody>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>1.</td>
                <td
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                  className="t-align-l"
                >
                  We may share your data with third-party service providers who
                  perform services on our behalf, such as hosting, data
                  analysis, and marketing services.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>2.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  We may disclose your information if required to do so by law
                  or in response to a request from a public or government
                  authority.
                </td>
              </tr>
              <tr className="flex t-align-l" style={{ paddingTop: 0 }}>
                <td style={{ paddingTop: 0 }}>3.</td>
                <td
                  className="t-align-l"
                  style={{ paddingTop: 0, paddingLeft: 8 }}
                >
                  In the event of a merger, acquisition, or sale of assets, your
                  personal information may be transferred as part of the
                  transaction.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="yellow-text t-align-l mt12" ref={dataSecurityRef}>
          Data Security
        </h3>
        <p className="fRoboto font-s18 text-color-silver font-w400 mt16 t-align-l">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          Internet or method of electronic storage is completely secure, and we
          cannot guarantee its absolute security.
        </p>
        <h3 className="yellow-text t-align-l mt18" ref={childrensPrivacyRef}>
          Children's Privacy
        </h3>
        <p className="fRoboto font-s18 text-color-silver font-w400 mt16 t-align-l">
          Our app is not intended for children under the age of 13. We do not
          knowingly collect personal information from children under 13.
        </p>
        <h3 className="yellow-text t-align-l mt18" ref={yourRightsRef}>
          Your Rights
        </h3>
        <p className="fRoboto font-s18 text-color-silver font-w400 mt16 t-align-l padding-b40">
          You have the right to access, update, or request the deletion of your
          personal information. You can do this by accessing your account
          settings within the app or contacting us directly.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
