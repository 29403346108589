import Layout from "components/Layout/Layout";
import React, { useEffect } from "react";
import DefaultButton from "components/buttons/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithHome from "components/headers/HeaderWithHome";
import http from "helpers/http";
import { clearPersistor, useAppSelector } from "store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const CancelSubscriptionNotExcited = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { userData } = useAppSelector((state) => state.user);

  const isDeleteFlow = location.from === "delete";

  useEffect(() => {
    ReactPixel.track("Cancel Subscription Not Excited Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/cancel-subscription-not-excited",
      title: "Cancel Subscription Not Excited Page",
    });
  }, []);
  const onClickCancelBtn = async () => {
    try {
      if (isDeleteFlow) {
        const res: { code: number; status: string } = await http.post(
          "/settings/delete-account",
          { deleteAccReason: location.deleteAccReason },
        );
        if (res.code === 200) {
          navigate("/account-deleted");
          await clearPersistor();
        }
        console.log("res", res);
      } else {
        await http.post("/payment/cancel-subscription", {
          email: userData.email,
          reason: "Not excited",
        });
        navigate("/cancelled-subscription");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Layout>
      <div className="container">
        <HeaderWithHome />
        <div className="position-relative" style={{ height: "94%" }}>
          <h2 className="yellow-text">Not utilizing all features? </h2>
          <p className="fRoboto text-white font-w400 font-s18 mt8">
            Discover what more you can do with your subscription.
          </p>
          <img
            src={require("assets/images/pazzle-group.png")}
            width={260}
            alt=""
            className="mt24"
          />
          <div className="bottom-container-absolute">
            <DefaultButton text="Discover all features" />
            <DefaultButton
              text={isDeleteFlow ? "Delete Account" : "Cancel Subscription"}
              customStyles="blue-border primary-bg text-color-silver mt24 mb40"
              onClick={onClickCancelBtn}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CancelSubscriptionNotExcited;
