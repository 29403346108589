import React, { FC } from "react";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import { Pie } from "react-chartjs-2";

type SwiperCardProps = {
  title: string;
  value: number;
  pieColor: string;
  text: string;
};
const SwiperCard: FC<SwiperCardProps> = ({ title, value, pieColor, text }) => {
  return (
    <ElBgContainer customClassNames="padding-h18 padding-v20 flex flex-column i-align-c gap-unset">
      <h3 className="yellow-text mb8">{title}</h3>
      <div
        className="default-border flex position-relative i-align-c"
        style={{ width: 50, height: 50, borderRadius: 25 }}
      >
        <div style={{ width: 44, height: 44 }}>
          <Pie
            data={{
              datasets: [
                {
                  data: [100 - value, value],
                  backgroundColor: ["#122133", pieColor],
                  borderWidth: 0,
                },
              ],
            }}
          />
        </div>
        <p className="position-absolute font-w500 font-s14">{value}%</p>
      </div>
      <p className="font-s16 font-w400 mt16">{text}</p>
    </ElBgContainer>
  );
};

export default SwiperCard;
