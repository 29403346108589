import React, { ChangeEvent, useState } from "react";
import DefaultInput from "components/fields/DefaultInput";
import DefaultButton from "components/buttons/DefaultButton";
import {
  refreshPartnerData,
  setPartnerName,
} from "store/slices/partnerRegistration";
import { useAppDispatch, useAppSelector } from "store";
import { useLocation, useNavigate } from "react-router-dom";
import http from "helpers/http";
import { setPartnerData } from "store/slices/userPartners";

const NameSelect = () => {
  const {
    gender,
    birthday,
    birthtime,
    birthplace,
    name,
    zodiac,
    zodiacName,
    birthplaceLon,
    birthplaceLat,
  } = useAppSelector((state) => state.partnerRegistration);

  const [partnerName, setName] = useState(name);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { navigateTo } = useLocation().state;

  const dispatch = useAppDispatch();
  const onEnterName = (event: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError("");
    }
    setName(event.target.value);
  };

  console.log("navigateTo", navigateTo);
  const onClickNext = async () => {
    if (!partnerName) {
      setError("Please provide partner name");
    } else {
      try {
        dispatch(setPartnerName(name));
        const pathName =
          navigateTo === "/friendship-detail"
            ? "/astro/friendship"
            : navigateTo === "/romantic-detail"
              ? "/astro/love-compatibility"
              : "/astro/romantic-couple";
        const res: any = await http.post(pathName, {
          gender,
          birthday,
          birthtime,
          birthplace,
          name: partnerName,
          zodiac,
          zodiacName,
          birthplaceLon,
          birthplaceLat,
        });

        console.log("res", res);

        if (res.astrologicData.message.love_report) {
          dispatch(
            setPartnerData({
              gender,
              birthday,
              birthtime,
              birthplace,
              name: partnerName,
              zodiac,
              zodiacName,
              romanticReport: res.astrologicData.message.love_report,
            }),
          );
        } else if (res.astrologicData.message.friendship_report) {
          dispatch(
            setPartnerData({
              gender,
              birthday,
              birthtime,
              birthplace,
              name: partnerName,
              zodiac,
              zodiacName,
              friendshipReport: res.astrologicData.message.friendship_report,
            }),
          );
        } else {
          dispatch(
            setPartnerData({
              gender,
              birthday,
              birthtime,
              birthplace,
              name: partnerName,
              zodiac,
              zodiacName,
              personalConnectionReport:
                res.astrologicData.message.romantic_forecast,
            }),
          );
        }

        navigate(navigateTo, {
          state: navigateTo === -1 ? { from: "/partner-details" } : undefined,
        });
        dispatch(refreshPartnerData());
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <>
      <h2>
        <span className="yellow-text">The last</span> step!
      </h2>
      <p className="fRoboto font-s16 text-color-silver font-w400 mt8">
        Please enter your partner name
      </p>
      <div style={{ marginTop: 136 }}>
        <DefaultInput
          handleChange={onEnterName}
          name="fullname"
          placeholder="Alex Crown"
          error={error}
          inputVal={name}
        />
      </div>
      <div className="bottom-container-absolute">
        <DefaultButton
          text={"Finish"}
          type="button"
          onClick={onClickNext}
          isDisabled={!!error}
        />
      </div>
    </>
  );
};

export default NameSelect;
