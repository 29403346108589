import React from "react";
import Lottie from "lottie-react";
import pulse from "./Infinite.json";

const Infinite = () => (
  <Lottie
    animationData={pulse}
    loop
    style={{ width: "100%", position: "absolute" }}
  />
);

export default Infinite;
