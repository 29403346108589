import { useState, useEffect, useRef } from "react";

function ChangingProgressProvider({ children, values, interval = 800 }) {
  const [valuesIndex, setValuesIndex] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const nextIndex = (valuesIndex + 1) % values.length;
      if (valuesIndex === 5) {
        clearInterval(intervalRef.current);
      } else if (values[nextIndex] <= 80) {
        setValuesIndex(nextIndex);
        clearInterval(intervalRef.current);
      } else if (values[nextIndex] === 100) {
        setValuesIndex(nextIndex);
      } else {
        clearInterval(intervalRef.current);
      }
    }, interval);

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [valuesIndex, values, interval]);

  return children(values[valuesIndex]);
}

export default ChangingProgressProvider;
