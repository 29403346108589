import React, { ChangeEvent, useEffect, useState } from "react";
import DefaultInput from "components/fields/DefaultInput";
import { useAppDispatch, useAppSelector } from "store";
import {
  goToNextStep,
  setPartnerBirthplace,
} from "store/slices/partnerRegistration";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import DefaultButton from "components/buttons/DefaultButton";

const PlaceOfBirthSelect = () => {
  const dispatch = useAppDispatch();

  const { birthplace, birthplaceLon, birthplaceLat } = useAppSelector(
    (state) => state.partnerRegistration,
  );

  const [place, setPlace] = useState(birthplace);
  const [coordinates, setCoordinates] = useState<{
    geometryLon: number;
    geometryLat: number;
  }>({ geometryLon: birthplaceLon, geometryLat: birthplaceLat });

  const [isShowPredictions, togglePredictions] = useState(false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
    });

  useEffect(() => {
    if (placePredictions.length) togglePredictions(true);
  }, [placePredictions]);

  useEffect(() => {
    if (!place.length) togglePredictions(false);
  }, [place]);

  const onChangePlace = (event: ChangeEvent<HTMLInputElement>) => {
    setCoordinates({ geometryLon: 0, geometryLat: 0 });
    setPlace(event.target.value);
    getPlacePredictions({ input: event.target.value });
  };

  const onSelectPlace = (val: { place_id: string }) => {
    placesService?.getDetails(
      { placeId: val.place_id },
      (placeDetails: {
        formatted_address: string;
        geometry: { location: { lng: any; lat: any } };
      }) => {
        const serializablePlaceDetails = {
          formatted_address: placeDetails.formatted_address,
          geometryLon: placeDetails.geometry.location.lng(),
          geometryLat: placeDetails.geometry.location.lat(),
        };
        setPlace(serializablePlaceDetails.formatted_address);
        setCoordinates({
          geometryLon: serializablePlaceDetails.geometryLon,
          geometryLat: serializablePlaceDetails.geometryLat,
        });
      },
    );
    togglePredictions(false);
  };

  const goNextStep = () => {
    dispatch(
      setPartnerBirthplace({
        formatted_address: place,
        geometryLon: coordinates.geometryLon,
        geometryLat: coordinates.geometryLat,
      }),
    );
    dispatch(goToNextStep());
  };

  return (
    <>
      <h2>
        Where was your partner <span className="yellow-text">born</span>?
      </h2>
      <p className="fRoboto font-s16 text-color-silver font-w400 mt8">
        Birthplace enriches a deeper cosmic understanding.
      </p>
      <div style={{ marginTop: 88 }}>
        <DefaultInput
          handleChange={onChangePlace}
          name="city"
          placeholder="New York"
          inputVal={place}
        />
        {isShowPredictions && (
          <div className="default-border border-radius12">
            {placePredictions.map((item, index) => (
              <div
                onClick={() => onSelectPlace(item)}
                key={index}
                className="padding-v8 t-align-l padding-h12 fRoboto text-white font-w400 font-s16 bottom-border place-prediction white-space-nowrap"
              >
                {item.description}
              </div>
            ))}
          </div>
        )}
        <div className="bottom-container-absolute">
          <DefaultButton
            text={"Next"}
            type="button"
            onClick={goNextStep}
            isDisabled={!coordinates.geometryLon && !coordinates.geometryLat}
          />
        </div>
      </div>
    </>
  );
};

export default PlaceOfBirthSelect;
