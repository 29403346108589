import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import Ellipse from "assets/svg/ellipse";
import styles from "./PalmReading.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

import "./swiper.css";

import { EffectCube, Pagination } from "swiper/modules";
import SwiperCard from "./components/SwiperCard/SwiperCard";
import ThisYearBtn from "assets/svg/buttons/this-year-btn.svg";
import ThreeYearsBtn from "assets/svg/buttons/3years-btn.svg";
import WithinBtn from "assets/svg/buttons/within-btn.svg";
import Timeline from "components/Timeline/Timeline";
import BlueCircle from "assets/svg/circles/Blue circle.svg";
import PinkCircle from "assets/svg/circles/Pink circle.svg";
import YellowCircle from "assets/svg/circles/Yellow circle.svg";
import GreenCircle from "assets/svg/circles/Green circle.svg";
import { useAppSelector } from "../../store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

export const legends = [
  { title: "Life line", color: "#5A8C8D", text: "23%", textColor: "#FFF" },
  { title: "Fate line", color: "#3F5497", text: "2%", textColor: "#FFF" },
  { title: "Head line", color: "#FFD700", text: "49%", textColor: "#122133" },
  { title: "Heart line", color: "#9B7BD0", text: "59%", textColor: "#122133" },
];
const PalmReadingPage = () => {
  const { palmPhotoUrl } = useAppSelector((state) => state.user.userData);
  const swiperData = [
    {
      title: "Life line",
      value: 70,
      pieColor: "#5A8C8D",
      text: "The life line, deeply etched and unbroken, points to excellent physical health and stamina. It suggests a journey with few health issues and the ability to overcome life's physical challenges, indicating a life of longevity and resilience.",
    },
    {
      title: "Fate line",
      value: 76,
      pieColor: "#3F5497",
      text: "A distinct and straight fate line highlights a life with a well-defined purpose. It suggests a career path that is likely to be straightforward and successful, with fewer unpredicted changes or disruptions. This line indicates strong ambition and a clear sense of direction.",
    },
    {
      title: "Head line",
      value: 70,
      pieColor: "#746C20",
      text: "The long and pronounced head line suggests a mind that values logic and clarity. It indicates a strong intellectual capacity, a preference for analytical and strategic thinking, and an ability to handle complex problems effectively. This line also suggests a practical and often cautious approach to decision-making.",
    },
    {
      title: "Heart line",
      value: 70,
      pieColor: "#9B7BD0",
      text: "A clear and deep heart line signifies a rich emotional life and strong interpersonal relationships. It indicates a person who is deeply empathetic, caring, and capable of forming meaningful connections. The line suggests a balance between expressing emotions and managing them wisely, hinting at a harmonious personal life.",
    },
  ];

  useEffect(() => {
    ReactPixel.track("Palm Reading Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/palm-reading",
      title: "Palm Reading Page",
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h mb24 fRoboto text-color-silver font-s18 font-w400 t-align-l">
          <HeaderWithBack />
          <h1 className="yellow-text mb24">Palm Reading</h1>
          <div className={styles.container}>
            <div
              className="flex i-align-c overflow-hidden border-radius12 default-border position-relative"
              style={{ width: "66.567%", height: 296, margin: "0 auto" }}
            >
              <img src={palmPhotoUrl} alt="" className={styles.palmImage} />
            </div>
            <div className={styles.legendContainer}>
              {legends.map((item, index) => (
                <div
                  key={index}
                  className="flex i-align-c gap8 justify-content-start"
                >
                  <Ellipse color={item.color} />
                  <p className="text-white fMontserrat font-s14 font-w600">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p className="mt24">
            The hand reading report highlights a balance between emotional
            intelligence and intellectual prowess, evidenced by prominent heart
            and head lines. Unique markings on the mount of Venus suggest a
            strong creative potential, indicating a successful future in both
            analytical and artistic endeavors.
          </p>
          <h2 className="yellow-text mt40 mb24">Palm lines</h2>
          <Swiper
            effect={"cube"}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            pagination={true}
            modules={[EffectCube, Pagination]}
            className="mySwiper"
          >
            {swiperData.map((item, index) => (
              <SwiperSlide key={index}>
                <SwiperCard
                  text={item.text}
                  title={item.title}
                  value={item.value}
                  pieColor={item.pieColor}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="font-s18 font-w400 flex flex-column padding-content-h font-w400 font-s16 fRoboto text-white t-align-l">
          <h2 className="yellow-text mb24">Love</h2>
          <div className="el-bg default-border border-radius12 padding-v18 padding-h18">
            <h3 className="mt24 mb8">
              Your Future <span className="yellow-text">Children Timing</span>
            </h3>
            <p className="mb16">
              Discover the estimated time frame when you may welcome children
              into your life based on our analysis.
            </p>
            <div className="t-align-c">
              <img src={ThisYearBtn} alt="" width={"63.28%"} />
            </div>
          </div>
          <div className="el-bg default-border border-radius12 padding-v18 padding-h18 mt12">
            <h3>
              Your Future <span className="yellow-text">Children Count</span>
            </h3>
            <p className="mt8">
              Find out the number of children you might have in your future. Our
              insightful analysis offers a glimpse into the potential size of
              your future family, giving you a unique perspective on what life
              might have in store.
            </p>
            <Timeline position={3} customClassNames="mt8" title="3 children" />
          </div>
          <div className="el-bg default-border border-radius12 padding-v18 padding-h18 mt12">
            <h3 className="mt8">
              <span className="yellow-text">Love</span> of your life
            </h3>
            <p className="mt8">
              Embark on a journey to uncover when you might meet the love of
              your life. Our tailored analysis provides a glimpse into your
              romantic future, offering insights to guide you towards meaningful
              connections.
            </p>
            <div className="t-align-c mb16 mt16">
              <img src={ThreeYearsBtn} alt="" width={"42.98%"} />
            </div>
          </div>
        </div>
        <div className="padding-content-h mt40 fRoboto text-white font-s16 font-w400 t-align-l bg-el-transparent padding-t40 padding-b40">
          <h2>
            <span className="yellow-text">Life Span</span> Prediction
          </h2>
          <p className="mt8">
            Find out the number of children you might have in your future. Our
            insightful analysis offers a glimpse into the potential size of your
            future family, giving you a unique perspective on what life might
            have in store.
          </p>
          <Timeline
            position={85}
            customClassNames="mt8"
            maxValue={100}
            title="80-85 years"
          />
        </div>
        <div className="padding-t40 font-s18 font-w400 flex flex-column padding-content-h font-w400 font-s18 fRoboto text-white t-align-l">
          <h2 className="yellow-text mt16 mb8">Career</h2>
          <p>
            Uncover the path your professional life may take with our in-depth
            career analysis. Get personalized insights into your future career
            trajectory and practical tips to navigate your professional journey
            with confidence and success.
          </p>
          <h3 className="mt32 mb8 t-align-c">
            <span className="yellow-text">Tips</span> for you
          </h3>
          <div
            style={{
              marginTop: 10,
              gap: "10px 10px",
              flexWrap: "wrap",
              display: "flex",
              gridTemplateColumns: "repeat(2, 1fr)",
              justifyContent: "center",
            }}
          >
            <div className="position-relative flex i-align-c">
              <img src={PinkCircle} alt="" />
              <div className="flex flex-column position-absolute t-align-c fMontserrat  font-s10 text-color-silver text-uppercase">
                <span>Prioritize</span>
                <span>Work-Life</span>
                <span>Balance</span>
              </div>
            </div>
            <div className="position-relative flex i-align-c">
              <img src={BlueCircle} alt="" />
              <div className="flex flex-column position-absolute t-align-c fMontserrat  font-s10 text-color-silver text-uppercase">
                <span>Build</span>
                <span>Strong</span>
                <span>Networks</span>
              </div>
            </div>
            <div className="position-relative flex i-align-c">
              <img src={YellowCircle} alt="" />
              <div className="flex flex-column position-absolute t-align-c fMontserrat  font-s10 text-color-silver text-uppercase">
                <span>Pursue</span>
                <span>Lifelong</span>
                <span>Learning</span>
              </div>
            </div>
            <div className="position-relative flex i-align-c">
              <img src={GreenCircle} alt="" />
              <div className="flex flex-column position-absolute t-align-c fMontserrat  font-s10 text-color-silver text-uppercase">
                <span>Embrace</span>
                <span>Creative</span>
                <span>Thinking</span>
              </div>
            </div>
          </div>
          <div className="el-bg default-border border-radius12 padding-v18 padding-h18 mt32 mb40">
            <h3 className="mb8">
              <span className="yellow-text">Job Change</span> Forecast
            </h3>
            <p className="font-s16">
              Gain insights into when a career shift may be on your horizon. Our
              analysis offers a timeline for potential job changes, helping you
              prepare for new professional opportunities.
            </p>
            <div className="t-align-c mt16">
              <img src={WithinBtn} alt="" width={"50.36%"} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PalmReadingPage;
