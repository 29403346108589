import Avatar1 from "assets/svg/avatars/avatar1.svg";
import Avatar2 from "assets/svg/avatars/avatar2.svg";
import Avatar3 from "assets/svg/avatars/avatar3.svg";
export const feedbacks = [
  {
    id: 0,
    starsCount: 5,
    avatarUrl: Avatar1,
    name: "Andreas",
    text: "This tarot reading service is a gem! The reader had an incredible ability to connect with my energy and provide insights that were both profound and actionable. Each card was explained in a way that was easy to understand and relate to my life.",
  },
  {
    id: 1,
    starsCount: 5,
    avatarUrl: Avatar2,
    name: "Eleni",
    text: "Exceptional service! The tarot reader was professional, friendly, and remarkably accurate.",
  },
  {
    id: 2,
    avatarUrl: Avatar3,
    starsCount: 5,
    name: "Maria",
    text: "I was skeptical about tarot readings before, but this service changed my mind. They helped me see my situation from a new perspective, offering both comfort and practical advice.",
  },
];
