import Layout from "components/Layout/Layout";
import React, { useEffect } from "react";
import DefaultButton from "components/buttons/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithHome from "components/headers/HeaderWithHome";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import http from "../../helpers/http";
import { clearPersistor, useAppSelector } from "../../store";
const CancelSubscriptionTechnicalProblems = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { userData } = useAppSelector((state) => state.user);

  const isDeleteFlow = location.from === "delete";

  useEffect(() => {
    ReactPixel.track("Cancel Subscription Technical Problems Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/cancel-subscription-technical-problems",
      title: "Cancel Subscription Technical Problems Page",
    });
  }, []);

  const onClickCancel = async () => {
    try {
      if (isDeleteFlow) {
        await http.delete("/settings/delete-account");
        navigate("/account-deleted");
        await clearPersistor();
      } else {
        await http.post("/payment/cancel-subscription", {
          email: userData.email,
          reason: "Technical problems",
        });
        navigate("/cancelled-subscription");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Layout>
      <div className="container">
        <HeaderWithHome />
        <div className="position-relative" style={{ height: "94%" }}>
          <h2 className="yellow-text">
            Need Help?
            <br /> We’re Here for You
          </h2>
          <p className="fRoboto text-white font-w400 font-s18 mt8 mb24">
            Experiencing technical difficulties or have questions? Our support
            team is ready to assist you.
          </p>
          <div>
            <textarea wrap={"hard"} placeholder="Explain your problem..." />
          </div>
          <div className="bottom-container-absolute">
            <DefaultButton
              text="Get Help"
              onClick={() => navigate("/settings")}
            />
            <DefaultButton
              text={isDeleteFlow ? "Delete Account" : "Cancel Subscription"}
              customStyles="blue-border primary-bg text-color-silver mt24 mb40"
              onClick={onClickCancel}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CancelSubscriptionTechnicalProblems;
