import { createSlice } from "@reduxjs/toolkit";

type TabNavigationState = {
  openedTab: number;
};

const initialState: TabNavigationState = {
  openedTab: 1,
};

export const tabNavigationSlice = createSlice({
  name: "tabNavigation",
  initialState,
  reducers: {
    setOpenedTab: (state, action) => {
      state.openedTab = action.payload.id;
    },
  },
});

export const { setOpenedTab } = tabNavigationSlice.actions;

export default tabNavigationSlice.reducer;
