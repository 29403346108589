import React, { useEffect } from "react";
import TabHeader from "components/headers/tabHeader/TabHeader";
import Layout from "components/Layout/Layout";
import CustomBottomNavigation from "components/bottomNavigation/CustomBottomNavigation";
import styles from "./CompatibilityPage.module.scss";
import SmallZodiacCard from "./SmallZodiacCard/SmallZodiacCard";
import classNames from "classnames";
import CustomProgressBar from "components/progressBar/ProgressBar";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { useInView } from "react-intersection-observer";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import ZodiacCard from "../LoveCompatibilityResultPage/components/ZodiacCard/ZodiacCard";
import Hands from "assets/svg/hands.svg";
import "./flicking-styles.scss";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
const CompatibilityPage = () => {
  const navigate = useNavigate();
  const { firstSign, lastSign, zodiacsArray } = useAppSelector(
    (state) => state.compatibility,
  );

  useEffect(() => {
    ReactPixel.track("Compatibility Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/compatibility",
      title: "Compatibility Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const [elemRef, inView] = useInView({
    threshold: 0,
  });

  const plugins = [
    new Pagination({
      type: "bullet",
    }),
  ];
  const navigateToLoveCompatibility = () =>
    navigate("/love-compatibility-result");

  const navigateToFullCompatibility = () =>
    navigate("/full-compatibility-report");

  const navigateToTwelveSignsPage = () => navigate("/twelve-signs");

  return (
    <Layout>
      <div className="app-container">
        <TabHeader />
        <div className="padding-content-h">
          <h1 className="t-align-l mb32">Compatibility</h1>
        </div>
        <div
          className="padding-content-h"
          onClick={navigateToLoveCompatibility}
        >
          <h2 className="t-align-l">
            <span className="yellow-text">Love</span> Compatibility
          </h2>
          <div className="flex i-align-c mt24">
            <ZodiacCard
              iconUrl={firstSign.iconUrl}
              isUserZodiac={firstSign.isUserZodiac}
              title={firstSign.title}
            />
            <ZodiacCard
              iconUrl={lastSign.iconUrl}
              isUserZodiac={lastSign.isUserZodiac}
              title={lastSign.title}
              isSecondSign
            />
          </div>
          <div
            className={classNames(
              styles.infoContainer,
              "flex flex-column i-align-c position-relative",
            )}
            ref={elemRef}
          >
            <h1 className="mb8">8.0</h1>

            {inView && (
              <CustomProgressBar
                completed={8}
                transitionDuration={"1s"}
                maxCompleted={10}
                animateOnRender
                customBarContainerStyles="progress-bar-purple"
              />
            )}
            <div
              className={classNames(
                styles.labelsContainer,
                "flex justify-content-space-between position-absolute",
              )}
            >
              <p>0</p>
              <p>10</p>
            </div>
          </div>
          <div className="mt24 flex justify-content-end mb32 i-align-c">
            <button className="linkBtn">
              Read More <BigArrowRightIcon />
            </button>
          </div>
          <DefaultButton
            style={{ zIndex: 5 }}
            text={"Discover More Pairings"}
            onClick={(event) => {
              event.stopPropagation();
              navigate("/love-compatibility-select");
            }}
          />
        </div>
        <div className={classNames(styles.bgContainer, "mt40 overflow-hidden")}>
          <div className="">
            <h2
              className="t-align-l padding-content-h"
              onClick={navigateToTwelveSignsPage}
            >
              Best <span className="yellow-text">Matches</span> for
              <span className="yellow-text"> 12 Signs</span>
            </h2>
            <div className="mt24" onClick={navigateToTwelveSignsPage}>
              <Flicking
                plugins={plugins}
                align={{ camera: "3%", panel: "5.33%" }}
                onMoveEnd={(e) => {
                  console.log(e);
                }}
              >
                {zodiacsArray.map((item) => (
                  <div key={item.id}>
                    <SmallZodiacCard
                      isUserZodiac={item.isUserZodiac}
                      key={item.id}
                      title={item.title}
                      iconUrl={item.iconUrl}
                    />
                  </div>
                ))}
                <ViewportSlot>
                  <div className="flicking-pagination" />
                </ViewportSlot>
              </Flicking>
            </div>
            <div className="mt24 flex justify-content-end mb40 i-align-c padding-content-h">
              <button className="linkBtn" onClick={navigateToTwelveSignsPage}>
                See all signs <BigArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
        <div className="padding-t40">
          <h2
            className="padding-content-h t-align-l"
            onClick={navigateToFullCompatibility}
          >
            Unlock
            <span className="yellow-text"> Personalized Compatibility </span>
            Insights
          </h2>
          <p
            className="text-white fRoboto font-w400 t-align-l padding-content-h mt8 mb24"
            onClick={navigateToFullCompatibility}
          >
            Discover unique connections with a detailed report tailored to your
            specifications.
          </p>
          <img
            src={Hands}
            alt={""}
            width="100%"
            onClick={navigateToFullCompatibility}
          />
          <div className="padding-content-h mt24 padding-b40">
            <DefaultButton
              style={{ zIndex: 5 }}
              text={"Unlock Insights"}
              onClick={navigateToFullCompatibility}
            />
          </div>
        </div>
        <CustomBottomNavigation />
      </div>
    </Layout>
  );
};

export default CompatibilityPage;
