import React from "react";

const EarthIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6773 2.27777C12.8581 2.27777 9.19548 3.79497 6.49494 6.49537C3.79441 9.1959 2.27734 12.8586 2.27734 16.6778C2.27734 20.497 3.79454 24.1596 6.49494 26.8602C9.19548 29.5607 12.8581 31.0778 16.6773 31.0778C20.4965 31.0778 24.1592 29.5606 26.8597 26.8602C29.5603 24.1596 31.0773 20.497 31.0773 16.6778C31.0773 14.1501 30.4119 11.6668 29.1481 9.47777C27.8842 7.28871 26.0665 5.47084 23.8774 4.2071C21.6883 2.94324 19.2051 2.27777 16.6773 2.27777ZM29.1157 15.7178H17.6373V4.23937C20.6051 4.46937 23.3931 5.7526 25.4979 7.8575C27.6027 9.96241 28.8856 12.7498 29.1157 15.7178ZM15.7173 4.23937V15.7178H4.23894C4.46894 12.75 5.75217 9.96204 7.85708 7.85724C9.96198 5.75244 12.7493 4.4695 15.7173 4.23937ZM4.23894 17.6378H15.7173V29.1162C12.7496 28.8862 9.96161 27.6029 7.85681 25.498C5.75201 23.3931 4.46908 20.6058 4.23894 17.6378ZM17.6373 29.1162V17.6378H29.1157C28.8857 20.6055 27.6025 23.3935 25.4976 25.4983C23.3927 27.6031 20.6053 28.886 17.6373 29.1162Z"
      fill="#696111"
    />
  </svg>
);

export default EarthIcon;
