import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import CorrectIcon from "assets/svg/correct";
import correctPalmPhoto from "assets/svg/correct-palm-photo.svg";
import WrongIcon from "assets/svg/wrong";
import wrongPalmPhoto from "assets/svg/wrong-palm-photo.svg";
import DefaultButton from "components/buttons/DefaultButton";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import { addClassToHiddenModalDiv } from "utils/defaults";
import CloseBtnIcon from "assets/svg/close-btn";
// import { goNextStep } from "store/slices/registration";
import CameraIcon from "assets/svg/camera.svg";
import ShareIcon from "assets/svg/share.svg";
// import { useAppDispatch } from "store";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const PalmScanInstructionPage = () => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setOpen] = useState(false);
  const [palmFile, setPalmFile] = useState<null | any>(null);
  console.log("palmFile", palmFile);

  useEffect(() => {
    addClassToHiddenModalDiv();
    ReactPixel.track("Palm Scan Instruction Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/palm-scan-instruction",
      title: "Palm Scan Instruction Page",
    });
  }, []);
  const openModal = () => {
    setOpen(true);
  };

  const headerContent = () => {
    return (
      <div className="flex justify-content-end">
        <span onClick={() => setOpen(false)} className="pointer">
          <CloseBtnIcon />
        </span>
      </div>
    );
  };

  const handleUpload = (event: any) => {
    setOpen(false);
    if (event.target.files && event.target.files[0]) {
      setPalmFile(URL.createObjectURL(event.target.files[0]));
      console.log(
        "URL.createObjectURL(event.target.files[0])",
        URL.createObjectURL(event.target.files[0]),
      );
      // dispatch(goNextStep());
    }
  };

  const onTakePhoto = () =>
    navigate("/palm-scan-and-analyzing", { state: { palmFile: "" } });

  const modalContent = () => {
    return (
      <div className="mt24 flex flex-column i-align-c gap10 fRoboto text-color-silver font-w700 font-s18">
        <div
          className="pointer bg-with-blur3 padding-v16 padding-h16 flex default-border border-radius12 gap10 i-align-c full-width justify-content-start"
          onClick={() => {
            setOpen(false);
            onTakePhoto();
          }}
        >
          <img src={CameraIcon} alt={""} />
          <p>Take photo</p>
        </div>
        <div className="bg-with-blur3 flex default-border border-radius12 gap10 i-align-s full-width">
          <label
            htmlFor={"formId"}
            className="pointer full-width flex gap10 justify-content-start i-align-c padding-v16 padding-h16"
          >
            <img src={ShareIcon} alt={""} />
            <p>Upload file</p>
          </label>
          <input
            id="formId"
            type="file"
            accept=".png, .jpg, .jpeg, .heic, .heif"
            name="myImage"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <h2>
            Take a photo of your
            <br />
            <span className="yellow-text"> left palm</span>
          </h2>
          <p className="fRoboto font-w400 font-s16 text-white mt8">
            Please position your palm within the outlined frame on the screen.
          </p>
          <div
            className="fMontserrat font-s10 text-white text-uppercase font-w600 flex i-align-s mt24"
            style={{ gap: "7.3%" }}
          >
            <div className="flex flex-column gap8 i-align-c">
              <CorrectIcon />
              <p>Correct</p>
              <img src={correctPalmPhoto} alt="mySvgImage" />
            </div>
            <div className="flex flex-column gap8 i-align-c">
              <WrongIcon />
              <p>wrong</p>
              <img src={wrongPalmPhoto} alt="mySvgImage" />
            </div>
            <div className="bottom-container-absolute">
              <DefaultButton text={"Next"} type="button" onClick={openModal} />
            </div>
          </div>
          <CustomHeightBottomSheet
            modalHeight={231}
            content={modalContent}
            header={headerContent}
            isModalOpen={isModalOpen}
            setOpen={setOpen}
          ></CustomHeightBottomSheet>
        </div>
      </div>
    </Layout>
  );
};

export default PalmScanInstructionPage;
