import { createSlice } from "@reduxjs/toolkit";

type PartnerRegistrationState = {
  gender: string;
  birthday: string;
  birthtime: string;
  birthplace: string;
  name: string;
  step: number;
  zodiac: string;
  zodiacName: string;
  birthplaceLon: number;
  birthplaceLat: number;
};

const initialState: PartnerRegistrationState = {
  gender: "",
  birthday: new Date().toISOString(),
  birthtime: "",
  birthplace: "",
  name: "",
  zodiac: "",
  zodiacName: "",
  step: 1,
  birthplaceLon: 0,
  birthplaceLat: 0,
};

export const partnerRegistrationSlice = createSlice({
  name: "partnerRegistration",
  initialState,
  reducers: {
    setPartnerGenderType: (state, action) => {
      state.gender = action.payload;
      state.step = 2;
    },
    setPartnerBirthday: (state, action) => {
      state.birthday = action.payload.birthday;
      state.zodiac = action.payload.zodiac;
      state.zodiacName = action.payload.zodiacName;
      state.step = 3;
    },
    setPartnerBirthtime: (state, action) => {
      state.birthtime = action.payload;
      state.step = 4;
    },
    setPartnerBirthplace: (state, action) => {
      state.birthplace = action.payload.formatted_address;
      state.birthplaceLon = action.payload.geometryLon;
      state.birthplaceLat = action.payload.geometryLat;
    },
    setPartnerName: (state, action) => {
      state.name = action.payload;
    },
    goToPrevStep: (state) => {
      state.step = state.step - 1;
    },
    goToNextStep: (state) => {
      state.step = state.step + 1;
    },
    refreshPartnerData: () => {
      return initialState;
    },
  },
});

export const {
  setPartnerGenderType,
  setPartnerBirthday,
  setPartnerBirthtime,
  setPartnerBirthplace,
  goToPrevStep,
  setPartnerName,
  refreshPartnerData,
  goToNextStep,
} = partnerRegistrationSlice.actions;

export default partnerRegistrationSlice.reducer;
