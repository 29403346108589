import React from "react";
import Lottie from "lottie-react";
import loader from "./loader.json";
import { useAppSelector } from "store";

const Loader = () => {
  const { isLoading } = useAppSelector((state) => state.loader);

  return (
    <>
      {isLoading && (
        <div
          className="full-height full-width position-absolute"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgb(23 37 54 / 93%)",
            zIndex: 10000000,
            paddingTop: 150,
          }}
        >
          <Lottie
            animationData={loader}
            loop
            style={{
              maxWidth: 430,
              width: "85%",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
