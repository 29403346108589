import React, { FC } from "react";
import styles from "./ZodiacCard.module.scss";
import classNames from "classnames";

type ZodiacCardProps = {
  isSelected: boolean;
  isUserZodiac: boolean;
  title: string;
  onClick: (id: number) => void;
  id: number;
  iconUrl: string;
};
const ZodiacCard: FC<ZodiacCardProps> = ({
  isSelected,
  title,
  onClick,
  id,
  isUserZodiac,
  iconUrl,
}) => {
  const titleClassNames = classNames(styles.title, {
    [styles.selectedTitle]: isUserZodiac,
  });

  const circleClassNames = classNames(styles.circle, {
    [styles.selectedCircle]: isSelected,
  });

  return (
    <div
      className={classNames(
        styles.container,
        "flex flex-column i-align-c text-color-silver",
      )}
      onClick={() => onClick(id)}
    >
      <p
        className={classNames(
          titleClassNames,
          "text-uppercase font-w600 font-s8 fMontserrat",
        )}
      >
        {title}
      </p>
      <div className={circleClassNames}>
        <img src={iconUrl} alt="" />
      </div>
    </div>
  );
};

export default ZodiacCard;
