import React, { ReactNode } from "react";
import StepsTemplate from "../StepsTemplate";
import styles from "./NaturalElementSelect.module.scss";
import NaturalElement from "./NaturalElement";
import AirIcon from "assets/svg/air-icon";
import EarthIcon from "assets/svg/earth";
import FireIcon from "assets/svg/fire-icon";
import WaterIcon from "assets/svg/water-icon";
import { useAppDispatch, useAppSelector } from "store";
import { setNaturalElement } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

type NaturalElementType = {
  type: string;
  icon: (isSelected: boolean) => ReactNode;
  id: number;
};

const naturalElementsList: NaturalElementType[] = [
  {
    type: "Earth",
    icon: () => <EarthIcon />,
    id: 0,
  },
  {
    type: "Water",
    icon: () => <WaterIcon />,
    id: 1,
  },
  {
    type: "Fire",
    icon: () => <FireIcon />,
    id: 2,
  },
  {
    type: "Air",
    icon: () => <AirIcon />,
    id: 3,
  },
];
const NaturalElementSelect = () => {
  const naturalElement = useAppSelector(
    (state) => state.registration.naturalElement,
  );
  const dispatch = useAppDispatch();
  const onSetNaturalElement = (type: string) => {
    ReactGA.event("naturalElementSelected");
    ReactPixel.trackCustom("naturalElementSelected");
    dispatch(setNaturalElement(type));
  };

  return (
    <StepsTemplate
      lightTitle="What is your favorite "
      yellowTitle="natural element"
      secondLightTitle="?"
    >
      <div className={styles.cardsContainer}>
        {naturalElementsList.map((item) => (
          <NaturalElement
            onSelectNaturalElement={onSetNaturalElement}
            type={item.type}
            icon={item.icon}
            isSelected={naturalElement === item.type}
            key={item.id}
          />
        ))}
      </div>
    </StepsTemplate>
  );
};

export default NaturalElementSelect;
