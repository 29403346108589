import React, { useEffect, useRef, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import ToggleBtnsComponent from "components/ToggleBtnComponent/ToggleBtnsComponent";
import { coffeeCupsMockedData } from "./mockedData";
import TabComponent from "./components/TabComponent/TabComponent";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import classNames from "classnames";
import CoffeCup from "assets/svg/coffeecups-icons/coffee-cup.svg";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const CoffeeCupReadingResult = () => {
  const [activeTab, setActiveTab] = useState("Present");
  const navigate = useNavigate();

  const { savedReading } = useAppSelector((state) => state.coffeeReading);
  const [isContentFit, setISContentFit] = useState(true);

  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    ReactPixel.track("Coffee Cup Reading Result Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/coffee-cup-reading-result",
      title: "Coffee Cup Reading Result Page",
    });
  }, []);

  useEffect(() => {
    if (contentWrapperRef.current instanceof HTMLElement) {
      const pageHeight = document.documentElement.clientHeight;
      const contentHeight =
        contentWrapperRef.current.offsetHeight / (pageHeight / 100);
      setISContentFit(contentHeight <= 70);
    }
  }, [activeTab]);

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div ref={contentWrapperRef}>
            <h1 className={"t-align-l"}>Coffee Cup Reading</h1>
            <ToggleBtnsComponent
              defaultBtnType={"Present"}
              btnsArray={coffeeCupsMockedData}
              setActiveBtn={setActiveTab}
              containerStyles={"mt24"}
            />
            <TabComponent
              imgUrl={CoffeCup}
              coffeeCupName={
                activeTab === "Present"
                  ? savedReading.present_title
                  : activeTab === "Near Future"
                    ? savedReading.near_future_title
                    : savedReading.distant_future_title
              }
              description={
                activeTab === "Present"
                  ? savedReading.present_content
                  : activeTab === "Near Future"
                    ? savedReading.near_future_content
                    : savedReading.distant_future_content
              }
            />
          </div>
          <div
            className={classNames({
              ["text bottom-container-absolute"]: isContentFit,
              ["mt24 padding-b40"]: !isContentFit,
            })}
          >
            <DefaultButton
              text="Select cups again"
              type="button"
              onClick={() => navigate("/coffee-cup-reading")}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoffeeCupReadingResult;
