import React from "react";
const FaceBookIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" fill="#122133" />
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" stroke="#27394B" />
    <g clipPath="url(#clip0_807_8920)">
      <path
        d="M17.8849 11.1534H20.0326V7.62292H17.5425C14.0252 7.62292 14.1186 10.7121 14.1186 11.1534V13.4618H12.5V16.9922H14.1186C14.1186 20.8622 14.1186 25.2074 14.1186 25.2074H17.3246C17.3246 25.2074 17.3246 20.7943 17.3246 16.9922H19.4723L20.0015 13.4618H17.3246V11.6965C17.3557 11.3231 17.5736 11.1534 17.8849 11.1534Z"
        fill="#E5E5E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_807_8920">
        <rect
          width="8"
          height="19"
          fill="white"
          transform="translate(12.5 6.87195)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FaceBookIcon;
