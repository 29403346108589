import React from "react";

const CloseBtnIcon = ({ fill = "#7DF9FF" }: { fill?: string }) => (
  <svg
    width="26"
    height="21"
    viewBox="0 0 26 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="5.57516" y1="16.7886" x2="19.7173" y2="2.64646" stroke={fill} />
    <line x1="5.35355" y1="2.64645" x2="19.4957" y2="16.7886" stroke={fill} />
  </svg>
);

export default CloseBtnIcon;
