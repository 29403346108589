import React, { FC } from "react";
import styles from "./HoroscopeInfographicItem.module.scss";
import ArrowRight from "assets/svg/arrow-right";
import { Pie } from "react-chartjs-2";

type HoroscopeInfographicItemProps = {
  onPressHoroscopeInfographic: (id: string) => void;
  horoscopeInfographic: any;
};
const HoroscopeInfographicItem: FC<HoroscopeInfographicItemProps> = ({
  onPressHoroscopeInfographic,
  horoscopeInfographic,
}) => {
  const newData = [
    { ...horoscopeInfographic.love, color: "#7361A1", title: "Love" },
    { ...horoscopeInfographic.health, color: "#374A82", title: "Health" },
    { ...horoscopeInfographic.career, color: "#3F636B", title: "Career" },
  ];

  return (
    <>
      {newData.map((item: any, index) => (
        <div
          key={index}
          className={styles.infographicContainer}
          onClick={() => onPressHoroscopeInfographic(item.title)}
        >
          <p className={styles.title}>
            {item.title}
            <ArrowRight />
          </p>
          <div className={styles.pieContainer}>
            <div className={styles.container}>
              <Pie
                data={{
                  datasets: [
                    {
                      data: [100 - item.percent, item.percent],
                      backgroundColor: ["#122133", item.color],
                      borderWidth: 0,
                    },
                  ],
                }}
              />
            </div>
            <p className={styles.text}>{item.percent}%</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default HoroscopeInfographicItem;
