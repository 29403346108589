export const data = [
  { subject: "EMOTIONAL", B: 8, fullMark: 10 },
  { subject: "INTELLECTUAL", B: 6, fullMark: 10 },
  { subject: "PHYSICAL", B: 5, fullMark: 10 },
];

export const dataToday = [
  { subject: "EMOTIONAL", B: 8, fullMark: 10 },
  { subject: "INTELLECTUAL", B: 6, fullMark: 10 },
  { subject: "PHYSICAL", B: 5, fullMark: 10 },
];

export const dataTomorrow = [
  { subject: "EMOTIONAL", B: 5, fullMark: 10 },
  { subject: "INTELLECTUAL", B: 8, fullMark: 10 },
  { subject: "PHYSICAL", B: 4, fullMark: 10 },
];
