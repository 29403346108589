import React, { FC } from "react";
import classNames from "classnames";
import styles from "./ToggleBtn.module.scss";

type ToggleBtnProps = {
  title: string;
  onClick: (type: string) => void;
  type: string;
  isActive: boolean;
};
const ToggleBtn: FC<ToggleBtnProps> = ({ title, onClick, type, isActive }) => {
  return (
    <div
      className={classNames(styles.btnContainer, {
        [styles.isActive]: isActive,
      })}
      onClick={() => onClick(type)}
    >
      {title}
    </div>
  );
};

export default ToggleBtn;
