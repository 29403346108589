import React from "react";
import Lottie from "lottie-react";
import palm from "./animation.json";

const Palm = () => (
  <Lottie
    animationData={palm}
    loop
    style={{ width: "80%", position: "relative" }}
  />
);

export default Palm;
