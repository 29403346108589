import React, { FC } from "react";
import styles from "./ZodiacCard.module.scss";
import classNames from "classnames";

type ZodiacCardProps = {
  isSelected: boolean;
  isUserZodiac: boolean;
  title: string;
  onClick: (id: number) => void;
  id: number;
  iconUrl: string;
  isSecondSigns?: boolean;
};
const ZodiacCard: FC<ZodiacCardProps> = ({
  isSelected,
  title,
  onClick,
  id,
  isUserZodiac,
  iconUrl,
  isSecondSigns = false,
}) => {
  const titleClassNames = classNames(styles.title, {
    [styles.selectedTitle]: isUserZodiac,
  });

  const circleClassNames = classNames(styles.circle, {
    [styles.selectedCircle]: !isSecondSigns && isSelected,
    [styles.selectedBlueCircle]: isSecondSigns && isSelected,
  });

  return (
    <div className={styles.container} onClick={() => onClick(id)}>
      <p className={titleClassNames}>{title}</p>
      <div className={circleClassNames}>
        <img src={iconUrl} alt="" />
      </div>
    </div>
  );
};

export default ZodiacCard;
