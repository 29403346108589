import React from "react";

const CorrectIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="correct">
      <path
        id="Vector"
        d="M17 1C8.75 1 2 7.75 2 16C2 24.25 8.75 31 17 31C25.25 31 32 24.25 32 16C32 7.75 25.25 1 17 1ZM25.4 11.9L16 21.25C15.75 21.5 15.45 21.5999 15.0999 21.5999C14.75 21.5999 14.5 21.5 14.25 21.25L9.85 16.85C9.35 16.35 9.35 15.6 9.85 15.1C10.35 14.6 11.15 14.6 11.6 15.1L15.1 18.6L23.6 10.1C24.1 9.6 24.9 9.6 25.35 10.1C25.9001 10.6 25.9001 11.4001 25.4001 11.9001L25.4 11.9Z"
        fill="#5A8C8D"
      />
    </g>
  </svg>
);

export default CorrectIcon;
