import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import CookieIcon from "assets/svg/cookie.svg";
import OpenedCookieIcon from "assets/svg/opened-cookie.svg";
import StarsLineIcon from "assets/svg/line-stars.svg";
import "./styles.scss";
import { animate, animateElements } from "utils/animations";
import DefaultButton from "../../components/buttons/DefaultButton";
import Arrow from "assets/svg/arrow-2.svg";
import http from "helpers/http";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
const FortuneCookiePage = () => {
  const [fortuneCookie, setFortuneCookie] = useState("");

  const getPredict = async () => {
    const res: any = await http.get("/astro/fortune-cookie");
    setFortuneCookie(res.astrologicData.prediction.message);
  };

  useEffect(() => {
    ReactPixel.track("Fortune Cookie Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/fortune-cookie",
      title: "Fortune Cookie Page",
    });
  }, []);

  const startAnimation = async () => {
    await getPredict();
    const cookie = document.getElementById("cookie");
    const lineStars = document.getElementById("line-stars");
    const openedCookie = document.getElementById("opened-cookie");
    const cookieDescription = document.getElementById("cookie-description");
    const header1 = document.getElementById("header1");
    const header2 = document.getElementById("header2");
    const bottomBtn = document.getElementById("bottom-btn");
    const arrow = document.getElementById("arrow");

    if (cookie) await animate(cookie, "opacity-out");
    if (lineStars && header1 && arrow) {
      await animateElements([
        { elem: arrow, animation: "opacity-out" },
        { elem: lineStars, animation: "line-stars-out" },
        { elem: header1, animation: "opacity-out" },
      ]);
      lineStars.className = "display-none";
      header1.className = "display-none";
    }
    if (cookie) cookie.className = "display-none";
    if (openedCookie && header2) {
      header2.classList.remove("display-none");
      openedCookie.classList.remove("display-none");
      await animateElements([
        { elem: header2, animation: "opacity-in" },
        { elem: openedCookie, animation: "opacity-in" },
      ]);
    }
    if (cookieDescription) {
      cookieDescription.classList.remove("display-none");
      await animate(cookieDescription, "opacity-in");
    }
    if (bottomBtn) {
      bottomBtn.classList.remove("display-none");
      await animate(bottomBtn, "opacity-in");
    }
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className="position-relative height100">
          <HeaderWithBack />
          <h1 className="t-align-l">
            What is <span className="yellow-text">the Fortune Cookie</span>{" "}
            talking about today?
          </h1>
          <div className="mt24">
            <h2 id="header1">
              <span className="yellow-text">Tap</span> to open
            </h2>
            <h2 className="display-none" id="header2">
              Your answer is ready!
            </h2>
            <div className="flex flex-column i-align-c gap24 pointer position-relative padding-t40">
              <img
                id="arrow"
                src={Arrow}
                alt=""
                className="position-absolute"
                style={{ top: 0, right: "25%" }}
              />
              <img
                id="cookie"
                src={CookieIcon}
                width={120}
                height={123.33}
                alt={""}
                onClick={startAnimation}
              />
              <img
                className={"display-none"}
                src={OpenedCookieIcon}
                width={120}
                height={147.692}
                alt={""}
                id="opened-cookie"
              />
              <img src={StarsLineIcon} alt={""} id="line-stars" />
              <p
                className="fRoboto font-s18 font-w400 text-color-silver display-none"
                id="cookie-description"
              >
                {fortuneCookie}
              </p>
            </div>
          </div>
          <div
            className={"text bottom-container-absolute display-none"}
            id="bottom-btn"
          >
            <DefaultButton
              text="Ask again"
              type="button"
              onClick={getPredict}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FortuneCookiePage;
