import React from "react";
const AirIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_761_21693)">
      <path
        d="M28.9798 4.10001C26.9854 4.10001 25.0728 4.89234 23.6625 6.3026C22.2523 7.7129 21.46 9.62562 21.46 11.6199C21.46 12.0485 21.6887 12.4448 22.06 12.6591C22.4313 12.8734 22.8887 12.8734 23.26 12.6591C23.6313 12.4448 23.86 12.0486 23.86 11.6199C23.86 10.262 24.3995 8.95962 25.3596 7.99959C26.3198 7.03943 27.6221 6.5 28.98 6.5C30.3379 6.5 31.6402 7.03946 32.6003 7.99959C33.5604 8.95976 34.0999 10.2621 34.0999 11.6199C34.0999 12.9777 33.5604 14.2801 32.6003 15.2402C31.6401 16.2005 30.3378 16.7398 28.98 16.7398H1.70004C1.27127 16.7398 0.875172 16.9686 0.66084 17.3398C0.446387 17.7112 0.446387 18.1686 0.66084 18.5398C0.875162 18.9112 1.27124 19.1398 1.70004 19.1398H28.98C30.9745 19.1398 32.8869 18.3476 34.2973 16.9374C35.7076 15.5271 36.4999 13.6143 36.4999 11.6201C36.4999 9.62576 35.7075 7.71306 34.2973 6.30273C32.887 4.8924 30.9743 4.10014 28.98 4.10014L28.9798 4.10001Z"
        fill="#79B7C0"
      />
      <path
        d="M13.3 12.3H1.70004C1.27127 12.3 0.875172 12.5287 0.66084 12.9C0.446387 13.2713 0.446387 13.7287 0.66084 14.1C0.875162 14.4713 1.27124 14.7 1.70004 14.7H13.3C14.6155 14.7 15.8771 14.1774 16.8073 13.2472C17.7374 12.317 18.2601 11.0554 18.2601 9.7399C18.2601 8.42454 17.7374 7.16294 16.8073 6.23291C15.8771 5.30271 14.6155 4.78018 13.3 4.78018C11.9845 4.78018 10.7229 5.30271 9.79268 6.23291C8.86261 7.16311 8.33995 8.4247 8.33995 9.7399C8.33995 10.1687 8.56873 10.5648 8.93995 10.7792C9.31131 10.9936 9.76871 10.9936 10.1399 10.7792C10.5113 10.5648 10.7399 10.1687 10.7399 9.7399C10.7399 9.061 11.0097 8.40984 11.4898 7.92974C11.9699 7.44967 12.6211 7.18001 13.3 7.18001C13.9789 7.18001 14.63 7.44967 15.1101 7.92974C15.5902 8.4098 15.86 9.061 15.86 9.7399C15.86 10.4189 15.5902 11.0701 15.1101 11.5502C14.6301 12.0303 13.9789 12.2999 13.3 12.2999L13.3 12.3Z"
        fill="#79B7C0"
      />
      <path
        d="M17.2598 23.7799C18.2464 23.7799 19.1926 24.1719 19.8903 24.8694C20.5878 25.5671 20.9797 26.5133 20.9797 27.4999C20.9797 28.4865 20.5878 29.4327 19.8903 30.1303C19.1926 30.828 18.2464 31.2199 17.2598 31.2199C16.2732 31.2199 15.327 30.828 14.6294 30.1303C13.9317 29.4327 13.5398 28.4865 13.5398 27.4999C13.5398 27.0712 13.3111 26.675 12.9398 26.4607C12.5685 26.2463 12.1111 26.2463 11.7398 26.4607C11.3685 26.675 11.1398 27.0712 11.1398 27.4999C11.1398 28.7103 11.4988 29.8936 12.1711 30.8999C12.8437 31.9062 13.7995 32.6907 14.9177 33.1539C16.036 33.617 17.2666 33.7382 18.4537 33.5022C19.641 33.266 20.7314 32.6832 21.5873 31.8272C22.735 30.6795 23.3797 29.1229 23.3797 27.4999C23.3797 25.8768 22.7349 24.3202 21.5873 23.1725C20.4395 22.0249 18.8829 21.3801 17.26 21.3801H1.70004C1.27127 21.3801 0.875172 21.6088 0.66084 21.9801C0.446387 22.3513 0.446387 22.8087 0.66084 23.1801C0.875162 23.5513 1.27124 23.7801 1.70004 23.7801L17.2598 23.7799Z"
        fill="#79B7C0"
      />
    </g>
    <defs>
      <clipPath id="clip0_761_21693">
        <rect
          width="36"
          height="36"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AirIcon;
