import React from "react";

const WaterIcon = () => (
  <svg
    width="33"
    height="25"
    viewBox="0 0 33 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_761_21673)">
      <path
        d="M32.1377 22.4094C31.6564 22.0532 31.2246 21.6345 30.8536 21.1644C29.9481 20.1144 28.9216 18.9435 26.8685 18.9435C24.8154 18.9435 23.7888 20.1144 22.8833 21.1644C22.0637 22.105 21.4157 22.8466 20.0769 22.8466C18.7381 22.8466 18.0863 22.105 17.2667 21.1644C16.3611 20.1144 15.3347 18.9435 13.2815 18.9435C11.2284 18.9435 10.2019 20.1144 9.29639 21.1644C8.48056 22.105 7.8327 22.8466 6.48997 22.8466C5.14725 22.8466 4.50332 22.105 3.68356 21.1644C3.12222 20.427 2.41389 19.8142 1.60326 19.365C1.35424 19.2521 1.06443 19.2776 0.839022 19.4323C0.613624 19.5869 0.485557 19.8481 0.5013 20.121C0.517182 20.394 0.674478 20.6387 0.916273 20.7662C1.53441 21.1302 2.07438 21.6132 2.50486 22.187C3.41434 23.237 4.44084 24.4079 6.49001 24.4079C8.53918 24.4079 9.56961 23.237 10.4751 22.187C11.2948 21.2464 11.9428 20.5047 13.2816 20.5047C14.6204 20.5047 15.2682 21.2464 16.088 22.187C16.9935 23.237 18.02 24.4079 20.077 24.4079C22.1339 24.4079 23.1566 23.237 24.0622 22.187C24.878 21.2464 25.526 20.5047 26.8686 20.5047C28.2113 20.5047 28.8592 21.2464 29.675 22.187C30.1365 22.7675 30.6765 23.2812 31.2792 23.7132C31.452 23.8312 31.6649 23.8748 31.8703 23.8344C32.0757 23.7939 32.2561 23.6727 32.3713 23.4978C32.4864 23.3231 32.5265 23.1094 32.4827 22.9047C32.4387 22.7002 32.3146 22.5217 32.1378 22.4095L32.1377 22.4094Z"
        fill="#4E67B5"
      />
      <path
        d="M32.1377 16.5547C31.6566 16.1984 31.2247 15.7797 30.8536 15.3097C29.9481 14.2597 28.9216 13.0888 26.8685 13.0888C24.8154 13.0888 23.7889 14.2597 22.8833 15.3097C22.0637 16.2503 21.4157 16.992 20.0731 16.992C18.7304 16.992 18.0863 16.2503 17.2667 15.3097C16.3611 14.2597 15.3347 13.0888 13.2815 13.0888C11.2284 13.0888 10.2019 14.2597 9.29639 15.3097C8.48056 16.2503 7.8327 16.992 6.48998 16.992C5.14725 16.992 4.50332 16.2503 3.68356 15.3097C3.12222 14.5723 2.41389 13.9595 1.60326 13.5104C1.35424 13.3974 1.06443 13.423 0.839022 13.5776C0.613624 13.7322 0.485557 13.9934 0.5013 14.2664C0.517182 14.5393 0.674478 14.784 0.916273 14.9116C1.53441 15.2756 2.07438 15.7585 2.50487 16.3323C3.41434 17.3823 4.44084 18.5532 6.49001 18.5532C8.53918 18.5532 9.56961 17.3823 10.4752 16.3323C11.2948 15.3917 11.9388 14.6501 13.2816 14.6501C14.6243 14.6501 15.2722 15.3917 16.088 16.3323C16.9935 17.3823 18.02 18.5532 20.0731 18.5532C22.1263 18.5532 23.1527 17.3823 24.0583 16.3323C24.8779 15.3917 25.5259 14.6501 26.8685 14.6501C28.2112 14.6501 28.8591 15.3917 29.6749 16.3323C30.1364 16.9128 30.6762 17.4265 31.2791 17.8585C31.4519 17.9766 31.6648 18.0201 31.8702 17.9797C32.0756 17.9392 32.256 17.818 32.3712 17.6431C32.4863 17.4684 32.5264 17.2547 32.4826 17.0501C32.4386 16.8455 32.3145 16.667 32.1377 16.5547Z"
        fill="#4E67B5"
      />
      <path
        d="M32.1377 10.3097C31.6564 9.95341 31.2246 9.53476 30.8536 9.06466C29.9481 8.01467 28.9216 6.84372 26.8685 6.84372C24.8154 6.84372 23.7888 8.01467 22.8833 9.06466C22.0637 10.0053 21.4157 10.7469 20.0769 10.7469C18.7381 10.7469 18.0863 10.0053 17.2667 9.06466C16.3611 8.01467 15.3347 6.84372 13.2815 6.84372C11.2284 6.84372 10.2019 8.01467 9.29639 9.06466C8.48056 10.0053 7.8327 10.7469 6.48997 10.7469C5.14725 10.7469 4.50332 10.0053 3.68356 9.06466C3.12222 8.32722 2.41389 7.71442 1.60326 7.26529C1.35424 7.15234 1.06443 7.17788 0.839022 7.33251C0.613624 7.48713 0.485557 7.74836 0.5013 8.02129C0.517182 8.2942 0.674478 8.53891 0.916273 8.66648C1.53441 9.03049 2.07438 9.51344 2.50486 10.0872C3.41434 11.1372 4.44084 12.3082 6.49001 12.3082C8.53918 12.3082 9.56961 11.1372 10.4751 10.0872C11.2948 9.14664 11.9428 8.405 13.2816 8.405C14.6204 8.405 15.2682 9.14664 16.088 10.0872C16.9935 11.1372 18.02 12.3082 20.077 12.3082C22.1339 12.3082 23.1566 11.1372 24.0622 10.0872C24.878 9.14664 25.526 8.405 26.8686 8.405C28.2113 8.405 28.8592 9.14664 29.675 10.0872C30.1365 10.6678 30.6765 11.1815 31.2792 11.6134C31.452 11.7315 31.6649 11.7751 31.8703 11.7347C32.0757 11.6941 32.2561 11.5729 32.3713 11.3981C32.4864 11.2234 32.5265 11.0097 32.4827 10.805C32.4387 10.6004 32.3146 10.4219 32.1378 10.3097L32.1377 10.3097Z"
        fill="#4E67B5"
      />
      <path
        d="M32.1377 4.45485C31.6566 4.09845 31.2247 3.67981 30.8536 3.20983C29.9481 2.15984 28.9216 0.988892 26.8685 0.988892C24.8154 0.988892 23.7889 2.15984 22.8833 3.20983C22.0637 4.15043 21.4157 4.89207 20.0731 4.89207C18.7304 4.89207 18.0863 4.15043 17.2667 3.20983C16.3611 2.15984 15.3347 0.988892 13.2815 0.988892C11.2284 0.988892 10.2019 2.15984 9.29639 3.20983C8.48056 4.15043 7.8327 4.89207 6.48998 4.89207C5.14725 4.89207 4.50332 4.15043 3.68356 3.20983C3.12222 2.47239 2.41389 1.85959 1.60326 1.41047C1.35424 1.29751 1.06443 1.32305 0.839022 1.47768C0.613624 1.63231 0.485557 1.89353 0.5013 2.16646C0.517182 2.43938 0.674478 2.68409 0.916273 2.81166C1.53441 3.17566 2.07438 3.65861 2.50487 4.23241C3.41434 5.2824 4.44084 6.45335 6.49001 6.45335C8.53918 6.45335 9.56961 5.2824 10.4752 4.23241C11.2948 3.29181 11.9388 2.55018 13.2816 2.55018C14.6243 2.55018 15.2722 3.29181 16.088 4.23241C16.9935 5.2824 18.02 6.45335 20.0731 6.45335C22.1263 6.45335 23.1527 5.2824 24.0583 4.23241C24.8779 3.29181 25.5259 2.55018 26.8685 2.55018C28.2112 2.55018 28.8591 3.29181 29.6749 4.23241C30.1364 4.81294 30.6762 5.32663 31.2791 5.75862C31.4519 5.87665 31.6648 5.92024 31.8702 5.87983C32.0756 5.8393 32.256 5.71809 32.3712 5.54325C32.4863 5.36854 32.5264 5.15485 32.4826 4.95016C32.4386 4.7456 32.3145 4.5671 32.1377 4.45488L32.1377 4.45485Z"
        fill="#4E67B5"
      />
    </g>
    <defs>
      <clipPath id="clip0_761_21673">
        <rect
          width="32"
          height="24.8"
          fill="white"
          transform="translate(0.5 0.100006)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WaterIcon;
