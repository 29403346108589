import { createSlice } from "@reduxjs/toolkit";

type ModalsState = {
  successfulPurchaseModal: {
    isOpen: boolean;
  };
  stripeModal: {
    isOpen: boolean;
  };
};

const initialState: ModalsState = {
  successfulPurchaseModal: {
    isOpen: false,
  },
  stripeModal: {
    isOpen: false,
  },
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openSuccessfulPurchaseModal: (state) => {
      state.successfulPurchaseModal.isOpen = true;
    },
    closeSuccessfulPurchaseModal: (state) => {
      state.successfulPurchaseModal.isOpen = false;
    },
    openStripeModal: (state) => {
      state.stripeModal.isOpen = true;
    },
    closeStripeModal: (state) => {
      state.stripeModal.isOpen = false;
    },
  },
});

export const {
  openSuccessfulPurchaseModal,
  closeSuccessfulPurchaseModal,
  closeStripeModal,
  openStripeModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
