import React from "react";
const EngagementIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.08657 10.6647L6.91406 9.71207L6.9848 10.8623C7.00259 11.1509 7.21391 11.3865 7.4988 11.4353L8.54464 11.6147C8.78601 11.3422 9.04771 11.0843 9.32853 10.8428L8.66048 10.86C8.65296 10.8603 8.64534 10.8604 8.63781 10.8604C8.43742 10.8604 8.24248 10.7914 8.0866 10.6647L8.08657 10.6647Z"
      fill="#9B7BD0"
    />
    <path
      d="M14.4001 23.021C13.4326 22.9775 12.4829 22.6994 11.6457 22.212C10.7457 21.6879 9.99425 20.9308 9.47265 20.0226C8.67652 18.6364 8.46784 17.0231 8.88503 15.48C9.30229 13.9368 10.2955 12.6484 11.6819 11.8522C13.0681 11.0561 14.6814 10.8473 16.2245 11.2646C17.7677 11.6819 19.056 12.675 19.8522 14.0614C20.6484 15.4477 20.8571 17.061 20.4399 18.604C20.0226 20.1471 19.0294 21.4356 17.643 22.2318C17.4775 22.327 17.3064 22.4139 17.1327 22.4929C17.5082 23.0145 17.9624 23.4541 18.469 23.8056C18.4887 23.7945 18.5083 23.784 18.528 23.7727C22.2392 21.6412 23.5245 16.8878 21.393 13.1766C19.2615 9.46535 14.5081 8.18005 10.7969 10.3116C7.08563 12.4431 5.80054 17.1964 7.93184 20.9077C9.48613 23.6138 12.531 25.1226 15.604 24.7481C15.2085 24.3127 14.8571 23.8314 14.5572 23.3091C14.5025 23.2138 14.4505 23.1176 14.4001 23.0211L14.4001 23.021Z"
      fill="#E5E5E5"
    />
    <path
      d="M8.64821 10.4435L9.87319 10.4118C9.87427 10.4111 9.87526 10.4104 9.87625 10.4095L7.03706 7.0677L6.36298 8.23178C6.24954 8.42755 6.29472 8.67329 6.47038 8.81593L8.34794 10.3413C8.4325 10.4101 8.53849 10.4468 8.64812 10.4436L8.64821 10.4435Z"
      fill="#9B7BD0"
    />
    <path
      d="M11.9658 8.52215C12.0396 8.4241 12.0712 8.29818 12.0523 8.17678L11.6864 5.82139C11.6515 5.59773 11.462 5.4349 11.2358 5.43425L9.89062 5.42986L11.2935 9.41519L11.9658 8.52215Z"
      fill="#9B7BD0"
    />
    <path
      d="M10.5871 9.95001C10.7119 9.87835 10.8379 9.81042 10.9648 9.74605L9.47924 5.52603L7.32422 6.76376L10.2208 10.1734C10.3403 10.0962 10.4624 10.0216 10.5872 9.94997L10.5871 9.95001Z"
      fill="#9B7BD0"
    />
    <path
      d="M12.2976 8.77273L11.8555 9.35995C12.2511 9.21515 12.6531 9.10255 13.0582 9.02187L13.4303 8.02815C13.5316 7.75751 13.4346 7.45616 13.1944 7.29548L12.2364 6.65473L12.463 8.11271C12.4989 8.34479 12.4387 8.58525 12.2975 8.77273H12.2976Z"
      fill="#9B7BD0"
    />
    <path
      d="M29.8394 27.4609L27.9618 25.9355C27.8773 25.8668 27.7697 25.8305 27.6615 25.8333L26.4366 25.865C26.4356 25.8657 26.4346 25.8664 26.4336 25.8671L29.2726 29.2089L29.9467 28.0449C30.0601 27.8492 30.015 27.6035 29.8393 27.4608L29.8394 27.4609Z"
      fill="#5A8C8D"
    />
    <path
      d="M24.3458 27.7553C24.272 27.8533 24.2404 27.9793 24.2593 28.1007L24.6252 30.4563C24.66 30.6798 24.8495 30.8427 25.0757 30.8434L26.4209 30.8477L25.018 26.8624L24.3458 27.7553Z"
      fill="#5A8C8D"
    />
    <path
      d="M25.7216 26.3277C25.5971 26.3991 25.4708 26.4662 25.3438 26.5308L26.8295 30.7516L28.9848 29.5139L26.0882 26.1043C25.9686 26.1815 25.8465 26.256 25.7217 26.3277L25.7216 26.3277Z"
      fill="#5A8C8D"
    />
    <path
      d="M24.0123 27.5053L24.4532 26.9196C24.0597 27.0643 23.6578 27.1781 23.2503 27.26L22.8797 28.2499C22.7783 28.5206 22.8753 28.8219 23.1156 28.9827L24.0735 29.6233L23.8469 28.1653C23.8109 27.9333 23.8712 27.6928 24.0123 27.5053Z"
      fill="#5A8C8D"
    />
    <path
      d="M28.3814 15.3702C26.8267 12.6634 23.7801 11.1546 20.7064 11.5302C21.0999 11.9617 21.4532 12.4418 21.7559 12.9689C21.8106 13.0641 21.8625 13.1601 21.913 13.2567C22.8791 13.3001 23.8276 13.5775 24.6641 14.0638C25.5657 14.5879 26.3183 15.3457 26.8407 16.2552C27.6368 17.6415 27.8456 19.2548 27.4283 20.7978C27.0111 22.341 26.0178 23.6294 24.6315 24.4256C23.6945 24.9638 22.6711 25.2194 21.661 25.2194C19.5862 25.2195 17.5666 24.1411 16.4611 22.2165C14.8175 19.3549 15.8086 15.6896 18.6701 14.0461C18.8359 13.951 19.007 13.8639 19.1807 13.7849C18.8106 13.2698 18.3605 12.8269 17.8479 12.4704C17.827 12.4822 17.806 12.4934 17.7852 12.5053C14.074 14.6368 12.7887 19.3902 14.9202 23.1014C15.9527 24.8992 17.6236 26.1872 19.6249 26.7284C21.6265 27.2694 23.7186 26.9989 25.5163 25.9663C29.2274 23.835 30.5127 19.0814 28.3811 15.3701L28.3814 15.3702Z"
      fill="#E5E5E5"
    />
    <path
      d="M28.8141 24.8422L27.7683 24.6628C27.5269 24.9353 27.2652 25.1933 26.9844 25.4347L27.6524 25.4174C27.8619 25.4134 28.0646 25.4814 28.2262 25.6128L29.3987 26.5654L29.328 25.4152C29.3103 25.1267 29.099 24.8911 28.8141 24.8422Z"
      fill="#5A8C8D"
    />
  </svg>
);

export default EngagementIcon;
