import PersonalIcon from "assets/svg/personal.svg";
import LovePersonIcon from "assets/svg/love-person.svg";
import FriendsIcon from "assets/svg/Frienship.svg";
export const cardsData = [
  {
    title: "Romantic",
    iconUrl: LovePersonIcon,
    text: "Explore the cosmic chemistry in your relationship with our Romantic Compatibility Report! Get a personalized astrological analysis that reveals how well your stars align, offering insights into your unique connection. Perfect for new and enduring relationships alike, let the stars guide your romantic journey!",
    btnText: "Unlock Romantic Report",
    purchasedBtnText: "See your Romantic Report",
    navigateTo: "/romantic-detail",
  },
  {
    title: "Friendship",
    iconUrl: FriendsIcon,
    btnText: "Unlock Friendship Report",
    purchasedBtnText: "See Friendship Report",
    navigateTo: "/friendship-detail",
    text: "Discover the astrological secrets of your friendships with our Friendship Compatibility Report. This personalized analysis explores how your stars align with a friend, revealing the unique dynamics of your bond. Understand and strengthen your connections under the guidance of the stars!",
  },
  {
    title: "Personal Connection",
    iconUrl: PersonalIcon,
    purchasedBtnText: "See Personal Connection Report",
    text: "Unveil the deeper aspects of your relationships with our Personal Connection Report. This tailored astrological analysis goes beyond the surface, exploring the intricate dynamics of your individual interactions. Whether it's a friend, family member, or colleague, understand and enhance your connections with the insightful wisdom of the stars.",
    btnText: "Unlock Personal Connection Report",
    navigateTo: "/personal-connection-detail",
  },
];
