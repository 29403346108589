import React, { forwardRef } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { Subscription } from "../PaymentPage";
import DefaultButton from "components/buttons/DefaultButton";
import StripeModal from "components/modals/StripeModal/StripeModal";

export const subscriptionsList: Subscription[] = [
  {
    id: "1c9a336b-9658-42d1-ae29-456241eccd01",
    price: "$1",
    period: "3-day trial",
    text: "then 2 weeks plan - $1.35/Day",
    perDay: "$1",
    oldPrice: "$18.99",
    priceId: "price_1P06lbItCWgNXKMKNOjcijjF", //live price
    // priceId: "price_1OvaOzItCWgNXKMKaTpwXLai", //test
  },
  {
    id: "62fca830-750c-4784-8482-7a3799d55d4a",
    price: "$3.55",
    period: "1-week trial",
    text: "Then 2 weeks plan - $1.10/Day",
    perDay: "$0.50/day",
    oldPrice: "$19.99",
    label: "most popular",
    // priceId: "price_1P06mIItCWgNXKMKhf6OOAn9", //live price
    priceId: "price_1OvvXuItCWgNXKMKSuYfCRPz", //test,
  },
  {
    id: "b88348e1-dff8-4591-baeb-b998f53ed2e0",
    price: "$9.99",
    period: "2-week trial",
    text: "Then one month plan - $0.90/Day",
    perDay: "$0.80/day",
    oldPrice: "$28.99",
    // priceId: "price_1P06nAItCWgNXKMKXGOrbzdP", //live price
    priceId: "price_1OvaSsItCWgNXKMKp4gyGR2m", //test
  },
];

type SubscriptionListProps = {
  isModalOpen: boolean;
  setSubscriptionType: (val: string) => void;
  subscriptionType: string;
  toggleButtonModal: (val: boolean) => void;
  isStripeOpen: boolean;
  setIsOpenStripe: (val: boolean) => void;
  subscriptionData: any;
  setSubscriptionData: (val: any) => void;
};

const SubscriptionList = forwardRef<any, SubscriptionListProps>(
  (
    {
      isModalOpen,
      setSubscriptionType,
      subscriptionType,
      toggleButtonModal,
      isStripeOpen,
      setIsOpenStripe,
      subscriptionData,
      setSubscriptionData,
    },
    ref,
  ) => {
    return (
      <>
        {subscriptionsList.map((subscription) => (
          <SubscriptionCard
            itemData={subscription}
            key={subscription.id}
            label={subscription.label}
            isSelected={subscriptionType === subscription.id}
            onClick={() => {
              if (!isModalOpen) {
                setSubscriptionType(subscription.id);
                // setPriceId(subscription.priceId);
                toggleButtonModal(false);
                setSubscriptionData(subscription);
                setIsOpenStripe(true);
              }
            }}
          />
        ))}
        <div ref={ref} className={"mt32"}>
          <DefaultButton
            text="Start Trial"
            type="button"
            isDisabled={isModalOpen}
            onClick={() => {
              console.log("open stripe");
              toggleButtonModal(false);
              setTimeout(() => setIsOpenStripe(true), 100);
            }}
            customStyles="button-shadow"
          />
          {isStripeOpen && (
            <StripeModal
              isOpen={true}
              paymentType="payment"
              setIsOpenStripe={setIsOpenStripe}
              toggleButtonModal={toggleButtonModal}
              subscriptionData={subscriptionData}
            />
          )}
        </div>
      </>
    );
  },
);

export default SubscriptionList;

SubscriptionList.displayName = "SubscriptionList";
