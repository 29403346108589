import React, { ChangeEventHandler, FC } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
const { REACT_APP_STRIPE_SK } = process.env;

export const stripePromise = loadStripe(`${REACT_APP_STRIPE_SK}`);

type StripeProps = {
  setCardName: ChangeEventHandler<HTMLInputElement>;
  toggleModal: (value: boolean) => void;
  cardName: string;
  paymentType?: "subscription" | "report";
  planData: {
    id: string;
    price?: string;
    period?: string;
    oldPrice?: string;
  };
};
const Stripe: FC<StripeProps> = ({
  setCardName,
  cardName,
  planData,
  toggleModal,
  paymentType,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        setCardName={setCardName}
        cardName={cardName}
        toggleModal={toggleModal}
        planData={planData}
        paymentType={paymentType}
      />
    </Elements>
  );
};

export default Stripe;
