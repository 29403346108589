import React from "react";
import { Gender, gendersList } from "constants/genders";
import GenderCard from "../../../Registration/steps/GenderSelect/GenderCard";
import { useAppDispatch, useAppSelector } from "store";
import { setPartnerGenderType } from "store/slices/partnerRegistration";

const GenderSelect = () => {
  const dispatch = useAppDispatch();
  const selectedGender = useAppSelector(
    (state) => state.partnerRegistration.gender,
  );

  const setGenderType = (genderType: string) =>
    dispatch(setPartnerGenderType(genderType));

  return (
    <div>
      <h2>
        Select your partner <span className="yellow-text">gender</span>
      </h2>
      <p className="fRoboto font-s16 text-color-silver font-w400 mt8">
        Your gender tailors astro insights to your unique energy.
      </p>
      <div className="flex i-align-s gap16" style={{ marginTop: 69 }}>
        {gendersList.map((gender: Gender) => (
          <GenderCard
            setGenderType={setGenderType}
            type={gender.type}
            icon={gender.icon}
            isSelected={selectedGender === gender.type}
            key={gender.id}
          />
        ))}
      </div>
    </div>
  );
};

export default GenderSelect;
