import React from "react";

const NavPredictionsIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6899 19.6424H1.2177C1.0141 19.6424 0.848633 19.8077 0.848633 20.0115V26.3115C0.848633 26.5151 1.0141 26.6805 1.2177 26.6805H2.6899C2.89349 26.6805 3.05896 26.5151 3.05896 26.3115V20.0115C3.05474 19.8077 2.89349 19.6424 2.6899 19.6424Z"
      fill="#7DF9FF"
    />
    <path
      d="M26.7827 1.32361H25.3105C25.1069 1.32361 24.9414 1.48907 24.9414 1.69267V7.99267C24.9414 8.19626 25.1069 8.36173 25.3105 8.36173H26.7827C26.9863 8.36173 27.1517 8.19627 27.1517 7.99267V1.68827C27.1517 1.48889 26.9863 1.32343 26.7827 1.32343V1.32361Z"
      fill="#7DF9FF"
    />
    <path
      d="M17.4109 19.4644C17.4109 19.0318 17.0631 18.6838 16.6303 18.6838H11.3695C10.9369 18.6838 10.5889 19.0316 10.5889 19.4644V20.1814H17.4109L17.4109 19.4644Z"
      fill="#7DF9FF"
    />
    <path
      d="M6.87743 10.6612L7.35679 9.15933L8.85851 8.68417L7.35679 8.20481L6.87743 6.70293L6.40227 8.20481L4.90039 8.68417L6.40227 9.15933L6.87743 10.6612Z"
      fill="#7DF9FF"
    />
    <path
      d="M22.6209 9.25281L22.1415 10.7547L20.6396 11.2298L22.1415 11.7092L22.6209 13.2111L23.096 11.7092L24.5978 11.2298L23.096 10.7547L22.6209 9.25281Z"
      fill="#7DF9FF"
    />
    <path
      d="M14.0003 7.942C10.8015 7.942 8.20947 10.5342 8.20947 13.7328C8.20947 15.5402 9.04103 17.148 10.3434 18.2128C10.6234 17.9837 10.9798 17.8395 11.3701 17.8395H16.6309C17.0212 17.8395 17.3776 17.9837 17.6576 18.2128C18.9558 17.1522 19.7916 15.5444 19.7916 13.7328C19.7916 10.5339 17.1994 7.942 14.0008 7.942H14.0003ZM14.0003 10.0631C11.9767 10.0631 10.3306 11.7092 10.3306 13.7328C10.3306 13.966 10.1397 14.157 9.90639 14.157C9.67311 14.157 9.48219 13.966 9.48219 13.7328C9.48219 11.2424 11.51 9.21476 14.0002 9.21476C14.2335 9.21476 14.4244 9.4057 14.4244 9.63896C14.4244 9.8724 14.2335 10.0632 14.0002 10.0632L14.0003 10.0631Z"
      fill="#7DF9FF"
    />
    <path
      d="M18.5694 22.5612C18.3616 22.4509 18.1282 22.4339 17.9161 22.5103L13.0841 24.2454C12.838 24.3812 12.5496 24.4576 12.2526 24.4576H10.161C9.92772 24.4576 9.7368 24.2667 9.7368 24.0334C9.7368 23.8001 9.92773 23.6092 10.161 23.6092H12.2526C12.401 23.6092 12.5452 23.5711 12.6684 23.5073C12.6854 23.4947 12.7065 23.4819 12.7277 23.4734C12.9145 23.3589 13.0459 23.1764 13.0799 22.9643C13.118 22.7311 13.0545 22.5019 12.906 22.3279C12.7576 22.154 12.5412 22.0522 12.3121 22.0522H10.1315C9.47817 22.0522 8.82473 21.8189 8.29021 21.3903C7.50957 20.7667 5.95693 19.9522 3.89941 20.9745V25.3485C4.59097 25.4036 5.64721 25.586 7.00909 26.1207C8.62129 26.7529 10.3394 26.8971 11.9771 26.5407C13.6655 26.1716 16.1899 25.4249 18.6079 23.94C18.786 23.834 18.9134 23.6516 18.9601 23.4436C19.0491 23.0916 18.8837 22.731 18.5698 22.5613L18.5694 22.5612Z"
      fill="#7DF9FF"
    />
    <path
      d="M9.43117 5.4388C9.63898 5.54911 9.87241 5.56614 10.0803 5.48974L14.9122 3.75882C15.1583 3.62304 15.4468 3.54663 15.7438 3.54663H18.2596C18.4929 3.54663 18.6838 3.73756 18.6838 3.97083C18.6838 4.20409 18.4929 4.39503 18.2596 4.39503H15.7438C15.5954 4.39503 15.4512 4.43315 15.328 4.4969C15.3068 4.50956 15.2899 4.52237 15.2644 4.53081C15.0777 4.64534 14.9463 4.82784 14.9122 5.03989C14.8741 5.27317 14.9377 5.50237 15.0861 5.67629C15.2346 5.8502 15.451 5.95207 15.6801 5.95207H17.8607C18.514 5.95207 19.1716 6.18535 19.7019 6.61395C20.4826 7.23755 22.0352 8.05207 24.0927 7.02975L24.0926 2.65575C23.401 2.60059 22.3448 2.41825 20.9829 1.88355C19.3707 1.25135 17.6526 1.10715 16.0149 1.46355C14.3265 1.83261 11.8021 2.57935 9.3841 4.05995C9.21019 4.16604 9.07863 4.34839 9.03191 4.56059C8.95145 4.9084 9.11691 5.26903 9.43082 5.43871L9.43117 5.4388Z"
      fill="#7DF9FF"
    />
  </svg>
);

export default NavPredictionsIcon;
