import React from "react";
const ArrowRight = () => (
  <svg
    width="5"
    height="7"
    viewBox="0 0 5 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07591 3.48959L1.45316 0.857844C1.30047 0.714134 1.06693 0.714134 0.923212 0.857844C0.779502 1.00155 0.779502 1.24407 0.923212 1.38779L3.28551 3.75009L0.923212 6.1124C0.779502 6.2651 0.779502 6.4986 0.923212 6.64235C1.06692 6.78605 1.30046 6.78605 1.45316 6.64235L4.07591 4.01959C4.21962 3.8669 4.21962 3.63331 4.07591 3.48959Z"
      fill="#7DF9FF"
    />
  </svg>
);

export default ArrowRight;
