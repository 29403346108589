import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import styles from "./LuckyNumberPage.module.scss";
import LuckyCard from "components/cards/LuckyCard/LuckyCard";
import { useAppSelector } from "../../store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const LuckyNumberPage = () => {
  const { luckyNumbers } = useAppSelector(
    (state) => state.horoscope.daily.prediction,
  );
  const content = () => {
    return <p className={styles.numbers}>{luckyNumbers.join(", ")}</p>;
  };

  useEffect(() => {
    ReactPixel.track("Lucky Numbers Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/lucky-numbers",
      title: "Lucky Numbers Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className={styles.content}>
          <h1>Numbers</h1>
          <LuckyCard
            content={content}
            text="Dear Aries, today's cosmic energy is illuminated by the mystical aura of the letters 5, 6, and 7. These numbers bring a sense of dynamic harmony and balance to your journey. 5 encourages adaptability and versatility, prompting you to embrace change with open arms. 6, a symbol of harmony and nurturing, suggests finding equilibrium in your relationships and surroundings. Lastly, 7, a beacon of introspection and wisdom, invites you to trust your instincts and seek deeper understanding. Allow the cosmic trio of 5-6-7 to guide your day with a balanced blend of flexibility, connection, and introspective insight. Fortune favors those who navigate the cosmic currents with grace."
          />
        </div>
      </div>
    </Layout>
  );
};
export default LuckyNumberPage;
