import Mandala2 from "assets/svg/twelve-cards-icons/mandala-2.svg";
import Mandala1 from "assets/svg/twelve-cards-icons/mandala-1.svg";
import Mandala3 from "assets/svg/twelve-cards-icons/mandala-3.svg";
import Mandala4 from "assets/svg/twelve-cards-icons/mandala-4.svg";
import Mandala5 from "assets/svg/twelve-cards-icons/mandala-5.svg";
import Mandala6 from "assets/svg/twelve-cards-icons/mandala-6.svg";
import Mandala7 from "assets/svg/twelve-cards-icons/mandala-7.svg";
import Mandala8 from "assets/svg/twelve-cards-icons/mandala-8.svg";
import Mandala9 from "assets/svg/twelve-cards-icons/mandala-9.svg";
import Mandala10 from "assets/svg/twelve-cards-icons/mandala-10.svg";
import Mandala11 from "assets/svg/twelve-cards-icons/mandala-11.svg";
import Mandala12 from "assets/svg/twelve-cards-icons/mandala-12.svg";

export const cards = [
  {
    titleWhite: "The First House of ",
    titleYellow: "Self",
    imgUrl: Mandala1,
    text: "Your journey begins in the 1st House, the cradle of your identity. This is where the self takes shape, mirroring your outward personality, physical appearance, and the vital energy you radiate. It’s the front door of your life, opening up to the world and representing how others see you at first glance.",
  },
  {
    titleWhite: "The Second House of ",
    titleYellow: "Value",
    imgUrl: Mandala2,
    text: "The 2nd House is where you establish what matters most to you. It governs your values, resources, and the tangibles of life, including personal finances. This house shapes your relationship with material possessions and your sense of security, reflecting what you value beyond money.",
  },
  {
    titleWhite: "The Third House of ",
    titleYellow: "Connections",
    imgUrl: Mandala3,
    text: "In the 3rd House, communication reigns. This sphere oversees how you think, speak, and connect with others. It's about short trips, siblings, and early education, reflecting your interaction with the immediate environment. It's where ideas travel and relationships form.",
  },
  {
    titleWhite: "The Fourth House of ",
    titleYellow: "Roots",
    imgUrl: Mandala4,
    text: "The 4th House is your emotional foundation, the bedrock of your being. It represents home, family, and ancestry, shaping your sense of belonging and inner security. It's where you retreat and recharge, holding the memories and traditions that define your emotional world.",
  },
  {
    titleWhite: "The Fifth House of ",
    titleYellow: "Joy",
    imgUrl: Mandala5,
    text: "Welcome to the 5th House, the playground of self-expression! This house is all about creativity, romance, and the things that bring you joy. It oversees leisure, love affairs, and artistic endeavors, inviting you to express yourself and celebrate life’s pleasures.",
  },
  {
    titleWhite: "The Sixth House of ",
    titleYellow: "Service",
    imgUrl: Mandala6,
    text: "The 6th House is where routine meets purpose. It governs your daily life, work, and health, focusing on how you serve others and yourself. This house shapes your approach to wellness, work ethic, and the small rituals that bring order to your everyday existence.",
  },
  {
    titleWhite: "The Seventh House of ",
    titleYellow: "Partnerships",
    imgUrl: Mandala7,
    text: "In the 7th House, relationships take center stage. This domain is about partnerships, both personal and business, highlighting how you connect with others. It reflects your approach to marriage, contracts, and open enemies, revealing the dance of duality in your life.",
  },
  {
    titleWhite: "The Eighth House of ",
    titleYellow: "Transformation",
    imgUrl: Mandala8,
    text: "Delve into the mysteries of the 8th House, the realm of deep emotional connections, shared resources, and transformation. This house governs birth, death, and rebirth cycles, challenging you to confront the unknown and emerge renewed.",
  },
  {
    titleWhite: "The Ninth House of ",
    titleYellow: "Expansion",
    imgUrl: Mandala9,
    text: "Journey through the 9th House to expand your mind and soul. It oversees long-distance travel, higher education, and philosophy, urging you to explore and find meaning beyond your immediate environment. It’s where beliefs are formed and wisdom is sought.",
  },
  {
    titleWhite: "The Tenth House of ",
    titleYellow: "Achievement",
    imgUrl: Mandala10,
    text: "The 10th House is your public stage, where ambition and achievement come into play. It represents career, reputation, and legacy, reflecting how you build your mark on the world. This house is about your aspirations and the effort you put into realizing them.",
  },
  {
    titleWhite: "The Eleventh House of ",
    titleYellow: "Community",
    imgUrl: Mandala11,
    text: "In the 11th House, community and friendship bloom. This domain is about your social circles, hopes, and dreams. It governs groups, organizations, and collective causes, encouraging you to find your tribe and work towards shared aspirations.",
  },
  {
    titleWhite: "The Twelfth House of ",
    titleYellow: "The Soul",
    imgUrl: Mandala12,
    text: "The 12th House is the realm of the subconscious, where the intangible meets the infinite. It's about solitude, spirituality, and what lies hidden. This house invites introspection, urging you to connect with your inner self and the mysteries of the universe.",
  },
];
