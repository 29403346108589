import React from "react";
const PinkCircleIcon = ({ color }: { color: string }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="48" height="48" rx="24" fill={color} />
    <rect
      x="1"
      y="1"
      width="48"
      height="48"
      rx="24"
      stroke="#27394B"
      strokeWidth="2"
    />
  </svg>
);

export default PinkCircleIcon;
