import { createSlice } from "@reduxjs/toolkit";

type UserState = {
  userData: {
    genderType: string;
    placeOfBirth: string;
    relationshipStatus: string;
    birthTime: string;
    birthDayDate: string;
    zodiacName: string;
    naturalElement: string;
    preferColor: string;
    email: string;
    birthplaceLon: string;
    birthplaceLat: string;
    userName: string;
    palmPhotoUrl: string;
    expirationDate?: Date | undefined;
  };
};

const initialState: UserState = {
  userData: {
    genderType: "",
    placeOfBirth: "",
    relationshipStatus: "",
    birthTime: "",
    birthDayDate: "",
    zodiacName: "",
    naturalElement: "",
    preferColor: "",
    email: "",
    birthplaceLon: "",
    birthplaceLat: "",
    userName: "",
    palmPhotoUrl: "",
    expirationDate: undefined,
  },
};

export const userSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = { ...action.payload.userData };
    },
    addUserName: (state, action) => {
      state.userData.userName = action.payload;
    },
    updateUserAccountExpiration: (state, action) => {
      state.userData.expirationDate = action.payload;
    },
    clearUserState: () => {
      return initialState;
    },
  },
});

export const {
  setUserData,
  clearUserState,
  addUserName,
  updateUserAccountExpiration,
} = userSlice.actions;

export default userSlice.reducer;
