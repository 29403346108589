import React, { FC, useState } from "react";
import classNames from "classnames";
import styles from "./RelationshipHurdlesComponent.module.scss";
import ArrowDownSmall from "assets/svg/arrow-down-small.svg";
import ArrowUp from "assets/svg/arrow-up.svg";

type RelationshipHurdlesComponentProps = {
  title: string;
  mainText: string;
  secondText: string;
};
const RelationshipHurdlesComponent: FC<RelationshipHurdlesComponentProps> = ({
  title,
  mainText,
  secondText,
}) => {
  const [isShowSolution, showSolution] = useState(false);

  const toggleShowSolution = () => showSolution((prev) => !prev);

  return (
    <div className={classNames(styles.container)}>
      <h3 className={"mb12"}>{title}</h3>
      <p className={"mb16"}>{mainText}</p>
      <button
        type="button"
        className={classNames("linkBtn", styles.customBtn)}
        onClick={toggleShowSolution}
      >
        How to solve it
        {isShowSolution ? (
          <img src={ArrowUp} alt={""} />
        ) : (
          <img src={ArrowDownSmall} alt={""} />
        )}
      </button>
      {isShowSolution && <p className={"mt12"}>{secondText}</p>}
    </div>
  );
};

export default RelationshipHurdlesComponent;
