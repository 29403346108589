import React, { useEffect, useState } from "react";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import CloseBtnIcon from "assets/svg/close-btn";
import ShareIcon from "assets/svg/share.svg";
import CameraIcon from "assets/svg/camera.svg";
import { goNextStep, setPalmPhoto } from "store/slices/registration";
import { useAppDispatch } from "store";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { addClassToHiddenModalDiv } from "utils/defaults";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import heic2any from "heic2any";
import { startLoading, stopLoading } from "store/slices/loader";
import HandAnimation from "./animation/HandAnimation";
import ProtectIcon from "assets/svg/protect.svg";
import { setPaymentLocation } from "../../../../helpers/sessionStorageHelpers";

const InstructionStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setOpen] = useState(false);

  useEffect(() => {
    addClassToHiddenModalDiv();
  }, []);
  const openModal = () => {
    setOpen(true);
  };

  const headerContent = () => {
    return (
      <div className="flex justify-content-end">
        <span onClick={() => setOpen(false)} className="pointer">
          <CloseBtnIcon />
        </span>
      </div>
    );
  };

  const handleUpload = async (event: any) => {
    setOpen(false);
    dispatch(startLoading());
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("file", file);
      const imgFormat = file.type.split("/");
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        dispatch(
          setPalmPhoto({
            palmPhotoUrl: base64String,
            isPalmPhotoFromUpload: true,
          }),
        );
        dispatch(stopLoading());
        ReactGA.event("selectedImageFromGallery");
        ReactPixel.trackCustom("selectedImageFromGallery");
        dispatch(goNextStep());
      };

      if (imgFormat[1] === "heic") {
        const blobURL = URL.createObjectURL(file);

        const blobRes = await fetch(blobURL);
        const blob = await blobRes.blob();
        const conversionResult = await heic2any({
          blob,
          toType: "image/jpg",
          quality: 1,
        });
        reader.readAsDataURL(conversionResult as Blob);
      } else {
        reader.readAsDataURL(file);
      }
    }
  };

  const modalContent = () => {
    return (
      <div className="mt24 flex flex-column i-align-c gap10 fRoboto text-color-silver font-w700 font-s18">
        <div
          className="bg-with-blur3 padding-v16 padding-h16 flex default-border border-radius12 gap10 i-align-c full-width justify-content-start"
          onClick={() => {
            setOpen(false);
            dispatch(
              setPalmPhoto({ palmPhotoUrl: "", isPalmPhotoFromUpload: false }),
            );
            ReactGA.event("selectedTakePhotoUsingCamera");
            ReactPixel.trackCustom("selectedTakePhotoUsingCamera");
            dispatch(goNextStep());
          }}
        >
          <img src={CameraIcon} alt={""} />
          <p>Take photo</p>
        </div>
        <div className="bg-with-blur3 flex default-border border-radius12 gap10 i-align-s full-width">
          <label
            htmlFor={"formId"}
            className="full-width flex gap10 justify-content-start i-align-c padding-v16 padding-h16"
          >
            <img src={ShareIcon} alt={""} />
            <p>Upload file</p>
          </label>
          <input
            id="formId"
            type="file"
            accept=".png, .jpg, .jpeg, .heic"
            name="myImage"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <h2 className="title">
        Take a photo of your
        <br />
        <span className="yellow-text"> left palm</span>
      </h2>
      <div className="fMontserrat font-s10 text-white text-uppercase font-w600  i-align-c flex justify-content-center mt24">
        <HandAnimation />
        <div className="bottom-container-absolute">
          <div className="flex padding-b10">
            <img src={ProtectIcon} alt="" />
            <p className="text-uppercase t-align-l text-white fMontserrat font-s8">
              We value your privacy. Swift deletion of non-identifiable data
              ensures anonymity{" "}
            </p>
          </div>
          <DefaultButton text={"Next"} type="button" onClick={openModal} />
          <p className="mt16 font-s18 fRoboto text-color-silver font-w400 text-transform-none">
            Want to do it later?{" "}
            <button
              type="button"
              className="linkBtn"
              onClick={() => {
                ReactGA.event("palmPhotoSkipped");
                ReactPixel.trackCustom("palmPhotoSkipped");
                setPaymentLocation("registration");
                navigate("/payment");
              }}
            >
              Skip for Now
            </button>
          </p>
        </div>
      </div>
      <CustomHeightBottomSheet
        modalHeight={231}
        content={modalContent}
        header={headerContent}
        isModalOpen={isModalOpen}
        setOpen={setOpen}
      ></CustomHeightBottomSheet>
    </>
  );
};

export default InstructionStep;
