import CoffeeCupIcon from "assets/svg/coffeecups-icons/coffee-cup.svg";

export const coffeeCupsMockedData = [
  {
    id: 0,
    type: "Present",
    title: "Present",
    isActive: true,
    tabData: {
      imgUrl: CoffeeCupIcon,
      coffeeCupName: "Knife",
      description:
        "The knife represents death or some kind of sacrifice in your life. If this appears in your coffee cup reading, it is indicative of the fact that you have to free yourself from something that is holding you back.  If it is seen at the top of your cup, it means that you will face your demise if you don’t get rid of all your bondages soon. If the knife is seen in the middle, it advises you to save your relationship before it is too late. And if it is seen at the bottom, it stands for the fact that you have a good life.",
    },
  },
  {
    id: 1,
    type: "Near Future",
    title: "Near Future",
    isActive: false,
    tabData: {
      imgUrl: CoffeeCupIcon,
      coffeeCupName: "Life",
      description:
        "Life Life Life Life Life The knife represents death or some kind of sacrifice in your life. If this appears in your coffee cup reading, it is indicative of the fact that you have to free yourself from something that is holding you back.  If it is seen at the top of your cup, it means that you will face your demise if you don’t get rid of all your bondages soon. If the knife is seen in the middle, it advises you to save your relationship before it is too late. And if it is seen at the bottom, it stands for the fact that you have a good life.",
    },
  },
  {
    id: 2,
    type: "Distant Future",
    title: "Distant Future",
    isActive: false,
    tabData: {
      imgUrl: CoffeeCupIcon,
      coffeeCupName: "Progress",
      description:
        "Progress Progress Progress Progress ProgressThe knife represents death or some kind of sacrifice in your life. If this appears in your coffee cup reading, it is indicative of the fact that you have to free yourself from something that is holding you back.  If it is seen at the top of your cup, it means that you will face your demise if you don’t get rid of all your bondages soon. If the knife is seen in the middle, it advises you to save your relationship before it is too late. And if it is seen at the bottom, it stands for the fact that you have a good life.",
    },
  },
];
