import { createSlice } from "@reduxjs/toolkit";

type PartnerData = {
  id: string;
  gender: string;
  birthday: string;
  birthtime: string;
  birthplace: string;
  name: string;
  zodiac: string;
  zodiacName: string;
  friendshipReport: string[];
  romanticReport: string[];
  personalConnectionReport: string[];
};

type PartnersState = {
  partners: PartnerData[];
};

const initialState: PartnersState = {
  partners: [],
};

export const partnersSlice = createSlice({
  name: "partnerRegistration",
  initialState,
  reducers: {
    setPartnerData: (state, action) => {
      state.partners[0] = { ...action.payload };
    },
  },
});

export const { setPartnerData } = partnersSlice.actions;

export default partnersSlice.reducer;
