import React, { forwardRef, Ref } from "react";
import styles from "./CardWithProgressBar.module.scss";
import classNames from "classnames";
import CustomProgressBar from "../../progressBar/ProgressBar";
import ElBgContainer from "../../containers/ElBgContainer/ElBgContainer";

interface CardWithProgressBarProps {
  item: {
    customContainerStyles?: string;
    title: string;
    btnTitle: string;
    btnColor: string;
    cardText: string;
    completed: number;
    progressContainerStyles?: string;
  };
  ref?: Ref<HTMLDivElement>;
  inView: boolean;
  customContainerStyles: string;
}

const CardWithProgressBar = forwardRef<
  HTMLDivElement,
  CardWithProgressBarProps
>(({ item, customContainerStyles, inView }, ref) => {
  const {
    title,
    btnTitle,
    btnColor,
    cardText,
    completed,
    progressContainerStyles,
  } = item;

  return (
    <ElBgContainer customClassNames={customContainerStyles} ref={ref}>
      <div className={styles.cardHeader}>
        <h3>{title}</h3>
        <div
          style={{ backgroundColor: btnColor }}
          className={classNames(styles.colorLabel)}
        >
          {btnTitle}
        </div>
      </div>
      <div className={styles.metricsProgressBar}>
        {inView && (
          <CustomProgressBar
            completed={completed}
            customLabel={`${completed}`}
            maxCompleted={10}
            transitionTimingFunction={"ease-in"}
            customBarContainerStyles={progressContainerStyles}
            animateOnRender
            transitionDuration={"1s"}
          />
        )}
        <div className={styles.labelsContainer}>
          <p>0</p>
          <p>10</p>
        </div>
      </div>
      <p className={styles.text}>{cardText}</p>
    </ElBgContainer>
  );
});

CardWithProgressBar.displayName = "CardWithProgressBar";

export default CardWithProgressBar;
