import React from "react";
import Lottie from "lottie-react";
import loader from "assets/load-animation.json";

const LoaderForPage = () => (
  <Lottie
    animationData={loader}
    loop
    style={{
      width: "85%",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
    }}
  />
);

export default LoaderForPage;
