import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "assets/svg/logo";
import LeftArrow from "assets/svg/leftArrow";
import { useAppDispatch, useAppSelector } from "store";
import {
  goToPrevStep,
  refreshPartnerData,
} from "store/slices/partnerRegistration";
import { createBrowserHistory } from "history";

// eslint-disable-next-line react/display-name
const Header = memo(() => {
  const { step } = useAppSelector((state) => state.partnerRegistration);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const history = createBrowserHistory();

  const handleBackPress = () => {
    step === 1 ? history.back() : dispatch(goToPrevStep());
  };

  const goHome = () => {
    navigate("/compatibility");
    dispatch(refreshPartnerData());
  };

  return (
    <div className="flex i-align-s justify-content-space-between position-relative mb24">
      <button className="linkBtn" onClick={handleBackPress}>
        <LeftArrow />
      </button>
      <div
        className="position-absolute absolute-left0 absolute-right0"
        style={{ top: 2 }}
      >
        <Logo />
      </div>
      <button
        className="linkBtn font-s16 fMontserrat font-w500 flex i-align-c"
        onClick={goHome}
      >
        Home
      </button>
    </div>
  );
});

export default Header;
