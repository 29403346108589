import React, { useEffect, useState } from "react";
import "./styles.scss";
import classNames from "classnames";
const Timeline = ({
  position,
  customClassNames,
  maxValue = 10,
  title,
}: {
  position: number;
  customClassNames?: string;
  maxValue?: number;
  title: string;
}) => {
  const [titleWidth, setTitleWidth] = useState(0);

  useEffect(() => {
    const el = document.getElementById("timeline-title");
    const container = document.getElementById("timeline-container");
    if (el && container) {
      const width = el.offsetWidth / (container.offsetWidth / 100);
      setTitleWidth(width);
    }
  }, []);

  const containerClasses = classNames("timeline-container", {
    [`${customClassNames}`]: customClassNames,
  });

  return (
    <div className={containerClasses} id="timeline-container">
      <div className="timeline-line" />
      <p
        id="timeline-title"
        className="timeline-title font-s10 font-w600 fMontserrat yellow-text text-uppercase"
        style={{ left: `${(position / maxValue) * 100 - titleWidth / 2}%` }}
      >
        {title}
      </p>
      <div
        className="timeline-point"
        style={{ left: `${(position / maxValue) * 100}%` }}
      />
      <div className="flex justify-content-space-between text-white font-s8 font-w600 fMontserrat position-absolute full-width bottom12">
        <span>0</span>
        <span>{maxValue}</span>
      </div>
    </div>
  );
};

export default Timeline;
