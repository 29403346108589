import React from "react";

const XAppIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" fill="#122133" />
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" stroke="#27394B" />
    <g clipPath="url(#clip0_807_8912)">
      <path
        d="M17.8319 15.7255L23.0437 9.66724H21.8087L17.2833 14.9276L13.6688 9.66724H9.5L14.9657 17.6218L9.5 23.9749H10.7351L15.5141 18.4198L19.3312 23.9749H23.5L17.8316 15.7255H17.8319ZM16.1403 17.6919L15.5865 16.8998L11.1801 10.597H13.0772L16.6331 15.6836L17.1869 16.4757L21.8093 23.0874H19.9122L16.1403 17.6922V17.6919Z"
        fill="#E5E5E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_807_8912">
        <rect
          width="14"
          height="15.9056"
          fill="white"
          transform="translate(9.5 8.87195)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default XAppIcon;
