import { FilesetResolver, HandLandmarker } from "@mediapipe/tasks-vision";
import { drawConnectors } from "@mediapipe/drawing_utils";
import { HAND_CONNECTIONS } from "@mediapipe/hands";
import { isMobile } from "react-device-detect";
import HandTask from "./hand_landmarker.task";

let runningMode = "IMAGE";

export const detectHand = async (imageRef, isPalmPhotoFromUpload) => {
  const vision = await FilesetResolver.forVisionTasks(
    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm",
  );
  let handLandmarker = await HandLandmarker.createFromOptions(vision, {
    baseOptions: {
      modelAssetPath: HandTask,
      delegate: "CPU",
    },
    runningMode: runningMode,
    numHands: 2,
  });

  if (!handLandmarker) {
    console.log("Wait for handLandmarker to load before clicking!");
    return;
  }

  const image = document.querySelector("#palmImage");

  await handLandmarker.setOptions({ runningMode: "IMAGE" });
  const handLandmarkerResult = handLandmarker.detect(image);
  console.log("handLandmarkerResult", handLandmarkerResult);

  if (!handLandmarkerResult.landmarks.length) {
    return;
  }

  const topPosition = isPalmPhotoFromUpload
    ? "0px"
    : isMobile
      ? "-30%"
      : "-20%";

  const canvas = document.createElement("canvas");
  canvas.setAttribute("class", "canvas");
  canvas.setAttribute("width", imageRef.current.naturalWidth + "px");
  canvas.setAttribute("height", imageRef.current.naturalHeight + "px");

  canvas.style =
    "left: 0px;" +
    `top: ${topPosition};` +
    "width: " +
    imageRef.current.width +
    "px;" +
    "height: " +
    imageRef.current.height +
    "px;";

  imageRef.current.parentNode.appendChild(canvas);
  const cxt = canvas.getContext("2d");

  function shiftY(points, percentage) {
    const shiftedPoints = [];
    for (const point of points) {
      const shiftedY = point.y - point.y * percentage;
      const shiftedPoint = {
        x: point.x,
        y: shiftedY,
        z: point.z,
      };
      shiftedPoints.push(shiftedPoint);
    }
    return shiftedPoints;
  }

  const selectedLandmarkers = [];
  selectedLandmarkers[0] = shiftY(
    [
      handLandmarkerResult.landmarks[0][5],
      handLandmarkerResult.landmarks[0][9],
      handLandmarkerResult.landmarks[0][13],
      handLandmarkerResult.landmarks[0][17],
    ],
    -0.05,
  );

  function getBezierPoints(startPoint, endPoint, numPoints) {
    // Розрахунок контрольних точок
    const controlPoint1 = {
      x: (startPoint.x + endPoint.x) / 2,
      y: (startPoint.y + endPoint.y) / 2,
      z: (startPoint.z + endPoint.z) / 2,
    };
    const controlPoint2 = {
      x: controlPoint1.x + (endPoint.x - startPoint.x) / 4,
      y: controlPoint1.y + (endPoint.y - startPoint.y) / 4,
      z: controlPoint1.z + (endPoint.z - startPoint.z) / 4,
    };
    const points = [];

    for (let i = 0; i < numPoints; i++) {
      const t = i / (numPoints - 1);
      const point = getBezierPoint(
        startPoint,
        controlPoint1,
        controlPoint2,
        endPoint,
        t,
      );
      points.push(point);
    }

    return points;
  }

  function getBezierPoint(
    startPoint,
    controlPoint1,
    controlPoint2,
    endPoint,
    t,
  ) {
    const x =
      (1 - t) * (1 - t) * (1 - t) * startPoint.x +
      3 * (1 - t) * (1 - t) * t * controlPoint1.x +
      3 * (1 - t) * t * t * controlPoint2.x +
      t * t * t * endPoint.x;
    const y =
      (1 - t) * (1 - t) * (1 - t) * startPoint.y +
      3 * (1 - t) * (1 - t) * t * controlPoint1.y +
      3 * (1 - t) * t * t * controlPoint2.y +
      t * t * t * endPoint.y;
    const z =
      (1 - t) * (1 - t) * (1 - t) * startPoint.z +
      3 * (1 - t) * (1 - t) * t * controlPoint1.z +
      3 * (1 - t) * t * t * controlPoint2.z +
      t * t * t * endPoint.z;
    return { x, y, z };
  }

  const numPoints = 20;

  const points = getBezierPoints(
    handLandmarkerResult.landmarks[0][5],
    handLandmarkerResult.landmarks[0][0],
    numPoints,
  );

  function centralPoint(point1, point2) {
    const centralX = (point1.x + point2.x) / 2;
    const centralY = (point1.y + point2.y) / 2;
    const centralZ = (point1.z + point2.z) / 2;

    return { x: centralX, y: centralY, z: centralZ };
  }

  const centralPointRes = centralPoint(
    handLandmarkerResult.landmarks[0][0],
    handLandmarkerResult.landmarks[0][17],
  );

  setTimeout(() => {
    drawConnectors(cxt, [...points], HAND_CONNECTIONS, {
      color: "#5A8C8D",
      lineWidth: 30,
    });
  }, 300);

  setTimeout(() => {
    drawConnectors(
      cxt,
      [handLandmarkerResult.landmarks[0][5], centralPointRes],
      HAND_CONNECTIONS,
      {
        color: "#EACB3F",
        lineWidth: 30,
      },
    );
  }, 500);

  for (const landmarks of selectedLandmarkers) {
    drawConnectors(cxt, landmarks, HAND_CONNECTIONS, {
      color: "#9B7BD0",
      lineWidth: 30,
    });
  }
};
