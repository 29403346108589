import React from "react";

const WrongIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="wrong">
      <path
        id="Vector"
        d="M16.5 1C8.2 1 1.5 7.7 1.5 16C1.5 24.3 8.2 31 16.5 31C24.8 31 31.5 24.3 31.5 16C31.5 7.7 24.8 1 16.5 1ZM23.05 20.4C23.6499 20.9999 23.6499 21.95 23.05 22.5501C22.75 22.8501 22.35 23.0001 22 23.0001C21.6 23.0001 21.2001 22.8501 20.9501 22.5501L16.5001 18.1501L12.1001 22.5501C11.8001 22.8501 11.4 23.0001 11.0501 23.0001C10.6501 23.0001 10.2502 22.8501 10.0002 22.5501C9.40025 21.9502 9.40025 21.0001 10.0002 20.4L14.4002 16L10.0002 11.6C9.40025 11.0001 9.40025 10.05 10.0002 9.44992C10.6001 8.85 11.5501 8.85 12.1502 9.44992L16.5503 13.8499L20.9503 9.44992C21.5502 8.85 22.5002 8.85 23.1003 9.44992C23.7003 10.0498 23.7003 10.9999 23.1003 11.6L18.6503 16L23.05 20.4Z"
        fill="#9B7BD0"
      />
    </g>
  </svg>
);

export default WrongIcon;
