export const monthMap: Record<number, string> = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const dateConfig = {
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
  month: {
    format: (value: Date) => monthMap[value.getMonth() + 1],
    caption: "Mon",
    step: 1,
  },
  year: {
    format: "YYYY",
    caption: "Year",
    step: 1,
  },
};

export const timeValueGroups = {
  hours: "01",
  minutes: "00",
  ampm: "pm",
};

type MonthMap = { [key: string]: number };

export const shortMonthMap: MonthMap = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};
