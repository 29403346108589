import React, { useEffect, useState } from "react";
import StepsTemplate from "../StepsTemplate";
import { useAppDispatch, useAppSelector } from "store";
import { goNextStep } from "store/slices/registration";
import DefaultButton from "components/buttons/DefaultButton";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { zodiacSigns } from "constants/zodiacs";
import "./styles.scss";
import AquariusCircle from "assets/svg/signs-circle/aquarius-circle";
import AriesCircle from "assets/svg/signs-circle/aries-circle";
import CancerCircle from "assets/svg/signs-circle/cancer-circle";
import CapricornCircle from "assets/svg/signs-circle/capricorn-circle";
import LeoCircle from "assets/svg/signs-circle/leo-circle";
import LibraCircle from "assets/svg/signs-circle/libra-circle";
import PiscesCircle from "assets/svg/signs-circle/pisces-circle";
import SagittariusCircle from "assets/svg/signs-circle/sagittarius-circle";
import ScorpioCircle from "assets/svg/signs-circle/scorpio-circle";
import TaurusCircle from "assets/svg/signs-circle/taurus-circle";
import VirgoCircle from "assets/svg/signs-circle/virgo-circle";
import GeminiCircle from "assets/svg/signs-circle/gemini-circle";

const InfoStep = () => {
  const dispatch = useAppDispatch();
  const { zodiacName } = useAppSelector((state) => state.registration);
  const [isActive, setIsActive] = useState(false);
  const zodiacNameTransformed =
    zodiacName.charAt(0).toUpperCase() + zodiacName.slice(1);

  const pageData =
    Object.prototype.hasOwnProperty.call(zodiacSigns, zodiacNameTransformed) &&
    zodiacSigns[zodiacNameTransformed].data;
  const onClickGoNext = () => {
    ReactGA.event("infoStepChecked");
    ReactPixel.trackCustom("infoStepChecked");
    dispatch(goNextStep());
  };

  useEffect(() => {
    let seconds = 2;
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        seconds--;
      } else {
        clearInterval(intervalId);
        setIsActive(true);
      }
    }, 800);
  }, []);

  return (
    <StepsTemplate lightTitle="Cosmic " yellowTitle="Finale!">
      <>
        {pageData && (
          <div
            className="position-relative flex i-align-c"
            style={{ marginTop: 100 }}
          >
            {pageData.icon()}
            <div
              className="position-absolute rotating-astro-circle-aries"
              id="astro-circle"
            >
              {zodiacName === "Aquarius" && (
                <AquariusCircle isActive={isActive} />
              )}
              {zodiacName === "Aries" && <AriesCircle isActive={isActive} />}
              {zodiacName === "Cancer" && <CancerCircle isActive={isActive} />}
              {zodiacName === "Capricorn" && (
                <CapricornCircle isActive={isActive} />
              )}
              {zodiacName === "Gemini" && <GeminiCircle isActive={isActive} />}
              {zodiacName === "Leo" && <LeoCircle isActive={isActive} />}
              {zodiacName === "Libra" && <LibraCircle isActive={isActive} />}
              {zodiacName === "Pisces" && <PiscesCircle isActive={isActive} />}
              {zodiacName === "Sagittarius" && (
                <SagittariusCircle isActive={isActive} />
              )}
              {zodiacName === "Scorpio" && (
                <ScorpioCircle isActive={isActive} />
              )}
              {zodiacName === "Taurus" && <TaurusCircle isActive={isActive} />}
              {zodiacName === "Virgo" && <VirgoCircle isActive={isActive} />}
            </div>
          </div>
        )}
        <div style={{ marginTop: 100 }}>{pageData && pageData.text()}</div>
        <div className="bottom-container-absolute">
          <DefaultButton text={"Next"} type="button" onClick={onClickGoNext} />
        </div>
      </>
    </StepsTemplate>
  );
};

export default InfoStep;
