import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "assets/svg/leftArrow";
import Logo from "assets/svg/logoIcon.svg";
import { useAppDispatch, useAppSelector } from "store";
import {
  clearRegistrationState,
  goToPrevStep,
} from "store/slices/registration";

// eslint-disable-next-line react/display-name
const Header = memo(() => {
  const { step } = useAppSelector((state) => state.registration);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleBackPress = () => {
    if (step === 1) {
      navigate("/login");
      dispatch(clearRegistrationState());
    }
    dispatch(goToPrevStep());
  };

  return (
    <div className="flex justify-content-space-between mb8 position-relative i-align-s">
      <div className="linkBtn" onClick={handleBackPress}>
        <LeftArrow />
      </div>
      <div className="position-absolute absolute-left0 absolute-right0">
        <img src={Logo} alt="" />
      </div>
      <p className="text-color-silver font-s16 fMontserrat font-w500">
        {step}
        /11
      </p>
    </div>
  );
});

export default Header;
