import React from "react";
import Lottie from "lottie-react";
import universe from "./universe.json";

const Universe = () => (
  <Lottie
    animationData={universe}
    loop
    style={{
      width: "31.64%",
      position: "absolute",
      top: -8,
      right: "2.686%",
      height: 85,
    }}
  />
);

export default Universe;
