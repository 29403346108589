import React, { FC, JSX } from "react";
import styles from "./LuckyCard.module.scss";
import StarIcon from "assets/svg/small-star.svg";
import moment from "moment";

type LuckyCardProps = {
  content: () => JSX.Element;
  text: string;
};
const LuckyCard: FC<LuckyCardProps> = ({ content, text }) => {
  const formattedDate = moment().format("D MMMM YYYY");

  return (
    <div className={styles.card}>
      <div className={styles.dateContainer}>
        <img src={StarIcon} alt={"star"} />
        <p>{formattedDate}</p>
        <img src={StarIcon} alt={"star"} />
      </div>
      {content()}
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default LuckyCard;
