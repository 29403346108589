import React, { useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import DefaultButton from "components/buttons/DefaultButton";
import ShieldIcon from "assets/svg/shield";
import CertificateCard from "./components/CertificateCard/CertificateCard";
import Logo from "assets/svg/logo";
import XAppIcon from "assets/svg/x-app-icon";
import FaceBookIcon from "assets/svg/facebook-icon";
import InstagramIcon from "assets/svg/instagram-icon";
import { useLocation, useNavigate } from "react-router-dom";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import {
  addClassToModalDiv,
  addClassToSpecificHiddenModalDiv,
} from "utils/defaults";
import Checkmark from "assets/svg/checkmark.svg";
import { useInView } from "react-intersection-observer";
import EmailModal from "./components/EmailModal/EmailModal";
import { useAppDispatch, useAppSelector } from "store";
import Loader from "components/Loader/Loader";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import SubscriptionList, {
  subscriptionsList,
} from "./components/SubscriptionList";
import Bear from "assets/svg/bear.svg";
import BgCircles from "assets/svg/bg-circles.svg";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import classNames from "classnames";
import styles from "./PaymentPage.module.scss";
import PaypalIcon from "assets/svg/payment-icons/paypal-logo.svg";
import MastercardIcon from "assets/svg/payment-icons/mastercard.svg";
import StripeIcon from "assets/svg/payment-icons/stripe-logo.svg";
import VisaIcon from "assets/svg/payment-icons/visa.svg";
import { legends } from "../PalmReadingPage/PalmReadingPage";
import Ellipse from "../../assets/svg/ellipse";
import StarIcon from "assets/svg/star 1.svg";
import LoveCompatibility from "./components/animations/LoveCompatibility";
import SvgText from "assets/svg/svg-text.svg";
import moment from "moment/moment";
import AirIcon from "assets/svg/air-icon";
import EarthIcon from "assets/svg/earth";
import FireIcon from "assets/svg/fire-icon";
import WaterIcon from "assets/svg/water-icon";
import HeartsIcon from "assets/svg/hearts";
import BrokenHeartIcon from "assets/svg/broke-heart";
import EngagementIcon from "assets/svg/engagement";
import MarriedIcon from "assets/svg/married";
import LookingForLoveIcon from "assets/svg/looking-for-love";
import ConfusedIcon from "assets/svg/confused";
import WorldMapIcon from "assets/svg/world map 1.svg";
import BlurBiorhythms from "assets/svg/blur-biorhythms.svg";
import Biorhythms from "./components/animations/Biorhythms";
import Flicking from "@egjs/react-flicking";
import { feedbacks } from "./feedbacks";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { AutoPlay } from "@egjs/flicking-plugins";
import BackTarotCard from "assets/svg/back-tarot-card.svg";
import Timer from "components/Timer/Timer";
import LogoIcon from "assets/svg/logoIcon.svg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getPaymentLocation } from "helpers/sessionStorageHelpers";
import { goToInfoAboutPalm } from "store/slices/registration";
import { getToken } from "helpers/localStorageHelpers";

import LogRocket from "logrocket";
import { stopLoading } from "../../store/slices/loader";
const { REACT_APP_STRIPE_SK } = process.env;

LogRocket.init("nwxyyv/astroday");

const stripePromise = loadStripe(`${REACT_APP_STRIPE_SK}`);
// const stripePromise = loadStripe(
//   "pk_test_51OqzK3ItCWgNXKMKsBilB3O110yCJf4u8BtQ8C4zPhSES7VgcQCT2BKzNLtu5lJDM8FKGhGQlRcT2VXsKNM9UN2S00VTz7i2SI",
// );

export type Subscription = {
  id: string;
  price: string;
  period: string;
  text: string;
  perDay: string;
  oldPrice: string;
  label?: string;
  priceId: string;
};

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentLocation = getPaymentLocation();
  const dispatch = useAppDispatch();

  const [subscriptionType, setSubscriptionType] = useState(
    "62fca830-750c-4784-8482-7a3799d55d4a",
  );
  const { userData } = useAppSelector((state) => state.user);
  const [readMoreHeight, setReadMoreHeight] = useState<number | string>(58);
  const { zodiacsArray } = useAppSelector((state) => state.compatibility);
  const token = getToken();

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function () {
      if (token) {
        navigate("/login");
      } else {
        dispatch(goToInfoAboutPalm());
        navigate("/registration");
      }
    });
    return window.removeEventListener("popstate", () => {
      console.log("remove");
    });
  }, [location]);

  const plugins = [
    new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false }),
  ];

  const [isModalOpen, toggleModal] = useState(false);
  const [isButtonModalOpen, toggleButtonModal] = useState(false);
  const [isStripeOpen, setIsOpenStripe] = useState(false);

  const [subscriptionData, setSubscriptionData] = useState(
    subscriptionsList[1],
  );

  const [isModalOpenFirstTime, toggleModalFirstTime] = useState(true);

  const [trialBtnRef, btnInView] = useInView({
    threshold: 0,
  });

  const scrollableContainer = document.getElementById("payment-container");

  useEffect(() => {
    const container = document.getElementById("main-container");
    if (isStripeOpen) {
      if (container) container.style.overflow = "hidden";
    } else if (!isStripeOpen) {
      if (container) container.style.overflow = "scroll";
    }
  }, [isStripeOpen]);

  useEffect(() => {
    const container = document.getElementById("main-container");
    if (!paymentLocation) {
      navigate("/login");
    } else if (
      isModalOpenFirstTime &&
      typeof userData.expirationDate === "undefined"
    ) {
      if (container) container.style.overflow = "hidden";
      toggleModal(true);
      toggleModalFirstTime(false);
    }
    addClassToModalDiv();
  }, [isModalOpenFirstTime, isModalOpen]);

  useEffect(() => {
    if (
      paymentLocation === "registration" ||
      paymentLocation === "analysing" ||
      typeof location?.state?.from === "undefined"
    ) {
      addClassToSpecificHiddenModalDiv(0);
      // addClassToSpecificHiddenModalDiv(2);
    } else if (paymentLocation === "login") {
      addClassToSpecificHiddenModalDiv(0);
    } else {
      addClassToSpecificHiddenModalDiv(1);
      // addClassToSpecificHiddenModalDiv(2);
    }
  }, [btnInView]);

  useEffect(() => {
    dispatch(stopLoading());
    if (paymentLocation === "analysing") {
      toggleButtonModal(true);
    }
  }, [paymentLocation]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payment",
      title: "Payment Page",
    });
    ReactPixel.track("Payment Page");
  }, []);

  const buttonModalHeader = () => {
    return (
      <div className="flex i-align-c justify-content-space-between">
        <Timer time={600} />
        <DefaultButton
          type="button"
          text="Get Personal Prediction"
          customStyles="button-shadow font-s16 width74 btn-prediction"
          onClick={() => {
            scrollableContainer &&
              scrollableContainer.scrollIntoView({
                behavior: "smooth",
              });
          }}
        />
      </div>
    );
  };

  console.log("isModalOpen", isModalOpen);

  const userZodiac = zodiacsArray.filter(
    (item) => item.title === userData.zodiacName,
  )[0];

  const pressButton = () => {
    scrollableContainer &&
      scrollableContainer.scrollIntoView({
        behavior: "smooth",
      });
    setSubscriptionType("62fca830-750c-4784-8482-7a3799d55d4a");
    toggleButtonModal(false);
    setSubscriptionData(subscriptionsList[1]);
    setIsOpenStripe(true);
  };

  return (
    <Layout>
      <div className="app-container" id="main-container">
        <div className="padding-content-h">
          <img
            src={BgCircles}
            alt=""
            className="position-absolute absolute-left0 full-width"
            style={{ top: 0, zIndex: -1 }}
          />
          <div
            className="flex justify-content-start i-align-s mb12"
            id="payment-container"
          >
            <img src={LogoIcon} alt="" style={{ width: 135 }} />
          </div>
          <div
            className="flex justify-content-start i-align-s"
            style={{ gap: 45 }}
          >
            <div className="i-align-s flex flex-column t-align-l">
              <p
                className="fRoboto font-s14 text-color-silver font-w400"
                style={{ width: 115 }}
              >
                Discover your joy through tailored predictions just for you
              </p>
            </div>
            <img src={Bear} alt={""} />
          </div>
          <h2 className="mb8 mt32">
            <span className="yellow-text">Unlock </span>
            predictions
          </h2>
          <p className="text">Please choose a plan</p>
          <div className="mt24 mb16">
            <Elements stripe={stripePromise}>
              <SubscriptionList
                subscriptionType={subscriptionType}
                ref={trialBtnRef}
                isModalOpen={isModalOpen}
                setSubscriptionType={setSubscriptionType}
                toggleButtonModal={toggleButtonModal}
                isStripeOpen={isStripeOpen}
                setIsOpenStripe={setIsOpenStripe}
                subscriptionData={subscriptionData}
                setSubscriptionData={setSubscriptionData}
              />
            </Elements>
          </div>
          <div className="fRoboto font-s10 font-w400 text-color-silver t-align-l flex gap6 justify-content-start i-align-s">
            <div
              className=""
              style={{
                width: 12,
                height: 12,
                border: "1px solid #FFD700",
                boxShadow: "0 0 1px 0 #FFD700",
                borderRadius: 2,
                padding: "0 1px",
              }}
            >
              <img src={Checkmark} alt="" width={8} height={8} />
            </div>
            <p>
              By continuing I accept
              <span
                className="text-color-blue pointer"
                onClick={() => {
                  ReactGA.event("clickedPrivacyPolice");
                  ReactPixel.trackCustom("clickedPrivacyPolice");
                  navigate("/privacy-policy");
                }}
              >
                {" "}
                Privacy Policy
              </span>
              ,<span className="text-color-blue"> Terms of Use</span>,
              <span className="text-color-blue"> Billing Terms</span> and
              <span className="text-color-blue"> Money-back Policy</span>.
            </p>
          </div>
          <div className="position-relative">
            <p
              className="text-color-silver fMontserrat font-s10 text-uppercase mt16 mb16 t-align-l overflow-hidden"
              style={{
                height: readMoreHeight,
              }}
            >
              YOUR TRIAL OPTIONS INCLUDE: A $1 3-DAY TRIAL, AFTER WHICH A $18.99
              FEE APPLIES BI-WEEKLY; A $3.55 WEEK-LONG TRIAL, FOLLOWED BY $18.99
              EVERY TWO WEEKS; OR A PREMIUM $9.99 TRIAL LEADING TO A MONTHLY
              $28.99 CHARGE. THESE SUBSCRIPTIONS COMMENCE AUTOMATICALLY
              POST-TRIAL UNLESS CANCELLED WITHIN THE APP OR BY CONTACTING
              CUSTOMER SUPPORT AT LEAST 24 HOURS PRIOR TO THE NEXT BILLING
              CYCLE. CANCELLATION IS FEASIBLE AT ANY TIME THROUGH THE SAME
              CHANNELS. BEFORE SEEKING A REFUND, PLEASE REVIEW OUR
              <span className="text-color-blue">
                {" "}
                Money-Back Guarantee Policy
              </span>
              . FOR FURTHER ASSISTANCE OR INQUIRIES, CONTACT US AT{" "}
              <span className="text-color-blue"> admin@astro.day</span>. ENSURE
              FAMILIARITY WITH OUR TERMS TO FULLY UNDERSTAND YOUR SUBSCRIPTION
              DETAILS AND RIGHTS.
            </p>
            {readMoreHeight === 58 && (
              <div
                className={classNames(
                  styles.textGradient,
                  styles.textGradientInCard,
                )}
              />
            )}
          </div>
          <div className={"flex i-align-c"}>
            {readMoreHeight === 58 && (
              <button
                style={{ zIndex: 1 }}
                type="button"
                className="linkBtn fMontserrat font-s16 font-w500 flex i-align-c gap4 mb16"
                onClick={() => setReadMoreHeight("auto")}
              >
                Read more
                <img src={ArrowDown} alt={""} />
              </button>
            )}
          </div>
          <p className="flex i-align-c text-white fMontserrat font-s13 gap4 mb16">
            <ShieldIcon /> Guaranteed safe checkout
          </p>
          <div className="flex gap8">
            <img src={VisaIcon} alt="" />
            <img src={MastercardIcon} alt="" />
            <img src={PaypalIcon} alt="" />
            <img src={StripeIcon} alt="" />
          </div>
          {userData.palmPhotoUrl && (
            <div className="el-bg border-radius12 default-border padding-v18 padding-h18 full-width mt32">
              <h2 className="mb16">
                Your <span className="yellow-text">palm reading</span> report is
                ready
              </h2>
              <div className="flex gap12">
                <div
                  className="flex i-align-c overflow-hidden border-radius12 default-border position-relative"
                  style={{ width: "59.567%", height: 296, margin: "0 auto" }}
                >
                  <img src={userData.palmPhotoUrl} alt={""} height={"100%"} />
                </div>
                <div className="flex flex-column gap4">
                  {legends.map((item, index) => (
                    <div
                      key={index}
                      className="flex i-align-c gap8 justify-content-start"
                    >
                      <div
                        className={
                          "position-relative flex justify-content-center i-align-c"
                        }
                      >
                        <Ellipse color={item.color} />
                        <p
                          className="position-absolute font-s8 font-w600"
                          style={{ color: item.textColor }}
                        >
                          {item.text}
                        </p>
                      </div>
                      <p className="text-white fMontserrat font-s14 font-w600">
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="fRoboto text-color-silver t-align-l mt32 flex flex-column gap8 font-w700"
                style={{ filter: "blur(3px)" }}
              >
                <p>
                  The <span style={{ color: "#5D989A" }}>Life Line</span>{" "}
                  reflects your overall well-being, vitality, and significant
                  transitions in life&apos;s journey.
                </p>
                <p>
                  The <span style={{ color: "#4161C7" }}>Fate Line</span>{" "}
                  highlights career paths, success, and life&apos;s pivotal
                  changes.
                </p>
                <p>
                  The <span style={{ color: "#FFD700" }}>Head Line</span> shows
                  intellect and decision-making style.
                </p>
                <p>
                  The <span style={{ color: "#9B7BD0" }}>Heart Line</span>{" "}
                  reveals emotional experiences and relationship styles.
                </p>
              </div>
              <div className="mt16">
                <DefaultButton
                  text={"Get full report"}
                  type="button"
                  onClick={pressButton}
                />
              </div>
            </div>
          )}
          <div className="mt32 border-radius12 el-bg padding-h18 padding-v12 flex flex-column gap6 mb32">
            <div>
              <img src={StarIcon} alt="" />
              <img src={StarIcon} alt="" />
              <img src={StarIcon} alt="" />
              <img src={StarIcon} alt="" />
              <img src={StarIcon} alt="" />
            </div>
            <p className="fMontserrat text-white font-s14 font-w600">
              Over 180k users have joined Astro.day
            </p>
          </div>
        </div>
        <div className="el-bg padding-t40 padding-content-h padding-b40 mb32">
          <h2>
            <span className="yellow-text">Love</span> Compatibility
          </h2>
          <p className="fRoboto font-s16 text-white">
            Check out your love and marriage compatibility with all 12 zodiac
            signs
          </p>
          <div className="flex mt16 mb16" style={{ gap: 100 }}>
            <LoveCompatibility />
          </div>
          <img src={SvgText} alt="" />
          <div className="mt16">
            <DefaultButton
              text={"Discover More Pairings"}
              type="button"
              onClick={pressButton}
            />
          </div>
        </div>
        <div className="padding-content-h">
          <CertificateCard />
          <div className="mt32 mb32">
            <DefaultButton
              text={"Read More"}
              type="button"
              onClick={pressButton}
            />
          </div>
        </div>
        <div className="el-bg padding-content-h padding-b40 padding-t40">
          <h2>
            Your <span className="yellow-text">information</span>
          </h2>
          <div className="flex gap8 mt24 mb24">
            {userZodiac?.iconUrl && (
              <div className={classNames(styles.signWrapper, "flex")}>
                <img src={userZodiac.iconUrl} alt="" width={32} />
              </div>
            )}
            <div className="flex  flex-column i-align-s gap6">
              {userZodiac?.title && (
                <h4 className="yellow-text">{userZodiac.title}</h4>
              )}
              <p className="fRoboto font-s16 text-color-silver">
                {moment(userData.birthDayDate).format("DD.MM.YYYY")}
              </p>
            </div>
          </div>
          <div className="flex justify-content-space-between">
            <div
              className="border-radius12 default-border primary-bg"
              style={{ padding: 10, width: "31%", gap: 2 }}
            >
              <p className="yellow-text text-uppercase font-s10 font-w600 fMontserrat">
                Element
              </p>
              <p className="text-color-silver fMontserrat font-s8 text-uppercase mb8">
                {userData.naturalElement}
              </p>
              {userData.naturalElement === "Earth" ? (
                <EarthIcon />
              ) : userData.naturalElement === "Water" ? (
                <WaterIcon />
              ) : userData.naturalElement === "Air" ? (
                <AirIcon />
              ) : (
                <FireIcon />
              )}
            </div>
            <div
              className="border-radius12 default-border primary-bg"
              style={{ padding: 10, width: "31%", gap: 2 }}
            >
              <p className="yellow-text text-uppercase font-s10 font-w600 fMontserrat">
                relationship
              </p>
              <p className="text-color-silver fMontserrat font-s8 text-uppercase mb8">
                {userData.relationshipStatus}
              </p>
              {userData.relationshipStatus === "Married" ? (
                <MarriedIcon />
              ) : userData.relationshipStatus === "In a relationship" ? (
                <HeartsIcon />
              ) : userData.relationshipStatus === "Broke up" ? (
                <BrokenHeartIcon />
              ) : userData.relationshipStatus === "Engaged" ? (
                <EngagementIcon />
              ) : userData.relationshipStatus === "Looking for a soulmate" ? (
                <LookingForLoveIcon />
              ) : (
                <ConfusedIcon />
              )}
            </div>
            <div
              className="border-radius12 default-border i-align-c flex flex-column primary-bg"
              style={{ padding: 10, width: "31%", gap: 2 }}
            >
              <p className="yellow-text text-uppercase font-s10 font-w600 fMontserrat">
                color
              </p>
              <p className="text-color-silver fMontserrat font-s8 text-uppercase mb8">
                {userData.preferColor}
              </p>
              <div
                className={classNames("circle", {
                  ["red-bg"]: userData.preferColor === "Red",
                  ["yellow-bg"]: userData.preferColor === "Yellow",
                  ["blue-bg"]: userData.preferColor === "Blue",
                  ["orange-bg"]: userData.preferColor === "Orange",
                  ["green-bg"]: userData.preferColor === "Green",
                  ["violet-bg"]: userData.preferColor === "Violet",
                })}
              />
            </div>
          </div>
          <div className="default-border border-radius12 primary-bg mt8 flex i-align-c">
            <img src={WorldMapIcon} alt="" />
            <div
              className="position-absolute flex flex-column"
              style={{ gap: 2 }}
            >
              <p className="yellow-text text-uppercase font-s10 font-w600 fMontserrat">
                birthplace
              </p>
              <p className="text-color-silver fMontserrat font-s8 text-uppercase mb8">
                {userData.placeOfBirth}
              </p>
            </div>
          </div>
        </div>
        <div className="padding-content-h padding-t32 t-align-l overflow-hidden">
          <h2 className="mb24">Biorhythms</h2>
          <img src={BlurBiorhythms} alt="" />
          <div className="flex i-align-c mt32">
            <Biorhythms />
          </div>
          <div className="mt32 mb32">
            <DefaultButton
              text={"Read More"}
              type="button"
              onClick={pressButton}
            />
          </div>
          <h2 className="t-align-c mb24">What People Are Saying</h2>
          <Flicking
            renderOnlyVisible={true}
            adaptive={true}
            plugins={plugins}
            circular={true}
            useFindDOMNode={true}
            align={{ panel: "5.33%", camera: "3%" }}
          >
            {feedbacks.map((item) => (
              <div
                className="default-border border-radius12 full-width el-bg padding-h18 padding-v20 flex gap16"
                key={item.id}
              >
                <div className="flex justify-content-start i-align-c gap16">
                  <img src={item.avatarUrl} alt="" />
                  <div className="flex flex-column justify-content-start gap8">
                    <p className="text-white fMontserrat font-w600 font-s20">
                      {item.name}
                    </p>
                    <div>
                      <img src={StarIcon} alt="" />
                      <img src={StarIcon} alt="" />
                      <img src={StarIcon} alt="" />
                      <img src={StarIcon} alt="" />
                      <img src={StarIcon} alt="" />
                    </div>
                  </div>
                </div>
                <p className="text-white fRoboto font-s18 mt16">{item.text}</p>
              </div>
            ))}
          </Flicking>
          <h2 className="t-align-c mt32">
            <span className="yellow-text">Tarot</span> reading
          </h2>
          <p className="text-white fRoboto font-s16 font-w400 t-align-c">
            Tarot readings provide guidance, clarity, and insight, helping you
            navigate life's challenges and opportunities.
          </p>
          <div
            className="flex full-width justify-content-space-between mt24"
            onClick={pressButton}
          >
            <img src={BackTarotCard} alt={""} width="32%" />
            <img src={BackTarotCard} alt={""} width={"32%"} />
            <img src={BackTarotCard} alt={""} width={"32%"} />
          </div>
          <div className="mt32 mb32">
            <DefaultButton
              text={"Get reading"}
              type="button"
              onClick={pressButton}
            />
          </div>
          <div
            className="flex justify-content-start i-align-s text-color-silver flex-column fMontserrat font-s16 font-w400 padding-t40"
            style={{ paddingBottom: 140 }}
          >
            <Logo />
            <div className="mt32 mb40 flex i-align-s">
              <div
                className="t-align-l flex flex-column gap16"
                style={{ marginRight: 48 }}
              >
                <p>About us</p>
                <p>Blog</p>
                <p>Contact us</p>
                <p>FAQ</p>
              </div>
              <div className="t-align-l gap16 flex flex-column">
                <p onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </p>
                <p>Terms of Use</p>
                <p>Billing Terms</p>
              </div>
            </div>
            <div className="flex i-align-c gap16" style={{ margin: "0 auto" }}>
              <XAppIcon /> <FaceBookIcon /> <InstagramIcon />
            </div>
          </div>
          {paymentLocation === "registration" && (
            <EmailModal
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              toggleButtonModal={toggleButtonModal}
            />
          )}
          <CustomHeightBottomSheet
            modalHeight={120}
            renderBackdrop={false}
            header={buttonModalHeader}
            isModalOpen={isButtonModalOpen}
            setOpen={toggleButtonModal}
          />
        </div>
      </div>
      <Loader />
    </Layout>
  );
};

export default PaymentPage;
