import React, { useEffect, useRef } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import HumanCard from "components/cards/HumanCard/HumanCard";
import { useAppSelector } from "store";
import { userData } from "constants/mockedData";
import FriendsIcon from "assets/svg/Frienship.svg";
import Sun1 from "assets/svg/suns/sun.svg";
import Sun2 from "assets/svg/suns/sun-1.svg";
import Sun3 from "assets/svg/suns/sun-2.svg";
import Sun4 from "assets/svg/suns/sun-3.svg";
import Sun5 from "assets/svg/suns/sun-4.svg";
import Sun6 from "assets/svg/suns/sun-5.svg";

import BigArrowUpIcon from "assets/svg/arrow-up.svg";
import { handleScrollToSection } from "utils/defaults";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const FriendshipDetailPage = () => {
  const partnerData = useAppSelector((state) => state.userPartners.partners);
  const { firstSign } = useAppSelector((state) => state.compatibility);

  const { friendshipReport, zodiac, zodiacName } = partnerData[0];

  useEffect(() => {
    ReactPixel.track("Friendship Detail Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/friendship-detail",
      title: "Friendship Detail Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const containerRef = useRef(null);

  return (
    <Layout>
      <div className="app-container" ref={containerRef}>
        <div className="padding-content-h mb24">
          <HeaderWithBack />
          <h1 className="t-align-l">
            <span className="yellow-text">Friendship</span> Compatibility report
          </h1>
          <p className="mt16 fRoboto font-s16 font-w400 text-color-silver t-align-l">
            Welcome to your personalized Friendship Harmony Report, where the
            cosmos reveals the unique dynamics of your bond. Prepare to embark
            on an exploration through the celestial patterns that shape the
            tapestry of your shared journey.
          </p>
          <div className="flex gap12 mt24">
            <HumanCard
              humanData={userData}
              title={firstSign.title}
              iconUrl={firstSign.iconUrl}
              isUser
            />
            <HumanCard
              humanData={partnerData[0]}
              iconUrl={zodiac}
              title={zodiacName}
            />
          </div>
        </div>
        <div className="bg-el-transparent padding-v24 padding-content-h font-s16 font-w400 flex flex-column i-align-c gap8 fRoboto t-align-l text-color-silver">
          <img src={FriendsIcon} alt="" width={140} className="mt16" />
          <p className="mt16">{friendshipReport[0]}</p>
          <img src={Sun1} alt="" className="mt24" />
          <p className="mt16">{friendshipReport[1]}</p>
          <img src={Sun2} alt="" className="mt24" />
          <p className="mt16">{friendshipReport[2]}</p>
          <img src={Sun3} alt="" className="mt24" />
          <p className="mt16">{friendshipReport[3]}</p>
          <img src={Sun4} alt="" className="mt24" />
          <p className="mt16">{friendshipReport[4]}</p>
          <img src={Sun5} alt="" className="mt24" />
          <p className="mt16">{friendshipReport[5]}</p>
          <img src={Sun6} alt="" className="mt24" />
          <p className="mt16 mb32">{friendshipReport[6]}</p>
          <div
            className="flex flex-column linkBtn i-align-c font-w500 font-s16 fMontserrat gap4"
            onClick={() => handleScrollToSection(containerRef)}
          >
            <img src={BigArrowUpIcon} alt="" width={10} />
            Back to Top
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FriendshipDetailPage;
