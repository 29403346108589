import React, { useEffect } from "react";
import Layout from "components/Layout/Layout";
import Header from "./components/Header/Header";
import CustomProgressBar from "components/progressBar/ProgressBar";
import GenderSelect from "./steps/GenderSelect/GenderSelect";
import { useAppDispatch, useAppSelector } from "store";
import BirthDaySelect from "./steps/BirthDaySelect/BirthDaySelect";
import BirthTimeSelect from "./steps/BirthTimeSelect/BirthTimeSelect";
import PlaceOfBirthSelect from "./steps/PlaceOfBirthSelect/PlaceOfBirthSelect";
import NameSelect from "./steps/NameSelect/NameSelect";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { getUserLocationString } from "utils/geolocation";
import { setPartnerBirthplace } from "store/slices/partnerRegistration";

const PartnerDetailsPage = () => {
  const { step } = useAppSelector((state) => state.partnerRegistration);
  const dispatch = useAppDispatch();

  useEffect(() => {
    ReactPixel.track("Partner Details Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/partner-details",
      title: "Partner Details Page",
    });

    const getUserLocation = async () => {
      const res: any = await getUserLocationString();
      if (res.status === "success") {
        if (res.formatted_address) {
          dispatch(
            setPartnerBirthplace({
              formatted_address: res.formatted_address,
              geometryLon: res.geometryLon,
              geometryLat: res.geometryLat,
            }),
          );
        }
      }
    };
    getUserLocation();
  }, []);

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <Header />
          <CustomProgressBar
            completed={step}
            maxCompleted={5}
            customBarWrapperStyles="mb24"
            customBarContainerStyles={"progress-bar-container-electric-blue"}
          />
          {step === 1 && <GenderSelect />}
          {step === 2 && <BirthDaySelect />}
          {step === 3 && <BirthTimeSelect />}
          {step === 4 && <PlaceOfBirthSelect />}
          {step === 5 && <NameSelect />}
        </div>
      </div>
    </Layout>
  );
};

export default PartnerDetailsPage;
