import { createSlice } from "@reduxjs/toolkit";
import AriesIcon from "assets/svg/zodiacs/aries-icon.svg";
import PiscesIcon from "assets/svg/zodiacs/pisces-icon.svg";
import AquariusIcon from "assets/svg/zodiacs/aquarius-icon.svg";
import CapricornIcon from "assets/svg/zodiacs/capricorn-icon.svg";
import SagittariusIcon from "assets/svg/zodiacs/capricorn-icon.svg";
import ScorpioIcon from "assets/svg/zodiacs/scorpio-icon.svg";
import LibraIcon from "assets/svg/zodiacs/libra-icon.svg";
import VirgoIcon from "assets/svg/zodiacs/virgo-icon.svg";
import LeoIcon from "assets/svg/zodiacs/leo-icon.svg";
import CancerIcon from "assets/svg/zodiacs/cancer-icon.svg";
import GeminiIcon from "assets/svg/zodiacs/gemini-icon.svg";
import TaurusIcon from "assets/svg/zodiacs/taurus-icon.svg";

export type Zodiac = {
  id: number;
  title: string;
  icon: string;
  isSelected: boolean;
  isUserZodiac: boolean;
  iconUrl: string;
};

type CompatibilityState = {
  userZodiac: string;
  firstSign: {
    id: number;
    title: string;
    isSelected: boolean;
    isUserZodiac: boolean;
    iconUrl: string;
    isSecondSign: boolean;
  };
  lastSign: {
    id: number;
    title: string;
    isSelected: boolean;
    isUserZodiac: boolean;
    iconUrl: string;
    isSecondSign: boolean;
  };
  zodiacsArray: Zodiac[];
  compatibilityResult: any;
};

const initialState: CompatibilityState = {
  userZodiac: "Aries",
  compatibilityResult: {},
  firstSign: {
    id: 0,
    title: "Aries",
    isSelected: false,
    isUserZodiac: true,
    iconUrl: AriesIcon,
    isSecondSign: false,
  },
  lastSign: {
    id: 1,
    title: "Pisces",
    isSelected: false,
    isUserZodiac: false,
    iconUrl: PiscesIcon,
    isSecondSign: true,
  },
  zodiacsArray: [
    {
      id: 0,
      title: "Aries",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: AriesIcon,
    },
    {
      id: 11,
      title: "Pisces",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: PiscesIcon,
    },
    {
      id: 10,
      title: "Aquarius",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: AquariusIcon,
    },
    {
      id: 9,
      title: "Capricorn",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: CapricornIcon,
    },
    {
      id: 8,
      title: "Sagittarius",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: SagittariusIcon,
    },
    {
      id: 7,
      title: "Scorpio",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: ScorpioIcon,
    },
    {
      id: 6,
      title: "Libra",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: LibraIcon,
    },
    {
      id: 5,
      title: "Virgo",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: VirgoIcon,
    },
    {
      id: 4,
      title: "Leo",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: LeoIcon,
    },
    {
      id: 3,
      title: "Cancer",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: CancerIcon,
    },
    {
      id: 2,
      title: "Gemini",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: GeminiIcon,
    },
    {
      id: 1,
      title: "Taurus",
      icon: "icon",
      isSelected: false,
      isUserZodiac: false,
      iconUrl: TaurusIcon,
    },
  ],
};

export const compatibilitySlice = createSlice({
  name: "compatibility",
  initialState,
  reducers: {
    setCompatibilityResult: (state, action) => {
      state.compatibilityResult = action.payload;
    },
    setUserZodiac: (state, action) => {
      state.userZodiac = action.payload.userZodiac;
      const selectedZodiac = state.zodiacsArray.find(
        (el) => el.title === action.payload.userZodiac,
      );
      state.zodiacsArray = state.zodiacsArray.map((item) => {
        if (item.title === action.payload.userZodiac) {
          return { ...item, isUserZodiac: true };
        }
        return { ...item, isUserZodiac: false };
      });
      if (selectedZodiac)
        state.firstSign = { ...selectedZodiac, isSecondSign: false };
    },
  },
});

export const { setUserZodiac, setCompatibilityResult } =
  compatibilitySlice.actions;

export default compatibilitySlice.reducer;
