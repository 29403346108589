import React, { FC } from "react";
import Infinite from "../animations/Infinite";
import styles from "./ElementsComponent.module.scss";
import FireIcon from "assets/svg/fire-icon.svg";
import WaterIcon from "assets/svg/water-icon.svg";

type ElementsComponentProps = {
  elements?: { id: number; label: string; icon: string }[];
};

const mockedData = [
  { id: 0, label: "fire", icon: FireIcon },
  { id: 1, label: "water", icon: WaterIcon },
];
const ElementsComponent: FC<ElementsComponentProps> = ({
  elements = mockedData,
}) => {
  return (
    <div className={styles.container}>
      <Infinite />
      <div className={styles.elementsContainer}>
        {elements.map((item) => (
          <div className={styles.element} key={item.id} id={"el"}>
            <p>{item.label}</p>
            <img src={item.icon} alt={""} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ElementsComponent;
