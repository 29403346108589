import React from "react";
const NavHeartIcon = () => (
  <svg
    width="29"
    height="26"
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1156 23.8828C9.03027 20.2044 5.3076 17.1422 3.29313 14.2915H8.27058L10.387 15.0902C10.6908 15.2049 11.0293 15.0414 11.1281 14.7316L12.8103 9.45225L14.219 18.3102C14.31 18.8826 15.1074 18.9634 15.311 18.4206L17.4379 12.758L18.8224 14.1274C18.9289 14.2328 19.072 14.2915 19.2218 14.2915H25.7064C23.6867 17.1498 19.9552 20.215 14.8848 23.8828C14.6552 24.0488 14.3458 24.0488 14.1162 23.8828L14.1156 23.8828ZM15.0026 16.0117L13.556 6.91267C13.4628 6.32486 12.6355 6.26262 12.4545 6.8297L10.2281 13.8167L8.57366 13.193C8.50967 13.1687 8.44217 13.1561 8.37396 13.1561H2.57598C1.91786 11.9682 1.57617 10.8083 1.57617 9.64608C1.57617 5.42103 5.00463 1.99257 9.22968 1.99257C11.2406 1.99257 13.0712 2.77163 14.4374 4.04079L14.4996 4.10477L14.5622 4.04079C15.9273 2.77161 17.759 1.99257 19.7702 1.99257C23.9952 1.99257 27.4241 5.42103 27.4241 9.64608C27.4241 10.8083 27.0809 11.9682 26.4232 13.1561H19.4548L17.6133 11.3347C17.3212 11.0457 16.8272 11.1536 16.6827 11.5382L15.0026 16.0117Z"
      fill="#7DF9FF"
    />
  </svg>
);

export default NavHeartIcon;
