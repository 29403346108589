import React, { useEffect } from "react";
import CameraComponent from "components/CameraComponent/CameraComponent";
import PalmLines from "assets/svg/palmistry-hand.svg";
import { useCamera } from "hooks/useCamera";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "store";
import "./style.css";
import { goToPrevStep, setPalmPhoto } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import LeftArrow from "assets/svg/leftArrow";
import CameraBtnIcon from "assets/svg/camera-btn-icon.svg";

const PalmPhotoStep = () => {
  const dispatch = useAppDispatch();
  const { captureImage, startCamera, stopCamera, imageData } = useCamera();

  const { palmPhotoUrl } = useAppSelector((state) => state.registration);

  useEffect(() => {
    if (!palmPhotoUrl) {
      startCamera();
    }
  }, []);

  useEffect(() => {
    if (imageData) {
      dispatch(setPalmPhoto({ palmPhotoUrl: imageData }));
    }
  }, [imageData]);

  // useEffect(() => {
  //   if (!displayAnimation) {
  //     handleDivClick();
  //   }
  // }, [displayAnimation]);

  // useEffect(() => {
  //   const textTimeout = setTimeout(() => {
  //     if (imageData || palmPhotoUrl)
  //       setScanningText("Scanning your fingers...");
  //   }, 3000);
  //   const textTimeout2 = setTimeout(() => {
  //     if (imageData || palmPhotoUrl) setScanningText("Identifying lines...");
  //   }, 5000);
  //   const textTimeout3 = setTimeout(() => {
  //     if (imageData || palmPhotoUrl)
  //       setScanningText("Generating your palm reading results...");
  //   }, 7500);
  //
  //   return () => {
  //     clearTimeout(textTimeout);
  //     clearTimeout(textTimeout2);
  //     clearTimeout(textTimeout3);
  //   };
  // }, [imageData, palmPhotoUrl]);

  return (
    <>
      <div
        style={{ top: 31, left: 19 }}
        className="linkBtn position-absolute"
        onClick={() => dispatch(goToPrevStep())}
      >
        <LeftArrow />
      </div>
      <div
        className=" full-width overflow-hidden position-relative"
        style={{
          background: "transparent",
          height: 534,
          backdropFilter: "blur(3px)",
        }}
      >
        <CameraComponent />
        <img
          src={PalmLines}
          alt={""}
          className="position-absolute absolute-left0 absolute-right0"
          style={{
            top: 0,
            bottom: 0,
            width: "86.626%",
            height: 440.5,
            margin: "auto auto",
            zIndex: 1000,
          }}
        />
      </div>
      <div className={classNames("text", "bottom-container-absolute")}>
        <p className={"padding-b24"}>
          Place left palm inside outline and take a photo
        </p>
        <div
          onClick={async () => {
            ReactGA.event("Take Photo");
            ReactPixel.trackCustom("Take Photo");
            await captureImage();
            stopCamera();
          }}
        >
          <img src={CameraBtnIcon} alt="" />
        </div>
      </div>
    </>
  );
};

export default PalmPhotoStep;
