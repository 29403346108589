import React, { FC } from "react";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";

type CardProps = {
  titleWhite: string;
  titleYellow: string;
  imgUrl: string;
  text: string;
};
const Card: FC<CardProps> = ({ titleWhite, titleYellow, imgUrl, text }) => {
  return (
    <ElBgContainer customClassNames="padding-h18 padding-v20 full-width">
      <h3 className="">
        {titleWhite} <span className="yellow-text">{titleYellow}</span>
      </h3>
      <img src={imgUrl} alt="" />
      <p className="fRoboto t-align-l font-s16 font-w400 text-color-silver">
        {text}
      </p>
    </ElBgContainer>
  );
};

export default Card;
