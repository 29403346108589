import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-cards";
import "./styles.css";

import { EffectCards } from "swiper/modules";
import Card from "./components/Card/Card";
import { cards } from "./mockedData";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const TwelveHoursPage = () => {
  useEffect(() => {
    ReactPixel.track("Twelve Houses Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/twelve-houses",
      title: "Twelve Houses Page",
    });
  }, []);

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className="t-align-l">
          <h1 className="yellow-text mb16">The Twelve Houses</h1>
          <p className="fRoboto font-w400 text-color-silver font-s18">
            The twelve houses of astrology form a celestial wheel that maps out
            various aspects of human experience. Each house represents a
            different area of life, shaping the narrative of our journey from
            birth to self-actualization.
          </p>
        </div>
        <div className="mt32 padding-b40" id="swiper-container">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <Card
                  imgUrl={card.imgUrl}
                  text={card.text}
                  titleWhite={card.titleWhite}
                  titleYellow={card.titleYellow}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Layout>
  );
};

export default TwelveHoursPage;
