import React, { ReactNode } from "react";
import Female from "../assets/svg/female";
import Male from "../assets/svg/male";

export type Gender = {
  type: string;
  icon: (isSelected: boolean) => ReactNode;
  id: number;
};
export const gendersList: Gender[] = [
  {
    type: "Female",
    icon: (isSelected) => <Female isSelected={isSelected} />,
    id: 0,
  },
  {
    type: "Male",
    icon: (isSelected) => <Male isSelected={isSelected} />,
    id: 1,
  },
];
