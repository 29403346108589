import React, { FC } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./styles.scss";
import classNames from "classnames";

type CustomProgressBarProps = {
  completed: number;
  customLabel?: string;
  maxCompleted?: number;
  customBarWrapperStyles?: string;
  customBarContainerStyles?: string;
  transitionDuration?: string;
  transitionTimingFunction?:
    | "ease-in-out"
    | "ease"
    | "linear"
    | "ease-in"
    | "ease-out"
    | undefined;
  animateOnRender?: boolean;
};
const CustomProgressBar: FC<CustomProgressBarProps> = ({
  completed,
  customLabel = " ",
  maxCompleted,
  customBarContainerStyles,
  customBarWrapperStyles,
  transitionTimingFunction = "ease-in-out",
  animateOnRender = false,
  transitionDuration = "0.5s",
}) => {
  return (
    <ProgressBar
      customLabelStyles={
        customLabel
          ? {
              position: "absolute",
              top: -18,
              fontWeight: 600,
              fontSize: 10,
              color: "#FFD700",
            }
          : undefined
      }
      transitionDuration={transitionDuration}
      transitionTimingFunction={transitionTimingFunction}
      completed={completed}
      customLabel={customLabel}
      maxCompleted={maxCompleted}
      animateOnRender={animateOnRender}
      className={classNames(
        { [`${customBarWrapperStyles}`]: !!customBarWrapperStyles },
        "progress-bar-wrapper",
      )}
      barContainerClassName={classNames(
        customBarContainerStyles,
        "progress-bar-container",
      )}
    />
  );
};

export default CustomProgressBar;
