import React, { ButtonHTMLAttributes, FC, memo } from "react";
import classNames from "classnames";

type DefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: (event: any) => void;
  text: string;
  type?: "submit" | "reset" | "button";
  isDisabled?: boolean;
  customStyles?: string;
  icon?: string;
};

// eslint-disable-next-line react/display-name
const DefaultButton: FC<DefaultButtonProps> = memo(
  ({
    isDisabled = false,
    onClick,
    text,
    type = "button",
    customStyles,
    icon,
  }) => {
    const btnClass = classNames(
      "btn pointer",
      {
        disabledBtn: isDisabled,
        hover: !isDisabled,
      },
      customStyles,
    );

    return (
      <button
        onClick={onClick}
        type={type}
        className={btnClass}
        disabled={isDisabled}
      >
        <p className="flex i-align-c gap4">
          {icon && <img src={icon} alt="" width={20} height={20} />} {text}
        </p>
      </button>
    );
  },
);

export default DefaultButton;
