import LeoIcon from "assets/svg/zodiacs/leo-icon.svg";
import CancerIcon from "assets/svg/zodiacs/cancer-icon.svg";
import ScorpioIcon from "assets/svg/zodiacs/scorpio-icon.svg";
import LibraIcon from "assets/svg/zodiacs/libra-icon.svg";

export const mockedData = [
  {
    icons: [
      { id: 0, zodiac: "leo", iconUrl: LeoIcon },
      { id: 1, zodiac: "Cancer", iconUrl: CancerIcon },
    ],
    title: "Best soulmates",
    btnTitle: "Friendship",
    btnColor: "#3F5497",
    cardText:
      "Aries have a strong and confident personality. Retaining their dignity in any relationship is essential to them. Although they won’t try to suppress...",
  },
  {
    icons: [{ id: 0, zodiac: "scorpio", iconUrl: ScorpioIcon }],
    title: "Best couple",
    btnTitle: "Love",
    btnColor: "#9B7BD0",
    cardText:
      "Aries and Aquarius can never get bored together, that’s why their relationship is always exciting. They like trying new things and keep looking for unusual...",
  },
  {
    icons: [
      { id: 0, zodiac: "leo", iconUrl: LeoIcon },
      { id: 1, zodiac: "Cancer", iconUrl: CancerIcon },
    ],
    title: "Marriage compatibility",
    btnTitle: "Marriage",
    btnColor: "#5A8C8D",
    cardText:
      "Aries have a strong and confident personality. Retaining their dignity in any relationship is essential to them. Although they won’t try to suppress...",
  },
  {
    icons: [{ id: 0, zodiac: "Cancer", iconUrl: CancerIcon }],
    title: "Matching element signs",
    btnTitle: "Career",
    btnColor: "#746C20",
    cardText:
      "The Fire element represents energy, power, and life. It makes you move forward and stand your ground. Those who have a trigon of the Fire element...",
  },
  {
    icons: [{ id: 0, zodiac: "libra", iconUrl: LibraIcon }],
    title: "Opposite Sign",
    cardText:
      "Fire needs oxygen to burn. Same here: the Fire element meets the Air forming a dangerous, yet intriguing, connection. Aries often make impulsive decisions...",
  },
];
