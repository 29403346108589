import React, { FC } from "react";
import classNames from "classnames";
import styles from "./SubscriptionCard.module.scss";
import { Subscription } from "../AppPaymentPage";

type SubscribeCardProps = {
  itemData: Subscription;
  isSelected: boolean;
  setType: ((val: string) => void) | undefined;
  label?: string;
};
const SubscriptionCard: FC<SubscribeCardProps> = ({
  itemData,
  isSelected,
  setType,
  label,
}) => {
  const { type, price, period, perDay, oldPrice, oldDayPrice } = itemData;
  return (
    <div
      className={classNames(
        styles.card,
        { [styles.cardSelected]: isSelected },
        "default-border flex justify-content-space-between gap10 i-align-c position-relative",
      )}
      onClick={() => setType && setType(type)}
    >
      <div className={styles.leftContainer}>
        <p className="text-color-silver fRoboto font-s18 font-w700">{period}</p>
        <p className="fMontserrat font-s10 font-w600 text-uppercase text-color-silver mt8">
          {perDay}
          <span className={styles.oldPrice}>{oldPrice}</span>
        </p>
      </div>
      <div
        className={classNames(styles.rightContainer, {
          [styles.rightContainerSelected]: isSelected,
        })}
      >
        <p
          className={classNames(styles.oldDayPrice, {
            [styles.oldDayPriceSelected]: isSelected,
          })}
        >
          {oldDayPrice}
        </p>
        <p
          className={classNames(styles.price, {
            [styles.priceSelected]: isSelected,
          })}
        >
          {price}
        </p>
        <p
          className={classNames(styles.period, {
            [styles.periodSelected]: isSelected,
          })}
        >
          per day
        </p>
      </div>
      {label && (
        <div
          className={classNames(
            "position-absolute fMontserrat font-s8 text-uppercase",
            styles.label,
            {
              [styles.labelSelected]: isSelected,
            },
          )}
        >
          {label}
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
