import React, { useEffect } from "react";
import "./styles/styles.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import AppRoutes from "./routes/routes";

const TRACKING_ID = "G-RTQ0Q69GJ3";
const PIXEL_ID = "232303090785139";

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactPixel.init(PIXEL_ID);
    console.log("Version:: 1.0.0");
  }, []);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoutes />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
