import React, { useEffect, useRef } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import HumanCard from "components/cards/HumanCard/HumanCard";
import { useAppSelector } from "store";
import { userData } from "constants/mockedData";
import LovePersonIcon from "assets/svg/love-person.svg";
import Heart1 from "assets/svg/hearts/Heart.svg";
import Heart2 from "assets/svg/hearts/Heart-1.svg";
import Heart3 from "assets/svg/hearts/Heart-2.svg";
import Heart4 from "assets/svg/hearts/Heart-3.svg";
import Heart5 from "assets/svg/hearts/Heart-4.svg";
import Heart6 from "assets/svg/hearts/Heart-5.svg";
import BigArrowUpIcon from "assets/svg/arrow-up.svg";
import { handleScrollToSection } from "utils/defaults";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const RomanticDetailPage = () => {
  const partnerData = useAppSelector((state) => state.userPartners.partners);
  const { firstSign } = useAppSelector((state) => state.compatibility);

  const { romanticReport, zodiac, zodiacName } = partnerData[0];

  useEffect(() => {
    ReactPixel.track("Romantic Detail Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/romantic-detail",
      title: "Romantic Detail Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const containerRef = useRef(null);

  return (
    <Layout>
      <div className="app-container" ref={containerRef}>
        <div className="padding-content-h mb24">
          <HeaderWithBack />
          <h1 className="t-align-l">
            <span className="yellow-text">Romantic</span> Compatibility report
          </h1>
          <p className="mt16 fRoboto font-s16 font-w400 text-color-silver t-align-l">
            Welcome to your personalized Romantic Compatibility Report, where
            the cosmos unfolds the mysteries of your connection. Brace
            yourselves for a journey through the celestial tapestry that weaves
            your love story.
          </p>
          <div className="flex gap12 mt24">
            <HumanCard
              humanData={userData}
              title={firstSign.title}
              iconUrl={firstSign.iconUrl}
              isUser
            />
            <HumanCard
              humanData={partnerData[0]}
              iconUrl={zodiac}
              title={zodiacName}
            />
          </div>
        </div>
        <div className="bg-el-transparent padding-v24 padding-content-h font-s16 font-w400 flex flex-column i-align-c gap8 fRoboto t-align-l text-color-silver">
          <img src={LovePersonIcon} alt="" width={140} className="mt16" />
          <p className="mt16">{romanticReport[0]}</p>
          <img src={Heart1} alt="" className="mt24" />
          <p className="mt16">{romanticReport[1]}</p>
          <img src={Heart2} alt="" className="mt24" />
          <p className="mt16">{romanticReport[2]}</p>
          <img src={Heart3} alt="" className="mt24" />
          <p className="mt16">{romanticReport[3]}</p>
          <img src={Heart4} alt="" className="mt24" />
          <p className="mt16">{romanticReport[4]}</p>
          <img src={Heart5} alt="" className="mt24" />
          <p className="mt16">{romanticReport[5]}</p>
          <img src={Heart6} alt="" className="mt24" />
          <p className="mt16 mb32">{romanticReport[6]}</p>
          <div
            className="flex flex-column linkBtn i-align-c font-w500 font-s16 fMontserrat gap4"
            onClick={() => handleScrollToSection(containerRef)}
          >
            <img src={BigArrowUpIcon} alt="" width={10} />
            Back to Top
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RomanticDetailPage;
