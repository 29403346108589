import Layout from "components/Layout/Layout";
import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import { userData } from "constants/mockedData";
import { useAppDispatch, useAppSelector } from "store";
import HumanCard from "./components/HumanCard/HumanCard";
import ArrowRight from "assets/svg/arrow right.svg";
import { useNavigate } from "react-router-dom";
import PalmReading from "../HomePage/components/PalmReading/PalmReading";
import classNames from "classnames";
import styles from "../ReadingPage/ReadingPage.module.scss";
import FlippedTarotCard from "components/FlippedTarotCard/FlippedTarotCard";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import DefaultButton from "components/buttons/DefaultButton";
import CoffeeCupIcon from "assets/svg/coffee-cup-reading";
import HeartIcon from "assets/svg/purchases/heart.svg";
import SunIcon from "assets/svg/purchases/sun.svg";
import PersonalConnectionIcon from "assets/svg/purchases/personal-connection.svg";
import PlusIcon from "assets/svg/plus.svg";
import { fetchTarotReading, TarotCardData } from "store/slices/tarotReading";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { firstSign } = useAppSelector((state) => state.compatibility);
  const { palmPhotoUrl } = useAppSelector((state) => state.user.userData);

  const [isCardsFlipped, setIsCardsFlipped] = useState(false);
  const [flippedCardsCount, setFlippedCardsCount] = useState(0);

  const { tarotReadingResult } = useAppSelector((state) => state.tarotReading);

  const [tarotResult, setTarotResult] =
    useState<TarotCardData[]>(tarotReadingResult);

  const { savedReading } = useAppSelector((state) => state.coffeeReading);

  useEffect(() => {
    ReactPixel.track("Profile Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/profile",
      title: "Profile Page",
    });
    window.scrollTo(0, 0);
    const fetchData = async () => await dispatch(fetchTarotReading()).unwrap();
    fetchData();
  }, []);

  const toggleCard = (id: string) => {
    if (flippedCardsCount !== 3) {
      const newCardsState = JSON.parse(JSON.stringify(tarotResult));
      const toggledCard = newCardsState.find(
        (item: { cardTitle: string }) => item.cardTitle === id,
      );
      if (toggledCard) {
        toggledCard.isCardFlipped = true;
        setTarotResult(newCardsState);
        setFlippedCardsCount((prev) => prev + 1);
      }
      if (flippedCardsCount === 2) {
        setIsCardsFlipped(true);
      }
    }
  };

  const toggleAllCards = () => {
    const newCardsState = JSON.parse(JSON.stringify(tarotResult));
    newCardsState.forEach((item: TarotCardData) => (item.isCardFlipped = true));
    setTarotResult(newCardsState);
    setIsCardsFlipped(true);
  };

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h">
          <HeaderWithBack />
          <h1 className="yellow-text mb24">Profile</h1>
          <div className="flex">
            <div className="flex flex-column">
              <HumanCard
                humanData={userData}
                title={firstSign.title}
                iconUrl={firstSign.iconUrl}
                isUser
              />
              <div className="mt8 flex width245 justify-content-end">
                <button
                  className="linkBtn font-s13 fMontserrat font-w500"
                  onClick={() => navigate("/edit-profile")}
                >
                  Edit <img src={ArrowRight} alt="" />
                </button>
              </div>
            </div>
          </div>
          <h2 className="yellow-text t-align-l mt40">My insights</h2>
        </div>
        <div className="padding-content-h mt32">
          <PalmReading palmPhotoUrl={palmPhotoUrl} />
        </div>
        <div className="padding-t40 padding-b40 padding-content-h bg-el-transparent">
          <h2 className="t-align-l">
            <span className="yellow-text">Tarot</span> reading
          </h2>
          <div
            className={classNames(
              styles.cardsContainer,
              "position-relative flex mt24 i-align-c",
            )}
          >
            {tarotResult.map((card, index) => (
              <FlippedTarotCard
                key={index}
                card={card.card}
                cardId={card.cardTitle}
                isCardFlipped={card.isCardFlipped}
                onClick={toggleCard}
              />
            ))}
          </div>
          {isCardsFlipped && (
            <div className="mt16 flex i-align-c">
              <button
                className="linkBtn"
                onClick={() => navigate("/tarot-reading-result")}
              >
                See your recent result <BigArrowRightIcon />
              </button>
            </div>
          )}
          <DefaultButton
            customStyles={"mt32"}
            text={isCardsFlipped ? "Get another reading" : "Get reading"}
            onClick={() => {
              isCardsFlipped ? navigate("/tarot-reading") : toggleAllCards();
            }}
          />
        </div>
        <div className="flex flex-column gap24 mt32 padding-b40 padding-content-h">
          <h2
            className="t-align-l pointer"
            onClick={() => navigate("/coffee-cup-reading")}
          >
            <span className="yellow-text">Coffee Cup </span>Reading
          </h2>
          <div
            className="flex gap16 i-align-c mb8 pointer"
            onClick={() => navigate("/coffee-cup-reading")}
          >
            {Array.from({ length: 3 }, (_, index) => (
              <div
                key={index}
                className={classNames(
                  "default-border border-radius12 flex i-align-c",
                  styles.itemContainer,
                )}
              >
                <CoffeeCupIcon />
              </div>
            ))}
          </div>
          {!!savedReading.near_future_title && (
            <div className={"flex i-align-c"}>
              <button
                className="linkBtn"
                onClick={() => navigate("/coffee-cup-reading-result")}
              >
                See your recent result <BigArrowRightIcon />
              </button>
            </div>
          )}
          <DefaultButton
            text={"Get reading"}
            onClick={() => navigate("/coffee-cup-reading")}
          />
        </div>
        <div className="flex flex-column gap24 padding-b40 padding-content-h">
          <h2 className="yellow-text t-align-l">Personal Compatibility</h2>
          <div className="flex gap8 flex-column">
            <div
              className="primary-bg border-radius12 default-border flex gap12 flex-column"
              style={{ padding: 10 }}
            >
              <h3>Constantina Costa</h3>
              <div className="flex i-align-c">
                <img src={HeartIcon} alt="" />
                <img src={SunIcon} alt="" />
                <img src={PersonalConnectionIcon} alt="" />
              </div>
              <div className="flex i-align-c">
                <button
                  className="linkBtn"
                  onClick={() => navigate("/tarot-reading-result")}
                >
                  Read report <BigArrowRightIcon />
                </button>
              </div>
            </div>
            <div
              className="primary-bg border-radius12 default-border flex gap12 flex-column"
              style={{ padding: 10 }}
            >
              <h3>Anna Demosthenous</h3>
              <div className="flex i-align-c">
                <img src={HeartIcon} alt="" />
                <img src={SunIcon} alt="" />
              </div>
              <div className="flex i-align-c">
                <button
                  className="linkBtn"
                  onClick={() => navigate("/tarot-reading-result")}
                >
                  Read report <BigArrowRightIcon />
                </button>
              </div>
            </div>
            <div
              className="primary-bg border-radius12 default-border flex gap12 flex-column i-align-c"
              style={{ padding: 10 }}
            >
              <img src={PlusIcon} alt="" width={40} height={40} />
              <div className="flex i-align-c">
                <button
                  className="linkBtn"
                  onClick={() =>
                    navigate("/partner-details", {
                      state: {
                        navigateTo: -1,
                      },
                    })
                  }
                >
                  Compare another person <BigArrowRightIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
