import React, { useEffect } from "react";
import LoaderForPage from "components/LoaderForPage";
import Layout from "components/Layout/Layout";
import LogoBig from "assets/svg/logo-big";
import styles from "./LoadPage.module.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import http from "helpers/http";
import { setUserData } from "store/slices/user";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import LogRocket from "logrocket";
LogRocket.init("nwxyyv/astroday");

const LoadPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getUserData = async () => {
    const res = await http.get("/settings/get-user-data");
    dispatch(setUserData({ userData: res.userData }));
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/load",
      title: "Load Page",
    });
    ReactPixel.track("Load Page");
    getUserData();
    const timeoutId = setTimeout(() => {
      navigate("/home");
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Layout>
      <div className="container" />
      <div className={styles.loaderContainer}>
        <div className={styles.contentContainer}>
          <LogoBig />
          <p className="text">Your Gateway to Celestial Wonders</p>
        </div>
        <LoaderForPage />
      </div>
    </Layout>
  );
};
export default LoadPage;
