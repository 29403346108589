import React from "react";

const UserIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="28"
      height="28"
      rx="7"
      stroke="#27394B"
      strokeWidth="2"
    />
    <path
      d="M18.6827 11.08C18.6827 13.112 17.0347 14.76 15.0027 14.76C12.9707 14.76 11.3227 13.112 11.3227 11.08C11.3227 9.04799 12.9707 7.39999 15.0027 7.39999C17.0347 7.39999 18.6827 9.04799 18.6827 11.08ZM20.8907 22.6C21.7227 22.6 22.3467 21.816 22.1707 21C21.4347 17.72 18.4907 15.256 15.0027 15.256C11.5147 15.256 8.57071 17.736 7.83471 21C7.64274 21.816 8.26669 22.6 9.11471 22.6H20.8907Z"
      fill="#7DF9FF"
    />
  </svg>
);

export default UserIcon;
