import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import styles from "./LuckyColorPage.module.scss";
import LuckyCard from "components/cards/LuckyCard/LuckyCard";
import { useAppSelector } from "store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const LuckyColorPage = () => {
  const { luckyColorCode, luckyColorName } = useAppSelector(
    (state) => state.horoscope.daily.prediction,
  );

  useEffect(() => {
    ReactPixel.track("Lucky Color Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/lucky-color",
      title: "Lucky Color Page",
    });
  }, []);

  const content = () => {
    return (
      <div className={styles.colorContainer}>
        <p>{luckyColorName}</p>
        <div className={styles.circle} style={{ background: luckyColorCode }} />
      </div>
    );
  };
  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className={styles.content}>
          <h1>Color</h1>
          <LuckyCard
            content={content}
            text={
              "Hello Aries, today's celestial canvas is painted in the soothing hues of pink, enveloping you in a gentle aura of love and harmony. The color pink, a blend of passion and tenderness, whispers promises of affection and connection. Immerse yourself in the rosy energy surrounding you, allowing it to inspire moments of compassion and warmth in your interactions. Embrace the soft glow of pink as a cosmic reminder to nurture your relationships, express your emotions freely, and savor the sweetness that love and understanding bring. May your day be adorned with the delicate strokes of the universe's affectionate palette!"
            }
          />
        </div>
      </div>
    </Layout>
  );
};
export default LuckyColorPage;
