import React, { FC } from "react";
import styles from "./FlippedTarotCard.module.scss";
import classNames from "classnames";
import BackTarotCard from "assets/svg/back-tarot-card.svg";

type FlippedTarotCardProps = {
  isCardFlipped: boolean;
  card: string;
  customSceneStyles?: any;
  customCardFaceStyles?: any;
  onClick?: ((id: string) => void | undefined) | undefined;
  cardId?: string;
};
const FlippedTarotCard: FC<FlippedTarotCardProps> = ({
  isCardFlipped,
  card,
  customSceneStyles,
  customCardFaceStyles,
  onClick,
  cardId,
}) => {
  return (
    <div
      className={classNames("pointer", styles.scene, styles.sceneCard)}
      style={customSceneStyles}
      onClick={() => onClick && cardId && onClick(cardId)}
    >
      <div
        className={classNames(styles.card, {
          [styles.isFlipped]: isCardFlipped,
        })}
      >
        <div className={styles.cardFace} style={customCardFaceStyles}>
          <img src={BackTarotCard} alt={""} />
        </div>
        <div className={classNames(styles.cardFace, styles.cardFaceBack)}>
          <img src={card} alt={""} />
        </div>
      </div>
    </div>
  );
};

export default FlippedTarotCard;
