import { createSlice } from "@reduxjs/toolkit";

type RegistrationState = {
  gender: string;
  birthday: string;
  birthtime: string;
  birthplace: string;
  birthplaceLon: number;
  birthplaceLat: number;
  step: number;
  zodiac: string;
  zodiacName: string;
  relationshipStatus: string;
  naturalElement: string;
  preferColor: string;
  isPalmPhotoFromUpload: boolean;
  palmPhotoUrl: string;
};

const initialState: RegistrationState = {
  gender: "",
  birthday: "",
  birthtime: "",
  birthplace: "",
  zodiac: "",
  zodiacName: "",
  step: 1,
  relationshipStatus: "",
  naturalElement: "",
  preferColor: "",
  palmPhotoUrl: "",
  isPalmPhotoFromUpload: false,
  birthplaceLon: 0,
  birthplaceLat: 0,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setGenderType: (state, action) => {
      state.gender = action.payload;
      state.step = 2;
    },
    setBirthday: (state, action) => {
      state.birthday = action.payload.birthday;
      state.zodiac = action.payload.zodiac;
      state.zodiacName = action.payload.zodiacName;
      state.step = 3;
    },
    setBirthtime: (state, action) => {
      state.birthtime = action.payload;
      state.step = 4;
    },
    setBirthplace: (state, action) => {
      state.birthplace = action.payload.formatted_address;
      state.birthplaceLon = action.payload.geometryLon;
      state.birthplaceLat = action.payload.geometryLat;
    },
    setRelationshipStatus: (state, action) => {
      state.relationshipStatus = action.payload;
      state.step = 7;
    },
    setNaturalElement: (state, action) => {
      state.naturalElement = action.payload;
      state.step = 9;
    },
    setPreferColor: (state, action) => {
      state.preferColor = action.payload;
      state.step = 10;
    },
    goToPrevStep: (state) => {
      state.step = state.step - 1;
    },
    goToInfoAboutPalm: (state) => {
      state.step = 10;
    },
    refreshRegistrationData: (state) => {
      state.step = 1;
    },
    goNextStep: (state) => {
      state.step = state.step + 1;
    },
    clearRegistrationState: () => {
      return initialState;
    },
    setPalmPhoto: (state, action) => {
      state.palmPhotoUrl = action.payload.palmPhotoUrl;
      state.isPalmPhotoFromUpload =
        action.payload.isPalmPhotoFromUpload || false;
    },
  },
});

export const {
  goToPrevStep,
  setGenderType,
  setBirthday,
  setBirthtime,
  setBirthplace,
  setRelationshipStatus,
  goNextStep,
  setNaturalElement,
  setPreferColor,
  clearRegistrationState,
  setPalmPhoto,
  goToInfoAboutPalm,
} = registrationSlice.actions;

export default registrationSlice.reducer;
