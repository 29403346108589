export type User = {
  token: string;
  isUnlockAccount: boolean;
};
export const isAuth = () => !!localStorage.getItem("token");
export const getToken = () => localStorage.getItem("token");
export const clearToken = () => localStorage.removeItem("token");

export const setCampainId = (campainId: string) =>
  localStorage.setItem("campainId", campainId);

export const getCampainId = () => localStorage.getItem("campainId");

export const setToken = (data: string) => localStorage.setItem("token", data);
