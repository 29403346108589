import React from "react";
const BrokenHeartIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7209 9.92011C23.8409 7.76011 21.2009 9.16003 19.6007 10.5601L20.6807 11.7601L18.3206 14.1201C18.2006 14.2401 18.0407 14.3601 18.0407 14.4001L17.7206 14.68L17.9606 15.0001C18.0006 15.04 18.1206 15.2001 18.2006 15.3201L18.5207 15.6801L19.2007 16.4801L20.1208 17.5202L19.2407 18.4L18.4807 19.12L17.8007 19.76L17.2807 20.24L17.7208 20.7599L18.3208 21.4799L19.7208 23.0799L16.8007 25.96L18.0808 24.4801L19.2408 23.1201L18.0007 21.8401L17.2807 21.1201L16.3607 20.2401L17.8807 18.5201L18.2008 18.1601C18.3208 18.0401 18.4408 17.8802 18.4807 17.8401L18.8407 17.4001L18.4007 17.0001C18.3607 16.9601 18.2006 16.8401 18.1207 16.7201L17.7607 16.4001L15.9208 14.5601L16.9608 13.4001L17.3608 12.9601L18.0008 12.2401C17.4008 11.4001 13.6808 6.68017 9.32094 9.88003C4.96104 13.0801 7.84098 19.84 14.081 24.3601C16.601 26.1601 18.041 28 18.041 28C18.041 28 19.4411 26.1601 21.9611 24.3601C28.2008 19.84 31.0808 13.12 26.7209 9.92023L26.7209 9.92011Z"
      fill="#9B7BD0"
    />
  </svg>
);

export default BrokenHeartIcon;
