export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isLeapYear = (year: number) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const isNumber = (value: string): boolean => {
  const regex = /^\d+$/;
  return regex.test(value);
};

export const isValidExpiryDate = (expiryDate: string): boolean => {
  const regex = /^\d{2}\/\d{2}$/;

  if (!regex.test(expiryDate)) {
    return false;
  }

  const parts = expiryDate.split("/");

  if (parts.length !== 2 || parts[0].length !== 2 || parts[1].length !== 2) {
    return false;
  }

  const month = parseInt(parts[0]);
  if (isNaN(month) || month < 1 || month > 12) {
    return false;
  }

  const year = parseInt(parts[1]);
  return !(isNaN(year) || year < 24 || year > 99);
};
