import React, { useEffect } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import styles from "./TarotReadingResultPage.module.scss";
import Layout from "components/Layout/Layout";
import ResultCard from "./components/ResultCard/ResultCard";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const TarotReadingResultPage = () => {
  const navigate = useNavigate();
  const { tarotReadingResult } = useAppSelector((state) => state.tarotReading);

  useEffect(() => {
    ReactPixel.track("Tarot Reading Result Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/tarot-reading-result",
      title: "Tarot Reading Result Page",
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <h1 className={"t-align-l mb24"}>
          <span className="yellow-text">Tarot</span> reading
        </h1>
        <div className={styles.content}>
          {tarotReadingResult.map((card, index) => (
            <ResultCard
              title={card.cardTitle}
              cardName={card.cardName}
              key={index}
              card={card.card}
              description={card.cardDescription}
            />
          ))}
        </div>
        <DefaultButton
          customStyles={"mt32 mb40"}
          text={"Get another reading"}
          onClick={() => navigate("/tarot-reading")}
        />
      </div>
    </Layout>
  );
};

export default TarotReadingResultPage;
