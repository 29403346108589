import React, { useState } from "react";
import DefaultButton from "components/buttons/DefaultButton";
import { useAppDispatch } from "store";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { dates } from "utils/defaults";
import { setPartnerBirthtime } from "store/slices/partnerRegistration";

const BirthTimeSelect = () => {
  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");
  const [ampm, setAmpm] = useState("");
  const dispatch = useAppDispatch();

  const onSelectBirthday = () => {
    dispatch(setPartnerBirthtime(`${hour}:${minutes} ${ampm}`));
  };

  const updateTransformDay = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        setHour(panel.element.textContent);
      }
    });
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (["am", "pm"].some((el) => el === panel.element.textContent)) {
          setAmpm(panel.element.textContent);
        } else {
          setMinutes(panel.element.textContent);
        }
      }
    });
  };

  const onClickDontRemember = () => {
    dispatch(setPartnerBirthtime(`01:00 pm`));
  };

  return (
    <>
      <h2>
        When’s your partner <span className="yellow-text">Birthday?</span>
      </h2>
      <p className="fRoboto font-s16 text-color-silver font-w400 mt8">
        Adding your partner birthday enriches astrological insights for accuracy
        and depth.
      </p>

      <div className="date-demo-wrapper has-text-white position-relative flex">
        <Flicking
          className="date-flicking"
          horizontal={false}
          onReady={updateTransformDay}
          onMove={updateTransformDay}
        >
          {dates(1, 12)}
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <Flicking
          className="date-flicking"
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
          {dates(1, 60)}
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <Flicking
          className="date-flicking"
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
          <div className="date-panel">am</div>
          <div className="date-panel">pm</div>
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <div className="date-panel-border" />
        <p
          className="fRoboto text-color-blue font-s18 font-w400 position-absolute"
          style={{ bottom: 0 }}
          onClick={onClickDontRemember}
        >
          I don’t remember
        </p>
      </div>
      <div className="bottom-container-absolute">
        <DefaultButton text={"Next"} type="button" onClick={onSelectBirthday} />
      </div>
    </>
  );
};

export default BirthTimeSelect;
