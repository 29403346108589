import React, { FC } from "react";
import styles from "./SmallZodiacCard.module.scss";
import classNames from "classnames";

type ZodiacCardProps = {
  isUserZodiac: boolean;
  title: string;
  iconUrl: string;
};
const SmallZodiacCard: FC<ZodiacCardProps> = ({
  title,
  isUserZodiac,
  iconUrl,
}) => {
  const titleClassNames = classNames(styles.title, {
    [styles.selectedTitle]: isUserZodiac,
  });

  return (
    <div className={styles.container}>
      <p className={titleClassNames}>{title}</p>
      <div className={styles.circle}>
        <img src={iconUrl} alt="" />
      </div>
    </div>
  );
};

export default SmallZodiacCard;
