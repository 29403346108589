import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import HeaderWithBack from "components/headers/HeaderWithBack";
import DefaultInput from "components/fields/DefaultInput";
import { Gender, gendersList } from "constants/genders";
import GenderCard from "../Registration/steps/GenderSelect/GenderCard";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAppSelector } from "store";
import CustomDatePicker from "components/datePicker/CustomDatePicker";
import TimePicker from "components/dataPicker/TimePicker";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const EditProfilePage = () => {
  const navigate = useNavigate();
  const partnerBirthplace = useAppSelector(
    (state) => state.partnerRegistration.birthplace,
  );

  const [firstName, setFirstName] = useState("Andreas");
  const [lastName, setLastName] = useState("Christoforou");
  const [genderType, setGenderType] = useState("Female");

  const [place, setPlace] = useState(partnerBirthplace);
  const [isShowPredictions, togglePredictions] = useState(false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
    });

  useEffect(() => {
    ReactPixel.track("Edit Profile Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/edit-profile",
      title: "Edit Profile Page",
    });
  }, []);

  useEffect(() => {
    if (placePredictions.length) togglePredictions(true);
  }, [placePredictions]);

  const onChangePlace = (event: ChangeEvent<HTMLInputElement>) => {
    setPlace(event.target.value);
    getPlacePredictions({ input: event.target.value });
  };

  const onSelectPlace = (val: { place_id: string }) => {
    placesService?.getDetails(
      { placeId: val.place_id },
      (placeDetails: { formatted_address: string }) => {
        setPlace(placeDetails.formatted_address);
      },
    );
    togglePredictions(false);
  };

  const handleFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <HeaderWithBack />
        <h1 className="yellow-text">Edit Profile</h1>
        <div className="flex flex-column gap16">
          <div className="flex flex-column gap6">
            <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
              first name
            </p>
            <DefaultInput
              name="firstName"
              handleChange={handleFirstName}
              inputVal={firstName}
            />
          </div>
          <div className="flex flex-column gap6">
            <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
              last name
            </p>
            <DefaultInput
              name="firstName"
              handleChange={handleLastName}
              inputVal={lastName}
            />
          </div>
        </div>
        <div className="mt16 flex flex-column gap6">
          <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
            Gender
          </p>
          <div className="flex i-align-s gap16">
            {gendersList.map((gender: Gender) => (
              <GenderCard
                setGenderType={setGenderType}
                type={gender.type}
                icon={gender.icon}
                isSelected={genderType === gender.type}
                key={gender.id}
              />
            ))}
          </div>
        </div>
        <div className="mt16 flex flex-column gap6 position-relative">
          <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
            birth place
          </p>
          <div className="i-align-s gap16">
            <DefaultInput
              handleChange={onChangePlace}
              name="city"
              placeholder="New York"
              inputVal={place}
            />
            {isShowPredictions && (
              <div
                className="default-border border-radius12 position-absolute overflow-hidden"
                style={{ zIndex: 9999 }}
              >
                {placePredictions.map((item, index) => (
                  <div
                    onClick={() => onSelectPlace(item)}
                    key={index}
                    className="padding-v8 t-align-l padding-h12 fRoboto text-white font-w400 font-s10 bottom-border place-prediction primary-bg"
                  >
                    {item.description}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="mt16">
          <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
            birth date
          </p>
          <CustomDatePicker />
        </div>
        <div className="mt16 mb32">
          <p className="text-uppercase yellow-text font-s10 t-align-l fMontserrat">
            birth time
          </p>
          <TimePicker />
        </div>
        <DefaultButton
          text="Save Changes"
          onClick={() => navigate("/profile")}
        />
      </div>
    </Layout>
  );
};

export default EditProfilePage;
