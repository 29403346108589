import React, { FC, JSX } from "react";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import classNames from "classnames";

type PurchaseCardProps = {
  iconUrl: string;
  text: () => JSX.Element;
  isSelected?: boolean;
  onClickPurchase: (type: string) => void;
  type: string;
  isPurchased: boolean;
};
const PurchaseCard: FC<PurchaseCardProps> = ({
  iconUrl,
  text,
  isSelected = false,
  onClickPurchase,
  type,
  isPurchased,
}) => {
  const containerClasses = classNames(
    "full-width gap8 i-align-unset position-relative overflow-hidden",
    {
      "yellow-border": isSelected,
    },
  );
  const onToggle = () => onClickPurchase(type);

  return (
    <ElBgContainer customClassNames={containerClasses}>
      <div
        className="flex i-align-c gap10 justify-content-start padding-h16 padding-v16 pointer"
        onClick={onToggle}
      >
        <img src={iconUrl} alt="" />
        {text()}
      </div>
      {isPurchased && (
        <div className="bg-blur position-absolute full-width full-height flex flex-column i-align-c">
          <h3>Purchased</h3>
        </div>
      )}
    </ElBgContainer>
  );
};

export default PurchaseCard;
