import React from "react";
import { zodiacSigns } from "../constants/zodiacs";
export const addClassToModalDiv = () => {
  const targetDiv = document.querySelector(
    'div[style*="position: fixed; inset: 0px; overflow: hidden; pointer-events: none; z-index: 9999999; visibility: visible;"]',
  );
  if (targetDiv !== null) {
    targetDiv.className = "flex";
  } else {
    console.error("No matching div found with those inline styles.");
  }
};

export const addClassToHiddenModalDiv = () => {
  const targetDiv = document.querySelector(
    'div[style*="position: fixed; inset: 0px; overflow: hidden; pointer-events: none; z-index: -1; visibility: hidden;"]',
  );
  console.log("targetDiv", targetDiv);
  if (targetDiv !== null) {
    targetDiv.className = "flex";
  } else {
    console.error("No matching div found with those inline styles.");
  }
};

export const addClassToSpecificHiddenModalDiv = (modalNumber: number) => {
  const targetDiv = document.querySelectorAll(
    'div[style*="position: fixed; inset: 0px; overflow: hidden; pointer-events: none;"]',
  );
  console.log("targetDiv", targetDiv);
  if (targetDiv !== null) {
    targetDiv[modalNumber].className = "flex";
  } else {
    console.error("No matching div found with those inline styles.");
  }
};

// export const addClassToSpecificVisibleModalDiv = (modalNumber: number) => {
//   const targetDiv = document.querySelectorAll(
//     'div[style*="position: fixed; inset: 0px; overflow: hidden; pointer-events: none; z-index: -1; visibility: hidden;"]',
//   );
//   console.log("targetDiv", targetDiv);
//   if (targetDiv !== null) {
//     targetDiv[modalNumber].className = "flex";
//   } else {
//     console.error("No matching div found with those inline styles.");
//   }
// };

export const toMilliseconds = (secondsStr: string) => {
  const seconds = parseFloat(secondsStr.replace("s", ""));
  return seconds * 1000;
};

export const getZodiacSign = (month: number, day: number): any => {
  const date = new Date(`2000/${month + 1}/${day}`);

  if (date.toString() === "Invalid Date") {
    return -1;
  }

  const signsData = Object.values(zodiacSigns);

  let dateMin;
  let dateMax;
  const i = signsData.findIndex((sign) => {
    dateMin = new Date(sign.dateMin);
    dateMax = new Date(sign.dateMax);

    return (
      (date.getDate() >= dateMin.getDate() &&
        date.getMonth() == dateMin.getMonth()) ||
      (date.getDate() <= dateMax.getDate() &&
        date.getMonth() === dateMax.getMonth())
    );
  });
  return signsData[i];
};

export const dates = (start: number, count: number): React.ReactElement[] => {
  return Array.from({ length: count }, (_, idx) => (
    <div key={idx} className="date-panel">
      {start + idx}
    </div>
  ));
};

export const handleScrollToSection = (ref: any) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
};
