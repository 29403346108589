import React, { ReactNode } from "react";
import StepsTemplate from "../StepsTemplate";
import HorizontalCard from "../../components/cards/HorizontalCard";
import { useAppDispatch, useAppSelector } from "store";
import { setPreferColor } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

type ColorItem = {
  type: string;
  icon: () => ReactNode;
  id: number;
};

const colorsList: ColorItem[] = [
  { type: "Red", icon: () => <div className="circle red-bg" />, id: 0 },
  { type: "Yellow", icon: () => <div className="circle yellow-bg" />, id: 1 },
  { type: "Blue", icon: () => <div className="circle blue-bg" />, id: 2 },
  { type: "Orange", icon: () => <div className="circle orange-bg" />, id: 3 },
  { type: "Green", icon: () => <div className="circle green-bg" />, id: 4 },
  { type: "Violet", icon: () => <div className="circle violet-bg" />, id: 5 },
];
const ColorSelect = () => {
  const preferColor = useAppSelector((state) => state.registration.preferColor);
  const dispatch = useAppDispatch();
  const onSetPreferColor = (type: string) => {
    ReactGA.event("preferColorSelected");
    ReactPixel.trackCustom("preferColorSelected");
    dispatch(setPreferColor(type));
  };

  return (
    <StepsTemplate
      lightTitle="Which of the "
      yellowTitle="colors "
      secondLightTitle="following  do you prefer?"
    >
      <div className="mt24 flex flex-column gap12 i-align-c">
        {colorsList.map((item) => (
          <HorizontalCard
            type={item.type}
            icon={item.icon}
            key={item.id}
            isSelected={preferColor === item.type}
            setType={onSetPreferColor}
          />
        ))}
      </div>
    </StepsTemplate>
  );
};

export default ColorSelect;
