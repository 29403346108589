import React, { ChangeEvent, FC, useEffect, useState } from "react";
import CustomHeightBottomSheet from "../../CustomHeightBottomSheet/CustomHeightBottomSheet";
import CloseBtnIcon from "assets/svg/close-btn";
import styles from "./PaymentDetailsModal.module.scss";
import Visa from "assets/svg/payment-icons/visa.svg";
import Mastercard from "assets/svg/payment-icons/mastercard.svg";
import Pci from "assets/svg/payment-icons/pci.svg";
import Secure from "assets/svg/payment-icons/secure.svg";
import Stripe from "../../forms/StripeForm/Stripe";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

type PaymentDetailsModalProps = {
  isOpen: boolean;
  paymentType?: "subscription" | "report";
  toggleModal: (value: boolean) => void;
  planData: {
    id: string;
    price?: string;
    period?: string;
    oldPrice?: string;
  };
};

const PaymentDetailsModal: FC<PaymentDetailsModalProps> = ({
  isOpen,
  toggleModal,
  planData,
  paymentType = "subscription",
}) => {
  const { price, period = "", oldPrice } = planData;

  const [cardName, setCardName] = useState("");

  useEffect(() => {
    ReactGA.event("paymentModalOpened");
    ReactPixel.trackCustom("paymentModalOpened");
  }, []);
  const handleCardName = (event: ChangeEvent<HTMLInputElement>) => {
    setCardName(event.target.value);
  };
  const modalContent = () => {
    return (
      <div className="mt24">
        <div
          className="flex gap4 i-align-c text-white fMontserrat font-s10 font-w600 text-uppercase padding-v6 justify-content-space-between"
          style={{ borderBottom: "1px solid #FFD700" }}
        >
          <p>{period}</p>
          <p className="flex i-align-c gap8">
            {oldPrice && (
              <span
                style={{
                  color: "#4C556F",
                  textDecoration: "line-through",
                  textDecorationColor: "#7361A1",
                  textDecorationThickness: 1,
                }}
              >
                {oldPrice}
              </span>
            )}
            <span className="font-s14">${price}</span>
          </p>
        </div>
        <div className="flex gap4 i-align-c mt24">
          <div className={styles.line} />
          <p
            className="text-uppercase font-s8 font-w600 text-color-dark-silver"
            style={{ whiteSpace: "nowrap" }}
          >
            card details
          </p>
          <div className={styles.line} />
        </div>
        <div className="flex gap8 justify-content-start mt16">
          <img src={Visa} alt="" />
          <img src={Mastercard} alt="" />
          <img src={Pci} alt="" />
          <img src={Secure} alt="" />
        </div>
        <div className={"position-relative"}>
          <p className="text-uppercase font-s10 text-color-silver fMontserrat mt16 mb6">
            Card number
          </p>
          <Stripe
            setCardName={handleCardName}
            cardName={cardName}
            planData={planData}
            toggleModal={toggleModal}
            paymentType={paymentType}
          />
        </div>
      </div>
    );
  };

  const headerContent = () => {
    return (
      <div className="flex i-align-s justify-content-space-between">
        <h2 className="yellow-text">Payment details</h2>
        <div
          className="flex i-align-c pointer"
          style={{ paddingTop: 4 }}
          onClick={() => {
            toggleModal(false);
          }}
        >
          <CloseBtnIcon />
        </div>
      </div>
    );
  };

  return (
    <CustomHeightBottomSheet
      modalHeight={595}
      header={headerContent}
      content={modalContent}
      isModalOpen={isOpen}
      setOpen={toggleModal}
    />
  );
};

export default PaymentDetailsModal;
