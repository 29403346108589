import { createSlice } from "@reduxjs/toolkit";

type PurchasesState = {
  purchases: {
    romantic: boolean;
    friendship: boolean;
    personalConnection: boolean;
  };
};

const initialState: PurchasesState = {
  purchases: {
    romantic: false,
    friendship: false,
    personalConnection: false,
  },
};

export const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      action.payload.forEach((type: keyof typeof state.purchases) => {
        state.purchases[type] = true;
      });
    },
  },
});

export const { setPurchases } = purchasesSlice.actions;

export default purchasesSlice.reducer;
