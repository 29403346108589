import React, { useEffect, useRef, useState } from "react";
import { goToPrevStep, setPalmPhoto } from "store/slices/registration";
import LeftArrow from "assets/svg/leftArrow";
import CircleProgressBar from "components/CircleProgressBar/CircleProgressBar";
import { useAppDispatch, useAppSelector } from "store";
import { detectHand } from "./palmReading";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { stopLoading } from "store/slices/loader";
import { setPaymentLocation } from "helpers/sessionStorageHelpers";
import html2canvas from "html2canvas";
// import NotificationModal from "components/modals/NotificationModal/NotificationModal";
// import { useNavigate } from "react-router-dom";
import "./style.css";
import { useNavigate } from "react-router-dom";

const PalmLineDetection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { palmPhotoUrl, isPalmPhotoFromUpload } = useAppSelector(
    (state) => state.registration,
  );

  const [displayAnimation, setDisplayAnimation] = useState(true);
  // const [error, setError] = useState("");
  const [scanningText] = useState("Analysing your palm shape...");

  // const [isNotificationModalOpen, toggleNotificationModal] = useState(true);

  const imageRef = useRef(null);
  const handleDivClick = () => {
    const divElement = document.getElementById("divScreenshot");
    if (divElement) {
      html2canvas(divElement).then((canvas) => {
        const imageDataRes = canvas.toDataURL("image/png");
        console.log("imageDataRes", imageDataRes);
        dispatch(setPalmPhoto({ palmPhotoUrl: imageDataRes }));
      });
    }
  };

  // useEffect(() => {
  //   if (error) {
  //     toggleNotificationModal(true);
  //   }
  // }, [error]);

  // const retryTakePhoto = () => {
  //   dispatch(goToPrevStep());
  // };

  useEffect(() => {
    const detect = async () => {
      await detectHand(imageRef, isPalmPhotoFromUpload);
    };
    detect().catch();
  }, [palmPhotoUrl]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDisplayAnimation(false);
      if (palmPhotoUrl) {
        ReactGA.event("goToPaymentPage");
        ReactPixel.trackCustom("goToPaymentPage");
        setTimeout(() => {
          handleDivClick();
          dispatch(stopLoading());
          setPaymentLocation("registration");
          navigate("/payment");
        }, 300);
      }
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [palmPhotoUrl]);
  return (
    <>
      <div
        style={{ top: 31, left: 19 }}
        className="linkBtn position-absolute"
        onClick={() => dispatch(goToPrevStep())}
      >
        <LeftArrow />
      </div>
      <div
        className="flex i-align-c overflow-hidden  position-relative detectOnClick full-width"
        style={{ height: "534px", margin: "0 auto" }}
        id={"divScreenshot"}
      >
        <img
          ref={imageRef}
          id="palmImage"
          src={palmPhotoUrl}
          alt={""}
          className="full-width"
        />
        {displayAnimation && (
          <div className={"animation-div"} id={"animatedDiv"} />
        )}
      </div>
      <div className={"flex i-align-c flex-column bottom-container-absolute"}>
        <p className={"font-w400 fRoboto text-white font-s18 mb12"}>
          {scanningText}
        </p>
        <CircleProgressBar />
      </div>
      {/*<NotificationModal*/}
      {/*  isOpen={isNotificationModalOpen}*/}
      {/*  btnAction={retryTakePhoto}*/}
      {/*/>*/}
    </>
  );
};

export default PalmLineDetection;
