import React from "react";
const CoffeeCupIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_297_14966)">
      <mask
        id="mask0_297_14966"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="42"
        height="50"
      >
        <path d="M4.1875 0H45.7875V49.8667H4.1875V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_297_14966)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0846 29.9656V30.9781C38.0846 32.7094 37.7711 34.3708 37.1992 35.9094C37.8055 36.4115 38.5836 36.7135 39.4325 36.7135C41.369 36.7135 42.9388 35.1427 42.9388 33.2052C42.9388 31.2677 41.369 29.6969 39.4325 29.6969C38.9544 29.6969 38.4992 29.7927 38.0846 29.9656ZM11.1742 26.1479C11.1742 26.1479 10.4326 32.3927 12.3201 36.1802C14.2086 39.9667 16.8378 41.8271 18.8607 42.6906C20.8836 43.5542 20.8836 43.5542 20.8836 43.5542C14.4076 38.849 15.1503 33.2323 15.5565 26.1479C15.5565 26.1479 11.1076 26.1479 11.1742 26.1479ZM45.7763 47.4344C45.1013 48.9417 43.5857 49.9979 41.8357 49.9979H8.12422C6.37422 49.9979 4.85859 48.9417 4.18359 47.4344H45.7763ZM23.2534 -0.0010376C26.4701 1.97709 26.6294 4.68646 26.0669 6.35105C25.3805 8.37709 23.3263 9.90625 23.0096 11.5688C22.7534 12.9188 24.3034 14.799 25.0065 15.9208H25.0055C22.4315 14.6583 20.3305 12.4813 22.3513 9.3698C23.9034 6.9823 26.944 5.10834 23.2534 -0.0010376ZM30.6023 3.9125C33.819 5.88959 33.9794 8.59896 33.4148 10.2646C32.6878 12.4156 30.4148 14.0073 30.3211 15.7938C30.2617 16.9354 31.7482 18.8646 32.3555 19.8333L32.3544 19.8344C29.7794 18.5708 27.6784 16.3938 29.7003 13.2833C31.2523 10.8948 34.293 9.02084 30.6023 3.9125ZM15.9034 3.9125C19.1211 5.88959 19.2805 8.59896 18.7169 10.2646C18.1263 12.0094 16.519 13.3865 15.8836 14.799C15.0648 16.6167 16.6909 18.2917 17.6576 19.8333L17.6565 19.8344C15.0815 18.5708 12.9805 16.3938 15.0023 13.2833C16.5544 10.8948 19.594 9.02084 15.9034 3.9125ZM9.63151 24.3H38.0846V27.5615C38.5169 27.4573 38.969 27.4031 39.4325 27.4031C42.6346 27.4031 45.2315 30.001 45.2315 33.2052C45.2315 36.4094 42.6346 39.0073 39.4325 39.0073C38.2378 39.0073 37.1284 38.6458 36.2055 38.026C33.7461 42.3104 29.1242 45.2125 23.8586 45.2125C16.0336 45.2125 9.63151 38.8073 9.63151 30.9781V24.3Z"
          fill="#746C20"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_297_14966">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CoffeeCupIcon;
