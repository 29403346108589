import React from "react";

const OpenedCookieWithStars = () => (
  <svg
    width="66"
    height="89"
    viewBox="0 0 66 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Opened cookie">
      <g id="Group 140">
        <g id="Group">
          <path
            id="Vector"
            d="M26.1081 31.8454C20.7699 33.917 16.5638 38.427 15.0371 44.2153C17.3252 39.6978 21.2908 36.1697 26.1081 34.4565V31.8454Z"
            fill="#FFD700"
          />
          <path
            id="Vector_2"
            d="M39.6035 31.9744V34.5654C44.2691 36.3128 48.1032 39.7746 50.345 44.1775C48.8407 38.5228 44.7767 34.0953 39.6035 31.9744Z"
            fill="#FFD700"
          />
          <g id="Group 56">
            <path
              id="Vector_3"
              d="M30.2424 21.7873C28.8003 21.7873 27.6309 22.9564 27.6309 24.3988V34.0013C29.2512 33.5711 30.9438 33.3183 32.6973 33.318H32.7007C34.5667 33.3183 36.3667 33.5979 38.0799 34.0831L38.0902 24.3855C38.0917 23.179 38.8839 22.2616 40.6879 21.7879L30.2424 21.7873Z"
              fill="#E5E5E5"
            />
            <line
              id="Line 21"
              x1="29.4844"
              y1="23.7908"
              x2="36.5122"
              y2="23.7908"
              stroke="black"
            />
            <line
              id="Line 22"
              x1="29.4844"
              y1="25.0686"
              x2="36.5122"
              y2="25.0686"
              stroke="black"
            />
            <line
              id="Line 23"
              x1="29.4844"
              y1="26.3464"
              x2="36.5122"
              y2="26.3464"
              stroke="black"
            />
            <line
              id="Line 24"
              x1="29.4844"
              y1="27.6242"
              x2="36.5122"
              y2="27.6242"
              stroke="black"
            />
            <line
              id="Line 25"
              x1="29.4844"
              y1="28.9019"
              x2="36.5122"
              y2="28.9019"
              stroke="black"
            />
          </g>
        </g>
        <path
          id="Vector_4"
          d="M22.3648 71.8104C26.8127 68.2169 30.3244 61.8028 32.0331 54.1072C32.1022 53.8055 32.366 53.5921 32.6801 53.5921C32.9942 53.5921 33.258 53.8055 33.3271 54.1072C35.0359 61.7903 38.5415 68.2107 42.9954 71.8104C48.5426 68.0787 51.847 61.7337 51.847 54.7542C51.847 43.5908 43.253 34.5131 32.6864 34.5131C22.1197 34.5131 13.5137 43.5908 13.5137 54.7542C13.5137 61.74 16.8118 68.0787 22.3653 71.8104H22.3648Z"
          fill="#FFD700"
        />
        <path
          id="Logo Star"
          d="M16.4845 16.6987L17.1578 15.8422L16.3013 16.5155L16 14L15.6987 16.5155L14.8422 15.8422L15.5155 16.6987L13 17L15.5155 17.3013L14.8422 18.1578L15.6987 17.4845L16 20L16.3013 17.4845L17.1578 18.1578L16.4845 17.3013L19 17L16.4845 16.6987Z"
          fill="#E5E5E5"
        />
        <path
          id="Logo Star_2"
          d="M8.06526 37.1485L8.85079 36.1492L7.85148 36.9347L7.5 34L7.14852 36.9347L6.14921 36.1492L6.93474 37.1485L4 37.5L6.93474 37.8515L6.14921 38.8508L7.14852 38.0653L7.5 41L7.85148 38.0653L8.85079 38.8508L8.06526 37.8515L11 37.5L8.06526 37.1485Z"
          fill="#E5E5E5"
        />
        <path
          id="Logo Star_3"
          d="M40.646 6.59831L41.5438 5.45624L40.4017 6.35398L40 3L39.5983 6.35398L38.4562 5.45624L39.354 6.59831L36 7L39.354 7.40169L38.4562 8.54376L39.5983 7.64602L40 11L40.4017 7.64602L41.5438 8.54376L40.646 7.40169L44 7L40.646 6.59831Z"
          fill="#E5E5E5"
        />
        <path
          id="Logo Star_4"
          d="M60.0653 24.1485L60.8508 23.1492L59.8515 23.9347L59.5 21L59.1485 23.9347L58.1492 23.1492L58.9347 24.1485L56 24.5L58.9347 24.8515L58.1492 25.8508L59.1485 25.0653L59.5 28L59.8515 25.0653L60.8508 25.8508L60.0653 24.8515L63 24.5L60.0653 24.1485Z"
          fill="#E5E5E5"
        />
      </g>
    </g>
  </svg>
);

export default OpenedCookieWithStars;
