import React, { FC, useState } from "react";
import classNames from "classnames";
import styles from "./ToggleBtnsComponent.module.scss";
import ToggleBtn from "../buttons/ToggleBtn/ToggleBtn";

type ToggleBtnsComponentProps = {
  btnsArray: { id: number; title: string; isActive: boolean; type: string }[];
  containerStyles?: string;
  setActiveBtn?: (type: string) => void;
  defaultBtnType?: string;
};

const ToggleBtnsComponent: FC<ToggleBtnsComponentProps> = ({
  btnsArray,
  containerStyles,
  setActiveBtn,
  defaultBtnType,
}) => {
  const [btnsData, setBtnsData] = useState([...btnsArray]);
  const [activeBtnType, setActiveBtnType] = useState(defaultBtnType);

  const toggleBtn = (type: string) => {
    if (activeBtnType !== type) {
      setActiveBtnType(type);
      setActiveBtn && setActiveBtn(type);
      setBtnsData((prevData) =>
        prevData.map((btn) =>
          btn.type === type
            ? { ...btn, isActive: !btn.isActive }
            : { ...btn, isActive: false },
        ),
      );
    }
  };

  return (
    <div className={classNames(styles.container, containerStyles)}>
      {btnsData.map((item) => (
        <ToggleBtn
          title={item.title}
          key={item.id}
          onClick={toggleBtn}
          type={item.type}
          isActive={item.isActive}
        />
      ))}
    </div>
  );
};

export default ToggleBtnsComponent;
