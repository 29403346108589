import React, { ChangeEvent, FC, useState } from "react";
import CustomHeightBottomSheet from "../../CustomHeightBottomSheet/CustomHeightBottomSheet";
import CloseBtnIcon from "assets/svg/close-btn";
import DefaultInput from "../../fields/DefaultInput";
import DefaultButton from "../../buttons/DefaultButton";
import http from "helpers/http";
import { useAppDispatch } from "store";
import { addUserName } from "store/slices/user";

type UserNameModalProps = {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
};
const UserNameModal: FC<UserNameModalProps> = ({ isOpen, toggleModal }) => {
  const [userName, setUserName] = useState("");
  const dispatch = useAppDispatch();

  const handleUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };
  const updateUserName = async () => {
    await http.post("/settings/set-user-name", { userName: userName });
    dispatch(addUserName(userName));
    toggleModal(false);
  };

  const modalContent = () => {
    return (
      <div>
        <div className="mb24">
          <p className="text-uppercase font-s10 yellow-text fMontserrat mt16 mb6">
            full name
          </p>
          <DefaultInput
            name="cardNumber"
            handleChange={handleUserName}
            inputVal={userName}
            placeholder="Andreas Christoforou"
          />
        </div>
        <DefaultButton
          disabled={!userName.length}
          text={`Get Started`}
          onClick={updateUserName}
        />
      </div>
    );
  };

  const headerContent = () => {
    return (
      <div className="flex i-align-s justify-content-space-between">
        <h2>
          Begin Your Personalized Journey:{" "}
          <span className="yellow-text">Enter Your Name Below</span>
        </h2>
        <div
          className="flex i-align-c pointer"
          style={{ paddingTop: 4 }}
          onClick={() => {
            toggleModal(false);
          }}
        >
          <CloseBtnIcon />
        </div>
      </div>
    );
  };

  return (
    <CustomHeightBottomSheet
      modalHeight={312}
      header={headerContent}
      content={modalContent}
      isModalOpen={isOpen}
      setOpen={toggleModal}
    />
  );
};

export default UserNameModal;
