import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./GenderSelect.module.scss";

type GenderCardProps = {
  type: string;
  isSelected: boolean;
  icon: (isSelected: boolean) => ReactNode;
  setGenderType: (genderType: string) => void;
};
const GenderCard: FC<GenderCardProps> = ({
  type,
  icon,
  isSelected,
  setGenderType,
}) => (
  <div
    className={classNames(
      styles.card,
      { [styles.cardSelected]: isSelected },
      "pointer",
    )}
    onClick={() => setGenderType(type)}
  >
    <div className={styles.circle}>{icon(isSelected)}</div>
    <p className="text">{type}</p>
  </div>
);

export default GenderCard;
