import { fromLatLng, setKey } from "react-geocode";

export const getUserLocationString = async () => {
  const permission = await navigator.permissions.query({ name: "geolocation" });
  if (permission.state === "granted") {
    try {
      const position: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
        });
      });
      const { latitude, longitude } = position.coords;

      setKey(`${process.env.REACT_APP_GOOGLE}`);

      const location = await fromLatLng(latitude, longitude);

      return {
        status: "success",
        formatted_address: location.results[0].formatted_address,
        geometryLon: longitude,
        geometryLat: latitude,
      };
    } catch (error) {
      console.error("Error getting user location:", error);
      return { status: "denied" };
    }
  } else {
    return { status: "denied" };
  }
};
