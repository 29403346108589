import React from "react";
const FireIcon = () => (
  <svg
    width="25"
    height="33"
    viewBox="0 0 25 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.67393 16.6565C6.81679 16.6565 6.72536 11.0337 6.72536 10.9422C6.72524 10.7073 6.65274 10.4782 6.51786 10.286C6.38286 10.0938 6.19192 9.94782 5.97109 9.86794C5.75383 9.78758 5.51727 9.77508 5.29275 9.83235C5.06823 9.88949 4.86633 10.0134 4.71395 10.1879C3.27215 11.9281 2.25132 13.9774 1.73109 16.1765C0.0739466 24.4965 4.38252 28.3822 6.30252 29.6738C7.99739 30.7932 9.98587 31.3857 12.0168 31.3767C14.9974 31.376 17.8631 30.2256 20.0168 28.1651C21.5988 26.6327 22.7192 24.6871 23.2506 22.5495C23.782 20.412 23.7029 18.1683 23.0225 16.0737C22.2428 13.8622 20.8996 11.8926 19.1252 10.3594C16.8029 8.38604 15.2959 5.62063 14.8967 2.59952C14.8627 2.38738 14.7698 2.18904 14.6282 2.02727C14.4867 1.8656 14.3025 1.74703 14.0967 1.68524C13.8951 1.62881 13.6821 1.62881 13.4805 1.68524C13.2789 1.74167 13.0968 1.85238 12.9538 2.00524C12.8281 2.14238 9.85683 5.43381 11.3195 11.2511C11.8795 13.4797 11.8224 15.171 11.171 16.017C10.7996 16.4529 10.2456 16.6898 9.67382 16.657L9.67393 16.6565Z"
      fill="#AF3333"
    />
  </svg>
);

export default FireIcon;
