import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import tabNavigationReducer from "./slices/tabNavigation";
import compatibilityReducer from "./slices/compatibility";
import purchasesReducer from "./slices/purchases";
import storage from "redux-persist/lib/storage";
import modalsReducer from "./slices/modals";
import partnerRegistrationReducer from "./slices/partnerRegistration";
import registrationReducer, {
  clearRegistrationState,
} from "./slices/registration";
import userReducer, { clearUserState } from "./slices/user";
import horoscopeReducer from "./slices/horoscopePrediction";
import coffeeReadingReducer from "./slices/coffeeReading";
import tarotReadingReducer from "./slices/tarotReading";
import userPartnersReducer from "./slices/userPartners";
import loaderReducer, { clearLoaderState } from "./slices/loader";
import { clearToken } from "../helpers/localStorageHelpers";

const persistLoaderConfig = {
  key: "loader",
  storage,
};

const persistTabNavigationConfig = {
  key: "tabNavigation",
  storage,
};

const persistCompatibilityConfig = {
  key: "compatibility",
  storage,
};

const purchasesConfig = {
  key: "purchases",
  storage,
};

const partnerRegistrationConfig = {
  key: "partnerRegistration",
  storage,
};

const registrationConfig = {
  key: "registration",
  storage,
};

const userConfig = {
  key: "user",
  storage,
};

const horoscopeConfig = {
  key: "horoscope",
  storage,
};

const coffeeReadingConfig = {
  key: "coffeeReading",
  storage,
};

const tarotReadingConfig = {
  key: "tarotReading",
  storage,
};

const userPartnersConfig = {
  key: "userPartners",
  storage,
};

const persistedPartnerRegistrationReducer = persistReducer(
  partnerRegistrationConfig,
  partnerRegistrationReducer,
);
const persistedRegistrationReducer = persistReducer(
  registrationConfig,
  registrationReducer,
);

const persistedCompatibilityReducer = persistReducer(
  persistCompatibilityConfig,
  compatibilityReducer,
);

const persistedTabNavigationReducer = persistReducer(
  persistTabNavigationConfig,
  tabNavigationReducer,
);

const persistedPurchasesReducer = persistReducer(
  purchasesConfig,
  purchasesReducer,
);

const persistedHoroscopeReducer = persistReducer(
  horoscopeConfig,
  horoscopeReducer,
);

const persistedLoaderReducer = persistReducer(
  persistLoaderConfig,
  loaderReducer,
);

const persistedUserReducer = persistReducer(userConfig, userReducer);

const persistedCoffeeReadingReducer = persistReducer(
  coffeeReadingConfig,
  coffeeReadingReducer,
);

const persistedTarotReadingReducer = persistReducer(
  tarotReadingConfig,
  tarotReadingReducer,
);

const persistedUserPartnersReducer = persistReducer(
  userPartnersConfig,
  userPartnersReducer,
);

const rootReducer = combineReducers({
  tabNavigation: persistedTabNavigationReducer,
  compatibility: persistedCompatibilityReducer,
  purchases: persistedPurchasesReducer,
  modals: modalsReducer,
  partnerRegistration: persistedPartnerRegistrationReducer,
  registration: persistedRegistrationReducer,
  user: persistedUserReducer,
  horoscope: persistedHoroscopeReducer,
  coffeeReading: persistedCoffeeReadingReducer,
  tarotReading: persistedTarotReadingReducer,
  userPartners: persistedUserPartnersReducer,
  loader: persistedLoaderReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const clearPersistor = async () => {
  persistor.purge();
  store.dispatch(clearRegistrationState());
  store.dispatch(clearUserState());
  store.dispatch(clearLoaderState());
  clearToken();
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
