import React from "react";

const DocumentIcon = ({ fill }: { fill: string }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.56923 58.125H51.4342C51.9518 58.125 52.3717 57.7051 52.3717 57.1875V2.8125C52.3717 2.2949 51.9518 1.875 51.4342 1.875H21.0537C21.0537 1.875 21.049 1.87695 21.0471 1.87695C21.0227 1.87695 20.9992 1.8873 20.9748 1.88906C20.8989 1.8957 20.8258 1.91055 20.7535 1.93496C20.7197 1.94629 20.6869 1.95566 20.6551 1.97148C20.5557 2.01836 20.461 2.07734 20.3813 2.15898L8.89727 13.984C8.81953 14.0637 8.76406 14.1574 8.72012 14.2559C8.71445 14.268 8.70508 14.2783 8.70039 14.2914C8.69199 14.3129 8.69297 14.3355 8.68535 14.357C8.66289 14.4293 8.64883 14.5023 8.64414 14.5783C8.64316 14.599 8.63281 14.6168 8.63281 14.6373V57.1878C8.63281 57.7054 9.05273 58.1253 9.57031 58.1253L9.56923 58.125ZM50.4957 56.25H10.5067V15.574H21.0537C21.5713 15.574 21.9912 15.1541 21.9912 14.6365V3.75H50.4967L50.4965 56.25H50.4957ZM20.1162 5.125V13.7005H11.7872L20.1162 5.125Z"
      fill={fill}
    />
    <path
      d="M17.2344 18.1605C16.7168 18.1605 16.2969 18.5804 16.2969 19.098C16.2969 19.6156 16.7168 20.0355 17.2344 20.0355H43.7674C44.285 20.0355 44.7049 19.6156 44.7049 19.098C44.7049 18.5804 44.285 18.1605 43.7674 18.1605H17.2344Z"
      fill={fill}
    />
    <path
      d="M43.7674 25.3995H17.2344C16.7168 25.3995 16.2969 25.8194 16.2969 26.337C16.2969 26.8546 16.7168 27.2745 17.2344 27.2745H43.7674C44.285 27.2745 44.7049 26.8546 44.7049 26.337C44.7049 25.8194 44.285 25.3995 43.7674 25.3995Z"
      fill={fill}
    />
    <path
      d="M43.7674 32.639H17.2344C16.7168 32.639 16.2969 33.0589 16.2969 33.5765C16.2969 34.0941 16.7168 34.514 17.2344 34.514H43.7674C44.285 34.514 44.7049 34.0941 44.7049 33.5765C44.7049 33.0589 44.285 32.639 43.7674 32.639Z"
      fill={fill}
    />
    <path
      d="M43.7674 39.8785H17.2344C16.7168 39.8785 16.2969 40.2984 16.2969 40.816C16.2969 41.3336 16.7168 41.7535 17.2344 41.7535H43.7674C44.285 41.7535 44.7049 41.3336 44.7049 40.816C44.7049 40.2984 44.285 39.8785 43.7674 39.8785Z"
      fill={fill}
    />
    <path
      d="M43.7674 47.118H17.2344C16.7168 47.118 16.2969 47.5379 16.2969 48.0555C16.2969 48.5731 16.7168 48.993 17.2344 48.993H43.7674C44.285 48.993 44.7049 48.5731 44.7049 48.0555C44.7049 47.5379 44.285 47.118 43.7674 47.118Z"
      fill={fill}
    />
  </svg>
);

export default DocumentIcon;
