import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import styles from "./LoveCompatibilityResultPage.module.scss";
import Layout from "components/Layout/Layout";
import { useLocation } from "react-router-dom";
import ZodiacCard from "./components/ZodiacCard/ZodiacCard";
import Pulse from "./components/animations/Pulse";
import CustomProgressBar from "components/progressBar/ProgressBar";
import ArrowDown from "assets/svg/arrow-down.svg";
import classNames from "classnames";
import moment from "moment/moment";
import StarIcon from "assets/svg/small-star.svg";
import ElementsComponent from "./components/ElementsComponent/ElementsComponent";
import RelationshipHurdlesComponent from "./components/RelationshipHurdlesComponent/RelationshipHurdlesComponent";
import Universe from "./components/animations/Universe";
import { useInView } from "react-intersection-observer";
import { useAppSelector } from "../../store";
import ToggleBtnsComponent from "../../components/ToggleBtnComponent/ToggleBtnsComponent";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const LoveCompatibilityResultPage = () => {
  const location = useLocation().state;
  const { firstSign, lastSign, compatibilityResult } = useAppSelector(
    (state) => state.compatibility,
  );

  console.log("compatibilityResult", compatibilityResult);

  const formattedDate = moment().format("D MMMM YYYY");
  const [aspectsType, setAspectsType] = useState("Positive");

  const [firstProgressBarRef, inViewFirst] = useInView({
    threshold: 0,
  });

  const [secondProgressBarRef, inViewSecond] = useInView({
    threshold: 0,
  });

  const [thirdProgressBarRef, inViewThird] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    ReactPixel.track("Love Compatibility Result Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/love-compatibility-result",
      title: "Love Compatibility Result Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const [readMoreHeight, setReadMoreHeight] = useState<number | string>(58);
  const [readMoreAspectsHeight, setReadMoreAspectsHeight] = useState<
    number | string
  >(58);
  const [readMoreOverviewHeight, setReadMoreOverviewHeight] = useState<
    number | string
  >(58);

  const aspectsBtnsData = [
    {
      id: 0,
      title: "Positive",
      isActive: true,
      type: "Positive",
    },
    {
      id: 1,
      title: "Negative",
      isActive: false,
      type: "Negative",
    },
  ];

  const relationshipHurdlesData = [
    {
      title: "Communication Styles",
      id: 0,
      mainText:
        "Aries tends to be direct and assertive, while Cancer values subtlety and emotional nuance. Bridging this gap in communication styles can pose a significant challenge.",
      secondText:
        "For Aries and Cancer to address emotional challenges, open communication is key. Aries should practice empathy, and Cancer can appreciate Aries' directness. Establishing clear boundaries and spending quality time together are crucial for finding balance. Professional guidance through counseling may help navigate persistent challenges.",
    },
    {
      title: "Handling Emotions",
      id: 1,
      mainText:
        "Aries may struggle with the depth of Cancer's emotions, while Cancer may find Aries' approach too brash. Navigating and understanding each other's emotional needs is vital for a harmonious connection.",
      secondText:
        "For Aries and Cancer to address emotional challenges, open communication is key. Aries should practice empathy, and Cancer can appreciate Aries' directness. Establishing clear boundaries and spending quality time together are crucial for finding balance. Professional guidance through counseling may help navigate persistent challenges.",
    },
    {
      title: "Independence vs. Dependence",
      id: 2,
      mainText:
        "Aries values independence, while Cancer seeks security and may lean towards dependency. Balancing these needs and finding a middle ground is crucial for a healthy relationship dynamic.",
      secondText:
        "For Aries and Cancer to address emotional challenges, open communication is key. Aries should practice empathy, and Cancer can appreciate Aries' directness. Establishing clear boundaries and spending quality time together are crucial for finding balance. Professional guidance through counseling may help navigate persistent challenges.",
    },
  ];

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className={styles.content}>
          <h1>
            <span className={"yellow-text"}>Love</span> Compatibility
          </h1>
          <div className="flex i-align-c mt24">
            <ZodiacCard
              iconUrl={
                location ? location.firstSign.iconUrl : firstSign.iconUrl
              }
              isUserZodiac={
                location
                  ? location.firstSign.isUserZodiac
                  : firstSign.isUserZodiac
              }
              title={location ? location.firstSign.title : firstSign.title}
            />
            <Pulse />
            <ZodiacCard
              iconUrl={location ? location.lastSign.iconUrl : lastSign.iconUrl}
              isUserZodiac={
                location
                  ? location.lastSign.isUserZodiac
                  : lastSign.isUserZodiac
              }
              title={location ? location.lastSign.title : lastSign.title}
              isSecondSign
            />
          </div>
          <div className={styles.progressBarContainer}>
            <h1>8.0</h1>
            <CustomProgressBar
              completed={8}
              maxCompleted={10}
              customBarContainerStyles={styles.progressBar}
              customBarWrapperStyles={styles.progressBarWrapper}
              transitionTimingFunction={"ease-in"}
              animateOnRender
              transitionDuration={"1s"}
            />
            <div className={styles.labelsContainer}>
              <p>0</p>
              <p>10</p>
            </div>
          </div>
          <div className={styles.elementsContainer}>
            <h2>Elements</h2>
            <ElementsComponent />
            <div
              className={classNames(styles.textContainer, "mt16")}
              style={{
                height: readMoreHeight,
              }}
            >
              <p>{compatibilityResult.elements}</p>
              {readMoreHeight === 58 && <div className={styles.textGradient} />}
            </div>
            <div className={styles.btnWrapper}>
              {readMoreHeight === 58 && (
                <button
                  type="button"
                  className={classNames("linkBtn", styles.customBtn)}
                  onClick={() => setReadMoreHeight("auto")}
                >
                  Read more
                  <img src={ArrowDown} alt={""} />
                </button>
              )}
            </div>
          </div>
          <div className={styles.overviewContainer}>
            <Universe />
            <h3>
              <span className={"yellow-text"}>Relationship</span> Overview
            </h3>
            <div
              className={styles.textContainer}
              style={{
                height: readMoreHeight,
              }}
            >
              <p>
                Full of optimism, generosity, and romance, this pair shares a
                deep understanding and passionate love. They delight in life's
                pleasures, sharing...
              </p>
              {readMoreOverviewHeight === 58 && (
                <div
                  className={classNames(
                    styles.textGradient,
                    styles.textGradientInCard,
                  )}
                />
              )}
            </div>
            <div className={styles.overviewBtnWrapper}>
              {readMoreOverviewHeight === 58 && (
                <button
                  type="button"
                  className={classNames("linkBtn", styles.customBtn)}
                  onClick={() => setReadMoreOverviewHeight("auto")}
                >
                  Read more
                  <img src={ArrowDown} alt={""} />
                </button>
              )}
            </div>
          </div>
          <div className={classNames(styles.luckyDayContainer, "mt40")}>
            <h3>Lucky Date</h3>
            <div className={styles.dateContainer}>
              <img src={StarIcon} alt={"star"} />
              <p>{formattedDate}</p>
              <img src={StarIcon} alt={"star"} />
            </div>
            <p>{compatibilityResult.ideal_date}</p>
          </div>
          <div className={classNames(styles.metricsContainer, "mt40")}>
            <h3>Compatibility Metrics</h3>
            <div className={classNames(styles.metricCard, "mt24")}>
              <div className={styles.cardHeader}>
                <h3>General</h3>
                <div
                  className={classNames(styles.colorLabel, styles.bgSecondary)}
                >
                  heigh
                </div>
              </div>
              <div
                className={styles.metricsProgressBar}
                ref={firstProgressBarRef}
              >
                {inViewFirst && (
                  <CustomProgressBar
                    completed={compatibilityResult.score.general}
                    customLabel={compatibilityResult.score.general}
                    maxCompleted={5}
                    customBarContainerStyles={styles.progressBar}
                    customBarWrapperStyles={styles.progressBarWrapper}
                    transitionTimingFunction={"ease-in"}
                    animateOnRender
                    transitionDuration={"1s"}
                  />
                )}
                <div className={styles.labelsContainer}>
                  <p>0</p>
                  <p>5</p>
                </div>
              </div>
              <p className={styles.text}>
                Aries (Fire) and Cancer (Water) exhibit a dynamic blend of
                passion and emotion. While their approaches differ, these
                differences can create a harmonious balance when navigated with
                understanding. Open communication and mutual appreciation are
                crucial for a successful connection.
              </p>
            </div>

            <div className={classNames(styles.metricCard, "mt12")}>
              <div className={styles.cardHeader}>
                <h3>Communication</h3>
                <div
                  className={classNames(styles.colorLabel, styles.bgRoyalBlue)}
                >
                  medium
                </div>
              </div>
              <div
                className={styles.metricsProgressBar}
                ref={secondProgressBarRef}
              >
                {inViewSecond && (
                  <CustomProgressBar
                    completed={compatibilityResult.score.communication}
                    customLabel={compatibilityResult.score.communication}
                    maxCompleted={5}
                    customBarContainerStyles={styles.progressBar}
                    customBarWrapperStyles={styles.progressBarWrapper}
                    transitionTimingFunction={"ease-in"}
                    animateOnRender
                    transitionDuration={"1s"}
                  />
                )}
                <div className={styles.labelsContainer}>
                  <p>0</p>
                  <p>5</p>
                </div>
              </div>
              <p className={styles.text}>
                Aries and Cancer may face communication challenges due to
                distinct styles. Aries is direct and assertive, while Cancer
                values emotional subtlety. Patience and willingness to adapt
                communication styles can foster better understanding in this
                dynamic pairing.
              </p>
            </div>

            <div className={classNames(styles.metricCard, "mt12")}>
              <div className={styles.cardHeader}>
                <h3>Sex</h3>
                <div className={classNames(styles.colorLabel, styles.bgPurple)}>
                  low
                </div>
              </div>
              <div
                className={styles.metricsProgressBar}
                ref={thirdProgressBarRef}
              >
                {inViewThird && (
                  <CustomProgressBar
                    completed={compatibilityResult.score.sex}
                    customLabel={compatibilityResult.score.sex}
                    maxCompleted={5}
                    customBarContainerStyles={styles.progressBar}
                    customBarWrapperStyles={styles.progressBarWrapper}
                    transitionTimingFunction={"ease-in"}
                    animateOnRender
                    transitionDuration={"1s"}
                  />
                )}
                <div className={styles.labelsContainer}>
                  <p>0</p>
                  <p>5</p>
                </div>
              </div>
              <p className={styles.text}>
                In the realm of intimacy, Aries and Cancer can create a
                passionate connection. Aries brings enthusiasm and adventurous
                energy, while Cancer adds emotional depth. The key lies in
                balancing Aries' fiery intensity with Cancer's need for
                emotional connection, fostering a satisfying and harmonious
                sexual dynamic.
              </p>
            </div>

            <div className={classNames(styles.aspectsContainer, "mt40")}>
              <h3>Aspects</h3>
              <ToggleBtnsComponent
                btnsArray={aspectsBtnsData}
                containerStyles={"mt24"}
                setActiveBtn={setAspectsType}
              />
              <div
                className={classNames(styles.textContainer, "mt16")}
                style={{
                  height: readMoreAspectsHeight,
                }}
              >
                <p>
                  {aspectsType === "Positive"
                    ? compatibilityResult.positive_aspects
                    : compatibilityResult.negative_aspects}
                </p>
                {readMoreAspectsHeight === 58 && (
                  <div
                    className={classNames(
                      styles.textGradient,
                      styles.textGradientInCard,
                    )}
                  />
                )}
              </div>
              <div className={styles.aspectsBtnWrapper}>
                {readMoreAspectsHeight === 58 && (
                  <button
                    type="button"
                    className={classNames("linkBtn", styles.customBtn)}
                    onClick={() => setReadMoreAspectsHeight("auto")}
                  >
                    Read more
                    <img src={ArrowDown} alt={""} />
                  </button>
                )}
              </div>
            </div>

            <div
              className={classNames(
                styles.relationshipHurdlesContainer,
                "mt40",
              )}
            >
              <h3 className={"mb24"}>Primary Relationship Hurdles</h3>
              <div className={styles.blocksContainer}>
                {relationshipHurdlesData.map((item) => (
                  <RelationshipHurdlesComponent
                    title={item.title}
                    mainText={item.mainText}
                    key={item.id}
                    secondText={item.secondText}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoveCompatibilityResultPage;
