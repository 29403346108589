import React, { useEffect, useRef } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import HumanCard from "components/cards/HumanCard/HumanCard";
import { useAppSelector } from "store";
import { userData } from "constants/mockedData";
import PersonalIcon from "assets/svg/personal.svg";
import Moon1 from "assets/svg/moons/moon.svg";
import Moon2 from "assets/svg/moons/moon-1.svg";
import Moon3 from "assets/svg/moons/moon-2.svg";
import Moon4 from "assets/svg/moons/moon-3.svg";
import Moon5 from "assets/svg/moons/moon-4.svg";
import Moon6 from "assets/svg/moons/moon-5.svg";

import BigArrowUpIcon from "assets/svg/arrow-up.svg";
import { handleScrollToSection } from "utils/defaults";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const PersonalConnectionDetailPage = () => {
  const partnerData = useAppSelector((state) => state.userPartners.partners);
  const { firstSign } = useAppSelector((state) => state.compatibility);

  const containerRef = useRef(null);

  useEffect(() => {
    ReactPixel.track("Personal Connection Detail Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/personal-connection-detail",
      title: "Personal Connection Detail Page",
    });
  }, []);

  const { personalConnectionReport, zodiac, zodiacName } = partnerData[0];

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h mb24" ref={containerRef}>
          <HeaderWithBack />
          <h1 className="t-align-l">
            <span className="yellow-text">Personal Connection</span> report
          </h1>
          <p className="mt16 fRoboto font-s16 font-w400 text-color-silver t-align-l">
            Welcome to your personalized Friendship Harmony Report, where the
            cosmos reveals the unique dynamics of your bond. Prepare to embark
            on an exploration through the celestial patterns that shape the
            tapestry of your shared journey.
          </p>
          <div className="flex gap12 mt24">
            <HumanCard
              humanData={userData}
              title={firstSign.title}
              iconUrl={firstSign.iconUrl}
              isUser
            />
            <HumanCard
              humanData={partnerData[0]}
              iconUrl={zodiac}
              title={zodiacName}
            />
          </div>
        </div>
        <div className="bg-el-transparent padding-v24 padding-content-h font-s16 font-w400 flex flex-column i-align-c gap8 fRoboto t-align-l text-color-silver">
          <img src={PersonalIcon} alt="" width={140} className="mt16" />
          <p className="mt16">{personalConnectionReport[0]}</p>
          <img src={Moon1} alt="" className="mt24" />
          <p className="mt16">{personalConnectionReport[1]}</p>
          <img src={Moon2} alt="" className="mt24" />
          <p className="mt16">{personalConnectionReport[2]}</p>
          <img src={Moon3} alt="" className="mt24" />
          <p className="mt16">{personalConnectionReport[3]}</p>
          <img src={Moon4} alt="" className="mt24" />
          <p className="mt16">{personalConnectionReport[4]}</p>
          <img src={Moon5} alt="" className="mt24" />
          <p className="mt16">{personalConnectionReport[5]}</p>
          <img src={Moon6} alt="" className="mt24" />
          <p className="mt16 mb32">{personalConnectionReport[6]}</p>
          <div
            className="flex flex-column linkBtn i-align-c font-w500 font-s16 fMontserrat gap4"
            onClick={() => handleScrollToSection(containerRef)}
          >
            <img src={BigArrowUpIcon} alt="" width={10} />
            Back to Top
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PersonalConnectionDetailPage;
