import React, { useEffect, useState } from "react";
import TabHeader from "components/headers/tabHeader/TabHeader";
import Layout from "components/Layout/Layout";
import CustomBottomNavigation from "components/bottomNavigation/CustomBottomNavigation";
import ZodiacLove from "assets/svg/zodiac-love.svg";
import { useNavigate } from "react-router-dom";
import DefaultButton from "components/buttons/DefaultButton";
import http from "helpers/http";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const BirthCartPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<
    { house: string; sign: string; degree: string }[]
  >([]);

  useEffect(() => {
    ReactPixel.track("Birth Chart Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/birth-chart",
      title: "Birth Chart Page",
    });
    const fetchData = async () => {
      const res: any = await http.get("/astro/house-cusps");
      setRows(res.astrologicData.message.houses);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="app-container">
        <TabHeader />
        <div className="padding-content-h t-align-l fRoboto font-s18 font-w400 text-color-silver">
          <h1 className="yellow-text">Birth Chart</h1>
          <p className="mt16">
            Welcome to a journey of self-discovery through the cosmic lens! A
            Natal Chart, or birth chart, is a celestial snapshot of the sky at
            the moment of your birth. It reveals the unique astrological DNA
            that shapes your personality, talents, challenges, and life path.
            Embark on an exploration of your inner cosmos and uncover the
            secrets written in the stars!
          </p>
          <h2 className="yellow-text mt32">
            You Personal Astrological Universe
          </h2>
          <div className="flex flex-column i-align-c mt24">
            <img src={ZodiacLove} alt="" />
          </div>
          <div className="overflow-hidden border-radius8 default-border font-s16 font-w400 mt32">
            <table
              className=" full-width"
              style={{
                background: "#172536",
                borderCollapse: "collapse",
              }}
            >
              <thead className="yellow-text font-s10 text-uppercase fMontserrat font-w600">
                <tr>
                  <th className="default-border">House</th>
                  <th className="default-border">Sign</th>
                  <th className="default-border">Degree</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td className="default-border">{row.house}</td>
                    <td className="default-border">{row.sign}</td>
                    <td className="default-border">{row.degree}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="default-border el-bg border-radius12 padding-h18 padding-v18 mt32">
            <h2 className="yellow-text mb8">Leadership and Independence</h2>
            <p>
              As an Aries Sun, you naturally take the lead and prefer to act on
              your own terms. Your independent spirit drives you to initiate
              projects and take charge, often inspiring others with your courage
              and enthusiasm.
            </p>
          </div>

          <div className="default-border el-bg border-radius12 padding-h18 padding-v18 mt16">
            <h2 className="yellow-text mb8">Impulsiveness and Enthusiasm</h2>
            <p>
              Your energetic and bold approach is a double-edged sword. While it
              enables you to start new ventures with unmatched zeal, it can also
              lead to hasty decisions and a tendency to overlook details. You
              thrive on excitement and adventure, often finding routine tasks
              less appealing.
            </p>
          </div>

          <div className="default-border el-bg border-radius12 padding-h18 padding-v18 mt16">
            <h2 className="yellow-text mb8">
              Understanding the Astrological Houses
            </h2>
            <p>
              The houses in astrology are like different scenes in the play of
              your life, each holding its own theme and characters. Dive deeper
              into what each house represents, from your sense of self to your
              relationships, career, and beyond. Click here to explore the roles
              and meanings of each house in detail.
            </p>
          </div>

          <div className="default-border el-bg border-radius12 padding-h18 padding-v18 mt16">
            <h2 className="yellow-text mb8">Challenge in Patience</h2>
            <p>
              One of your significant challenges is developing patience and a
              more considered approach to situations. Learning to value the
              perspectives of others and balancing your pioneering spirit with
              foresight and collaboration can enhance your natural leadership
              qualities.
            </p>
          </div>
          <DefaultButton
            customStyles="mb40 mt32"
            text={"Read about houses"}
            type="button"
            onClick={() => navigate("/twelve-houses")}
          />
        </div>
        <CustomBottomNavigation />
      </div>
    </Layout>
  );
};

export default BirthCartPage;
