import Layout from "components/Layout/Layout";
import React, { useEffect } from "react";
import Confetti from "assets/svg/noun-confetti.svg";
import DefaultButton from "components/buttons/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithHome from "components/headers/HeaderWithHome";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import http from "../../helpers/http";
import { clearPersistor, useAppSelector } from "../../store";
// import http from "../../helpers/http";

const CancelSubscriptionTooExpensive = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const { userData } = useAppSelector((state) => state.user);

  const isDeleteFlow = location.from === "delete";

  useEffect(() => {
    if (isDeleteFlow) {
      ReactPixel.track("Delete Account Discount Page");
      ReactGA.send({
        hitType: "pageview",
        page: "/cancel-subscription-discount",
        title: "Delete Account Discount Page",
      });
    } else {
      ReactPixel.track("Cancel Subscription Discount Page");
      ReactGA.send({
        hitType: "pageview",
        page: "/cancel-subscription-discount",
        title: "Cancel Subscription Discount Page",
      });
    }
  }, []);
  const onClickCancel = async () => {
    try {
      if (isDeleteFlow) {
        await http.delete("/settings/delete-account");
        navigate("/account-deleted");
        await clearPersistor();
      } else {
        await http.post("/payment/cancel-subscription", {
          email: userData.email,
          reason: "To expensive",
        });
        navigate("/cancelled-subscription");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickGetDiscount = () => navigate("/app-payment");

  return (
    <Layout>
      <div className="container">
        <HeaderWithHome />
        <div className="position-relative" style={{ height: "94%" }}>
          <h2 className="yellow-text">
            Wait, we have a discount for your current plan!
          </h2>
          <p className="fRoboto text-white font-w400 font-s18 mt8">
            We understand that budget considerations are important. To ensure
            you're getting the best value, we wan to suggest your personal
            discount
          </p>
          <div
            className="custom-border-gradient2 padding-h18 padding-v18 fMontserrat width-fit position-relative"
            style={{ margin: "40px auto 0" }}
          >
            <span
              className="text-uppercase text-color-silver font-s8 position-absolute absolute-left0 absolute-right0"
              style={{
                top: 12,
                textDecoration: "line-through",
                textDecorationThickness: 1,
              }}
            >
              $1.43/day
            </span>
            <div className="flex i-align-c gap12">
              <img src={Confetti} alt="" width={28.8} />
              <h2 className="yellow-text">$1.30/day</h2>
              <img src={Confetti} alt="" width={28.8} />
            </div>
          </div>
          <div className="bottom-container-absolute">
            <DefaultButton text="Get a Discount" onClick={onClickGetDiscount} />
            <DefaultButton
              text={isDeleteFlow ? "Delete Account" : "Cancel Subscription"}
              customStyles="blue-border primary-bg text-color-silver mt24 mb40"
              onClick={onClickCancel}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CancelSubscriptionTooExpensive;
