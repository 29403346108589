import React, { FC } from "react";
import CustomZodiacCard from "components/CustomZodiacCard/CustomZodiacCard";
import classNames from "classnames";
import NounQuestion from "assets/svg/noun-question.svg";

type HumanCardProps = {
  title?: string;
  iconUrl?: string;
  isUser?: boolean;
  humanData: {
    name: string;
    gender: string;
    birthday: string;
    birthtime: string;
    birthplace: string;
  };
};
const HumanCard: FC<HumanCardProps> = ({
  title,
  iconUrl,
  isUser = false,
  humanData,
}) => {
  const { name, gender, birthtime, birthday, birthplace } = humanData;

  return (
    <div className="padding-v16 padding-h12 gap16 i-align-unset width245 bg-with-blur3 default-border border-radius12">
      <CustomZodiacCard
        title={title ? title : "zodiac sign"}
        iconUrl={iconUrl ? iconUrl : NounQuestion}
        cardWidth={74}
        cardHeight={74}
        iconWidth={40}
        iconHeight={40}
        customTitleStyles={classNames("font-s10 mb4", {
          ["yellow-text"]: isUser,
        })}
      />
      <div className="flex flex-column gap4 i-align-s font-s10">
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="yellow-text" style={{ marginRight: 4 }}>
            Name:
          </span>
          <span className="text-color-silver">{name}</span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="yellow-text font-s10" style={{ marginRight: 4 }}>
            gender:
          </span>
          <span className=" text-color-silver">{gender}</span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="yellow-text" style={{ marginRight: 4 }}>
            Birthdate:
          </span>
          <span className="text-color-silver">{birthday}</span>
        </p>
        <p className="flex gap4 t-align-l i-align-c font-w600 text-uppercase">
          <span className="yellow-text" style={{ marginRight: 4 }}>
            birthtime:
          </span>
          <span className=" text-color-silver">{birthtime}</span>
        </p>
        <p className="flex gap4 t-align-l i-align-s font-w600 text-uppercase">
          <span className="yellow-text" style={{ marginRight: 4 }}>
            birthplace:
          </span>
          <span className=" text-color-silver">{birthplace}</span>
        </p>
      </div>
    </div>
  );
};

export default HumanCard;
