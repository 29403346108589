import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import styles from "./BiorhythmsReportsPage.module.scss";
// import ToggleBtnsComponent from "components/ToggleBtnComponent/ToggleBtnsComponent";
import { PolarAngleAxis, PolarGrid, Radar, RadarChart } from "recharts";
import { dataToday, dataTomorrow } from "../HomePage/RadarConfig";
import CardWithProgressBar from "../../components/cards/CardWithProgressBar/CardWithProgressBar";
import { useInView } from "react-intersection-observer";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const BiorhythmsReportsPage = () => {
  // const [selectedPeriod, setPeriod] = useState(0);
  const [selectedPeriod] = useState(0);

  const [firstProgressBarRef, inViewFirst] = useInView({
    threshold: 0,
  });

  const [secondProgressBarRef, inViewSecond] = useInView({
    threshold: 0,
  });

  const [thirdProgressBarRef, inViewThird] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    ReactPixel.track("Biorhythms Reports Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/biorhythms-reports",
      title: "Biorhythms Reports Page",
    });
    window.scrollTo(0, 0);
  }, []);

  // const btnsData = [
  //   {
  //     id: 0,
  //     title: "Today",
  //     isActive: true,
  //   },
  //   {
  //     id: 1,
  //     title: "Tomorrow",
  //     isActive: false,
  //   },
  // ];

  const cardsData = [
    {
      title: "Emotional",
      btnTitle: "Heigh",
      btnColor: "#3F5497",
      id: 0,
      completed: selectedPeriod === 0 ? 7.2 : 5.1,
      progressContainerStyles: "progress-bar-secondary",
      cardText:
        "Today's vibe is high energy and enthusiasm in the morning—confidence and action. Watch impatience. Evening is for self-care and reflection. Embrace your Aries courage.",
    },
    {
      title: "Physical",
      btnTitle: "medium",
      btnColor: "#9B7BD0",
      id: 1,
      completed: selectedPeriod === 0 ? 5.2 : 8.5,
      progressContainerStyles: "progress-bar-purple",
      cardText:
        "Today, your physical energy is on the rise. In the morning, expect vitality and a natural inclination for activity. Channel this energy into a productive day. Be mindful of pacing to avoid burnout. In the evening, prioritize rest and relaxation to recharge. Stay active, but listen to your body's signals.",
    },
    {
      title: "Intellectual",
      btnTitle: "low",
      btnColor: "#746C20",
      id: 2,
      completed: selectedPeriod === 0 ? 4.8 : 3.1,
      progressContainerStyles: "progress-bar-muted-gold",
      cardText:
        "Today, your intellectual energy is soaring. Morning is prime for focused, strategic thinking—tackle tasks requiring mental prowess. Guard against restlessness, and take short breaks. Afternoon may bring creative sparks, so explore new ideas. Evening, engage in light mental activities or unwind with a good book. Embrace your sharp Aries intellect.",
    },
  ];

  return (
    <Layout>
      <div className="container">
        <HeaderWithBack />
        <div className={styles.content}>
          <h1>Biorhythms</h1>
          {/*<ToggleBtnsComponent*/}
          {/*  btnsArray={btnsData}*/}
          {/*  containerStyles={"mt24"}*/}
          {/*  setActiveBtn={setPeriod}*/}
          {/*/>*/}
          <div className={styles.radarContainer}>
            <RadarChart
              width={400}
              height={180}
              cx={200}
              cy={90}
              outerRadius={65}
              data={selectedPeriod === 0 ? dataToday : dataTomorrow}
            >
              <PolarGrid
                gridType="circle"
                stroke={"#27394B"}
                polarRadius={[65]}
                strokeWidth={2}
              />
              <PolarAngleAxis
                tick={{ fill: "#E5E5E5" }}
                dataKey="subject"
                fontFamily={"Montserrat"}
                fontSize={10}
                fontWeight={600}
              />
              <Radar
                name="Lily"
                dataKey="B"
                strokeWidth={2}
                stroke="#27394B"
                fill="rgba(90, 140, 141, 0.60)"
                fillOpacity={0.6}
              />
            </RadarChart>
          </div>
          <h2 className={"mb24"}>Overview</h2>
          <div className={"flex gap12 flex-column"}>
            {cardsData.map((item) => (
              <CardWithProgressBar
                inView={
                  item.id === 1
                    ? inViewFirst
                    : item.id === 2
                      ? inViewSecond
                      : inViewThird
                }
                ref={
                  item.id === 1
                    ? firstProgressBarRef
                    : item.id === 2
                      ? secondProgressBarRef
                      : thirdProgressBarRef
                }
                key={item.id}
                item={item}
                customContainerStyles={"padding-v18 padding-h18"}
              />
            ))}
          </div>
          <h2 className={"mt32 mb16"}>Recommendations</h2>
          <div className={"flex flex-column gap8 padding-b40"}>
            <p className={"fRoboto font-w400 font-s18"}>
              Embrace the dynamic synergy of your emotional, physical, and
              intellectual biorhythms for a balanced and fulfilling day:
            </p>
            <p className={"fRoboto font-w400 font-s18"}>
              In the morning, channel your high emotional energy into positive
              actions, synchronized with your physical vitality. Be mindful of
              impatience, pacing yourself to maintain a harmonious balance.
            </p>
            <p className={"fRoboto font-w400 font-s18"}>
              During the day, utilize your sharp Aries intellect for focused
              tasks, exploring creative ideas in the afternoon. This mental
              engagement aligns with your dynamic energy, creating a cohesive
              flow.
            </p>
            <p className={"fRoboto font-w400 font-s18"}>
              As evening approaches, prioritize self-care and reflection,
              allowing both your emotional and physical aspects to unwind.
              Embrace the day's harmony by integrating your Aries traits into
              every facet of your experience.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BiorhythmsReportsPage;
