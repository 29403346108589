import React, { FC } from "react";
import styles from "./CustomZodiacCard.module.scss";
import classNames from "classnames";

type CustomZodiacCardProps = {
  isUserZodiac?: boolean;
  title: string;
  iconUrl: string;
  isSecondSign?: boolean;
  customStyles?: string;
  cardWidth: number;
  cardHeight: number;
  iconWidth: number;
  iconHeight: number;
  customTitleStyles?: string;
};
const CustomZodiacCard: FC<CustomZodiacCardProps> = ({
  title,
  isUserZodiac,
  iconUrl,
  isSecondSign = false,
  customStyles,
  cardWidth,
  cardHeight,
  iconWidth,
  iconHeight,
  customTitleStyles,
}) => {
  const titleClassNames = classNames("fMontserrat font-w600 text-uppercase", {
    [styles.selectedTitle]: isUserZodiac,
    [`${customTitleStyles}`]: customTitleStyles,
  });

  const circleClassNames = classNames(styles.circle, "flex i-align-c", {
    [styles.secondBlueCircle]: isSecondSign,
  });

  return (
    <div
      className={classNames(styles.container, "flex flex-column i-align-c", {
        [`${customStyles}`]: customStyles,
      })}
    >
      <p className={titleClassNames}>{title}</p>
      <div
        className={circleClassNames}
        style={{ width: cardWidth, height: cardHeight }}
      >
        <img
          src={iconUrl}
          alt=""
          style={{ width: iconWidth, height: iconHeight }}
        />
      </div>
    </div>
  );
};

export default CustomZodiacCard;
