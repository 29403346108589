import React from "react";

const CameraComponent = () => {
  return (
    <>
      <video width={"100%"} style={{ objectFit: "cover", height: 534 }} />
      <canvas id={"camera-canvas"} style={{ opacity: 0 }} />
    </>
  );
};

export default CameraComponent;
