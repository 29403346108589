import React, { FC, ReactNode } from "react";
import styles from "./StripeInput.module.scss";
import classNames from "classnames";

type StripeInput = {
  children: ReactNode;
  customStyles?: string;
};
const StripeInput: FC<StripeInput> = ({ children, customStyles }) => {
  return (
    <div className={classNames(customStyles, styles.inputWrapper)}>
      {children}
    </div>
  );
};

export default StripeInput;
