import React, { Fragment, useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import TabHeader from "components/headers/tabHeader/TabHeader";
import styles from "./HomePage.module.scss";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";

import ArrowRight from "assets/svg/arrow-right";
import classNames from "classnames";
import CustomBottomNavigation from "components/bottomNavigation/CustomBottomNavigation";
import CoffeeCupIcon from "assets/svg/coffee-cup-reading";
import DefaultButton from "components/buttons/DefaultButton";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import CustomProgressBar from "components/progressBar/ProgressBar";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from "recharts";
import PredictionBallTop from "assets/svg/prediction-ball/Top-part.svg";
import PredictionBallBottom from "assets/svg/prediction-ball/Bottom-part.svg";

import { data } from "./RadarConfig";
import PersonalizedCompatibilityI from "assets/svg/personalized-сompatibility";

import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

import "./styles.scss";
import BottomModalSheet from "components/BottomModalSheet/BottomModalSheet";
import { useNavigate } from "react-router-dom";
import FlippedTarotCard from "components/FlippedTarotCard/FlippedTarotCard";
import { biorhythms, dailyTips, typeHoroscope } from "./mockedData";
import ZodiacCard from "../LoveCompatibilityResultPage/components/ZodiacCard/ZodiacCard";
import { useAppDispatch, useAppSelector } from "store";
import { InView, useInView } from "react-intersection-observer";
import http from "helpers/http";
import { setHoroscopeData } from "store/slices/horoscopePrediction";
import ToggleBtnsComponent from "components/ToggleBtnComponent/ToggleBtnsComponent";
import {
  addClassToModalDiv,
  addClassToSpecificHiddenModalDiv,
} from "utils/defaults";
import HoroscopeInfographicItem from "./components/HoroscopeInfographicItem/HoroscopeInfographicItem";
import PinkCircleIcon from "assets/svg/pink-circle";
import { fetchTarotReading, TarotCardData } from "store/slices/tarotReading";
import UserNameModal from "../../components/modals/UserNameModal/UserNameModal";
import PalmReading from "./components/PalmReading/PalmReading";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

import LogRocket from "logrocket";
LogRocket.init("nwxyyv/astroday");

ChartJS.register(ArcElement);

interface Prediction {
  love: { percent: number; text: string };
  health: { percent: number; text: string };
  career: { percent: number; text: string };
}

interface HoroscopeData {
  [key: string]: Prediction;
}

interface PredictionItem {
  title: string;
  color: string;
  [key: string]: any;
}

const HomePage = () => {
  const horoscopeState = useAppSelector((state) => state.horoscope);
  const { daily, monthly, weekly, yearly } = horoscopeState;
  const { savedReading } = useAppSelector((state) => state.coffeeReading);
  const { tarotReadingResult } = useAppSelector((state) => state.tarotReading);
  const [isCardsFlipped, setIsCardsFlipped] = useState(false);
  const [flippedCardsCount, setFlippedCardsCount] = useState(0);
  const [isUserNameModalOpen, toggleUserNameModal] = useState(false);

  const { userData } = useAppSelector((state) => state.user);

  const [tarotResult, setTarotResult] =
    useState<TarotCardData[]>(tarotReadingResult);

  const dispatch = useAppDispatch();
  const [elemRef2, inView2] = useInView({
    threshold: 0,
  });

  const [BiorhythmsChartsRef, biorhythmsChartsView] = useInView({
    threshold: 0,
  });

  const { firstSign, lastSign } = useAppSelector(
    (state) => state.compatibility,
  );

  useEffect(() => {
    ReactPixel.track("Home Page");
    ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const dailyRes: any = await http.get("/astro/daily-horoscope");
        const weeklyRes: any = await http.get("/astro/weekly-horoscope");
        const monthlyRes: any = await http.get("/astro/monthly-horoscope");
        const yearlyRes: any = await http.get("/astro/yearly-horoscope");
        dispatch(
          setHoroscopeData({ dailyRes, weeklyRes, monthlyRes, yearlyRes }),
        );
        await dispatch(fetchTarotReading());
      } catch (error) {
        console.error("Error fetching horoscope:", error);
      }
    };

    fetchData();

    if (!userData.userName) toggleUserNameModal(true);
  }, []);

  useEffect(() => {
    if (isUserNameModalOpen) {
      addClassToSpecificHiddenModalDiv(1);
    }
  }, [isUserNameModalOpen]);

  const [isModalOpen, openModal] = useState(false);
  const [modalData, setModalData] = useState<PredictionItem[]>([
    { ...daily.prediction.love, color: "#7361A1", title: "Love" },
    { ...daily.prediction.health, color: "#374A82", title: "Health" },
    { ...daily.prediction.career, color: "#3F636B", title: "Career" },
  ]);
  const [selectedHoroscopePeriod, setHoroscopePeriod] = useState("daily");

  const setPeriod = (periodType: string) => {
    setHoroscopePeriod(periodType);
  };
  const navigate = useNavigate();

  const plugins = [
    new Pagination({
      type: "bullet",
    }),
  ];

  const onPressHoroscopeInfographic = (name: string): void => {
    addClassToModalDiv();

    const predictionData: HoroscopeData = {
      daily: {
        love: daily.prediction.love,
        health: daily.prediction.health,
        career: daily.prediction.career,
      },
      week: {
        love: weekly.prediction.love,
        health: weekly.prediction.health,
        career: weekly.prediction.career,
      },
      month: {
        love: monthly.prediction.love,
        health: monthly.prediction.health,
        career: monthly.prediction.career,
      },
      year: {
        love: yearly.prediction.love,
        health: yearly.prediction.health,
        career: yearly.prediction.career,
      },
    };

    const selectedData = predictionData[selectedHoroscopePeriod];

    const newData: PredictionItem[] = [
      { ...selectedData.love, color: "#7361A1", title: "Love" },
      { ...selectedData.health, color: "#374A82", title: "Health" },
      { ...selectedData.career, color: "#3F636B", title: "Career" },
    ];

    const itemIndex = newData.findIndex((item) => item.title === name);

    if (itemIndex > -1) {
      const item = newData.splice(itemIndex, 1)[0];
      newData.unshift(item);
      setModalData(newData);
    }

    openModal(true);
  };

  const toggleCard = (id: string) => {
    if (flippedCardsCount !== 3) {
      const newCardsState = JSON.parse(JSON.stringify(tarotResult));
      const toggledCard = newCardsState.find(
        (item: { cardTitle: string }) => item.cardTitle === id,
      );
      if (toggledCard) {
        toggledCard.isCardFlipped = true;
        setTarotResult(newCardsState);
        setFlippedCardsCount((prev) => prev + 1);
      }
      if (flippedCardsCount === 2) {
        setIsCardsFlipped(true);
      }
    }
  };

  const toggleAllCards = () => {
    const newCardsState = JSON.parse(JSON.stringify(tarotResult));
    newCardsState.forEach((item: TarotCardData) => (item.isCardFlipped = true));
    setTarotResult(newCardsState);
    setIsCardsFlipped(true);
  };

  const navigateToLoveCompatibility = () =>
    navigate("/love-compatibility-result");

  const navigateToBiorhythmsReports = () => navigate("/biorhythms-reports");

  return (
    <Layout>
      <div className="app-container">
        <TabHeader />
        <div className={styles.contentContainer}>
          <div className={styles.firstBlock}>
            <h2>
              Your <span className="yellow-text">({daily.sign}) </span>
              Horoscope
            </h2>
            <ToggleBtnsComponent
              setActiveBtn={setPeriod}
              btnsArray={typeHoroscope}
              containerStyles={"mt24"}
              defaultBtnType={"daily"}
            />
            <Fragment>
              <div
                className={"flex i-align-c mt16 mb24 gap8"}
                style={{ height: 94 }}
              >
                <HoroscopeInfographicItem
                  horoscopeInfographic={
                    selectedHoroscopePeriod === "daily"
                      ? daily.prediction
                      : selectedHoroscopePeriod === "week"
                        ? weekly.prediction
                        : selectedHoroscopePeriod === "month"
                          ? monthly.prediction
                          : yearly.prediction
                  }
                  onPressHoroscopeInfographic={onPressHoroscopeInfographic}
                />
              </div>
              <p className={"fRoboto font-w400 font-s16 mb40"}>
                {daily.prediction.predictionText}
              </p>
            </Fragment>
          </div>
          <div className={classNames(styles.luckContainer)}>
            <h2>
              Your <span className="yellow-text">luck</span> today
            </h2>
            <div
              className={"flex i-align-c mt16 mb24 gap8"}
              style={{ height: 94 }}
            >
              <div
                className={classNames("pointer", styles.infographicContainer)}
                onClick={() => navigate("/lucky-letters")}
              >
                <p className={styles.title}>
                  Letters
                  <ArrowRight />
                </p>
                <div className={styles.luckyItemContainer}>
                  {daily.prediction.luckyAlphabets.join(", ")}
                </div>
              </div>
              <div
                className={classNames("pointer", styles.infographicContainer)}
                onClick={() => navigate("/lucky-color")}
              >
                <p className={styles.title}>
                  Color
                  <ArrowRight />
                </p>
                <div className={styles.luckyItemContainer}>
                  <PinkCircleIcon color={daily.prediction.luckyColorCode} />
                </div>
              </div>
              <div
                className={classNames("pointer", styles.infographicContainer)}
                onClick={() => navigate("/lucky-numbers")}
              >
                <p className={styles.title}>
                  Numbers
                  <ArrowRight />
                </p>
                <div className={styles.luckyItemContainer}>
                  {daily.prediction.luckyNumbers.join(", ")}
                </div>
              </div>
            </div>
          </div>
          <div className="padding-content-h">
            <PalmReading palmPhotoUrl={userData.palmPhotoUrl} />
          </div>
          <div className={styles.dailyTipContainer}>
            <h2>
              Cosmic <span className="yellow-text">Daily Tip</span>
            </h2>
            <div
              className="position-relative flex flex-column t-align-c i-align-c"
              style={{ marginTop: 29 }}
            >
              <div
                className="flex justify-content-start i-align-s"
                style={{ height: 145.193 }}
              >
                <img src={PredictionBallTop} alt="" height={130} />
                <img
                  src={PredictionBallBottom}
                  alt=""
                  className="position-absolute"
                  style={{ bottom: -1 }}
                />
              </div>
              <p className="position-absolute" style={{ width: 76, top: 45 }}>
                Opinions do not define you
              </p>
            </div>
          </div>
          <div className={styles.sliderContainer}>
            <h2>Daily Tips</h2>
            <InView>
              {({ inView, ref }) => (
                <div className="mt24 mb40" style={{ height: 261 }} ref={ref}>
                  {inView && (
                    <Flicking
                      renderOnlyVisible={true}
                      plugins={plugins}
                      align={{ camera: "5.33%", panel: "5.33%" }}
                      onMoveEnd={(e) => {
                        console.log(e);
                      }}
                    >
                      {dailyTips.map((item) => (
                        <div
                          className={styles.dailyTipItemContainer}
                          key={item.id}
                        >
                          {item.icon()}
                          <h3>{item.title}</h3>
                          <p className="t-align-l">{item.text}</p>
                        </div>
                      ))}
                      <ViewportSlot>
                        <div className="flicking-pagination" />
                      </ViewportSlot>
                    </Flicking>
                  )}
                </div>
              )}
            </InView>
          </div>
          <div className={classNames("pointer", styles.dailyTipContainer)}>
            <h2 onClick={() => navigate("/coffee-cup-reading")}>
              <span className="yellow-text">Coffee Cup </span>Reading
            </h2>
            <div className={classNames("pointer", styles.coffeeCupContainer)}>
              <div
                className={classNames(
                  styles.itemsContainer,
                  "flex gap16 i-align-c",
                )}
                onClick={() => navigate("/coffee-cup-reading")}
              >
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={index}
                    className={classNames(
                      styles.itemContainer,
                      "flex i-align-c border-radius12 default-border",
                    )}
                  >
                    <CoffeeCupIcon />
                  </div>
                ))}
              </div>
              {!!savedReading.near_future_title && (
                <div className={"mt16 flex i-align-c mb32"}>
                  <button
                    className="linkBtn"
                    onClick={() => navigate("/coffee-cup-reading-result")}
                  >
                    See your recent result <BigArrowRightIcon />
                  </button>
                </div>
              )}
              <DefaultButton
                customStyles={classNames({
                  ["mt32"]: !savedReading.near_future_title,
                })}
                text={"Get reading"}
                onClick={() => navigate("/coffee-cup-reading")}
              />
            </div>
          </div>
          <div
            className={classNames("pointer", styles.compatibilityContainer)}
            onClick={navigateToLoveCompatibility}
          >
            <h2>
              <span className="yellow-text">Love</span> Compatibility
            </h2>
            <div className="flex i-align-c mt24">
              <ZodiacCard
                iconUrl={firstSign.iconUrl}
                isUserZodiac={firstSign.isUserZodiac}
                title={firstSign.title}
              />
              <ZodiacCard
                iconUrl={lastSign.iconUrl}
                isUserZodiac={lastSign.isUserZodiac}
                title={lastSign.title}
                isSecondSign
              />
            </div>

            <div
              className={classNames(
                styles.infoContainer,
                "flex flex-column i-align-c position-relative",
              )}
            >
              <h1 className="mb8">8.0</h1>
              <CustomProgressBar
                completed={8}
                transitionDuration={"1s"}
                maxCompleted={10}
                animateOnRender
                customBarContainerStyles="progress-bar-purple"
              />
              <div
                className={classNames(
                  styles.labelsContainer,
                  "flex justify-content-space-between position-absolute",
                )}
              >
                <p>0</p>
                <p>10</p>
              </div>
            </div>

            <div className={classNames(styles.readMoreContainer, "mt24")}>
              <button className="linkBtn">
                Read More <BigArrowRightIcon />
              </button>
            </div>
            <DefaultButton
              style={{ zIndex: 5 }}
              text={"Discover More Pairings"}
              onClick={(event) => {
                event.stopPropagation();
                navigate("/love-compatibility-select");
              }}
            />
          </div>
        </div>
        <div className={styles.personalizedCompatibilityContainer}>
          <div className={styles.personalizedContainer}>
            <div className={styles.contentWrapper}>
              <div>
                <PersonalizedCompatibilityI />
              </div>
              <div className={styles.textContainer}>
                <h3>
                  Unlock
                  <span className="yellow-text">
                    {" "}
                    Personalized Compatibility{" "}
                  </span>
                  Insights
                </h3>
                <p>
                  Discover unique connections with a detailed report tailored to
                  your specifications.
                </p>
              </div>
            </div>
            <div className={styles.unlockBtnContainer}>
              <button
                className="linkBtn pointer"
                onClick={() => navigate("/full-compatibility-report")}
              >
                Unlock Insights
                <BigArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.biorhythmsContainer}>
          <h2 className="pointer" onClick={navigateToBiorhythmsReports}>
            Biorhythms
          </h2>

          <div
            className={"flex i-align-c mt16 mb24 gap8"}
            style={{ height: 94 }}
            ref={BiorhythmsChartsRef}
          >
            {biorhythmsChartsView &&
              biorhythms.map((item) => (
                <div
                  key={item.id}
                  className={classNames(
                    "pointer",
                    styles.biorhythmsItemContainer,
                  )}
                  onClick={navigateToBiorhythmsReports}
                >
                  <p className={styles.title}>{item.title}</p>
                  <div className={styles.pieContainer}>
                    <div className={styles.container}>
                      <Pie
                        data={{
                          datasets: [
                            {
                              data: [100 - item.value, item.value],
                              backgroundColor: ["#122133", item.color],
                              borderWidth: 0,
                            },
                          ],
                        }}
                      />
                    </div>
                    <p className={styles.text}>{item.value}%</p>
                  </div>
                </div>
              ))}
          </div>
          <div
            className={classNames(styles.radarContainer, "pointer")}
            ref={elemRef2}
            onClick={navigateToBiorhythmsReports}
          >
            {inView2 && (
              <RadarChart
                width={400}
                height={180}
                cx={200}
                cy={90}
                outerRadius={65}
                data={data}
              >
                <PolarGrid
                  gridType="circle"
                  stroke={"#27394B"}
                  polarRadius={[65]}
                  strokeWidth={2}
                />
                <PolarAngleAxis
                  tick={{ fill: "#E5E5E5" }}
                  dataKey="subject"
                  fontFamily={"Montserrat"}
                  fontSize={10}
                  fontWeight={600}
                />
                <Radar
                  name="Lily"
                  dataKey="B"
                  strokeWidth={2}
                  stroke="#27394B"
                  fill="rgba(90, 140, 141, 0.60)"
                  fillOpacity={0.6}
                />
              </RadarChart>
            )}
          </div>
          <DefaultButton
            text={"Read More"}
            onClick={navigateToBiorhythmsReports}
          />
        </div>
        <div className={styles.tarotReadingContainer}>
          <h2 className={"t-align-l"}>
            <span className={"yellow-text"}>Tarot</span> reading
          </h2>
          <div
            className={classNames(
              "position-relative flex mt24 i-align-c",
              styles.cardsContainer,
            )}
          >
            {tarotResult.map((card, index) => (
              <FlippedTarotCard
                key={index}
                card={card.card}
                cardId={card.cardTitle}
                isCardFlipped={card.isCardFlipped}
                onClick={toggleCard}
              />
            ))}
          </div>
          {isCardsFlipped && (
            <div className={"mt16 flex i-align-c"}>
              <button
                className="linkBtn"
                onClick={() => navigate("/tarot-reading-result")}
              >
                See your recent result <BigArrowRightIcon />
              </button>
            </div>
          )}
          <DefaultButton
            customStyles={"mt32"}
            text={isCardsFlipped ? "Get another reading" : "Get reading"}
            onClick={() => {
              isCardsFlipped ? navigate("/tarot-reading") : toggleAllCards();
            }}
          />
        </div>
        <BottomModalSheet
          isModalOpen={isModalOpen}
          setOpen={openModal}
          data={modalData}
        />
        <UserNameModal
          isOpen={isUserNameModalOpen}
          toggleModal={toggleUserNameModal}
        />
        <CustomBottomNavigation />
      </div>
    </Layout>
  );
};

export default HomePage;
