import React, { forwardRef, ReactNode } from "react";
import styles from "./ElBgContainer.module.scss";
import classNames from "classnames";

interface ElBgContainerProps {
  children: ReactNode;
  customClassNames?: string;
}

const ElBgContainer = forwardRef<HTMLDivElement, ElBgContainerProps>(
  (props, forwardedRef) => {
    const customContainerClassNames = classNames(
      "flex flex-column i-align-c gap12 default-border",
      styles.container,
      props.customClassNames,
    );
    return (
      <div className={customContainerClassNames} ref={forwardedRef}>
        {props.children}
      </div>
    );
  },
);

ElBgContainer.displayName = "ElBgContainer";

export default ElBgContainer;
