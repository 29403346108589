import React, { FC, useState } from "react";
import styles from "./InfoCard.module.scss";
import classNames from "classnames";
import ElBgContainer from "components/containers/ElBgContainer/ElBgContainer";
import ArrowDown from "assets/svg/arrow-down.svg";
import CustomZodiacCard from "components/CustomZodiacCard/CustomZodiacCard";

type InfoCardProps = {
  title: string;
  btnColor?: string;
  btnTitle?: string;
  cardText: string;
  customContainerStyles: string;
  zodiacs: { id: number; zodiac: string; iconUrl: string }[];
};
const InfoCard: FC<InfoCardProps> = ({
  title,
  btnColor,
  btnTitle,
  cardText,
  customContainerStyles,
  zodiacs,
}) => {
  const [readMoreHeight, setReadMoreHeight] = useState<number | string>(58);

  return (
    <ElBgContainer customClassNames={customContainerStyles}>
      <div
        className={classNames(
          styles.cardHeader,
          "flex justify-content-space-between i-align-s",
        )}
      >
        <h3 className="yellow-text t-align-l">{title}</h3>
        {btnTitle && (
          <div
            style={{ backgroundColor: btnColor }}
            className={classNames(
              styles.colorLabel,
              "text-color-silver text-uppercase font-s10 font-w600",
            )}
          >
            {btnTitle}
          </div>
        )}
      </div>
      <div className="flex gap12">
        {zodiacs.map((item, index) => (
          <CustomZodiacCard
            customStyles="gap4"
            cardWidth={50}
            cardHeight={50}
            iconWidth={28}
            iconHeight={28}
            iconUrl={item.iconUrl}
            title={item.zodiac}
            key={index}
            customTitleStyles="font-s6"
          />
        ))}
      </div>
      <div
        className={classNames(
          styles.textContainer,
          "fRoboto font-s16 font-w400 position-relative text-color-silver",
        )}
        style={{
          height: readMoreHeight,
        }}
      >
        <p>{cardText}</p>
        {readMoreHeight === 58 && (
          <div
            className={classNames(
              styles.textGradient,
              styles.textGradientInCard,
            )}
          />
        )}
      </div>
      <div className={styles.overviewBtnWrapper}>
        {readMoreHeight === 58 && (
          <button
            type="button"
            className="linkBtn fMontserrat font-s16 font-w500 flex i-align-c gap4"
            onClick={() => setReadMoreHeight("auto")}
          >
            Read more
            <img src={ArrowDown} alt={""} />
          </button>
        )}
      </div>
    </ElBgContainer>
  );
};

export default InfoCard;
