import React from "react";
import GenderCard from "./GenderCard";
import { Gender, gendersList } from "constants/genders";
import { useAppDispatch, useAppSelector } from "store";
import { setGenderType } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Logo from "../../../../assets/svg/logoIcon.svg";

const GenderSelect = () => {
  const dispatch = useAppDispatch();
  const userGender = useAppSelector((state) => state.registration.gender);

  const onSetGenderType = (type: string) => {
    ReactGA.event("genderTypeSelected");
    ReactPixel.trackCustom("genderTypeSelected");
    dispatch(setGenderType(type));
  };

  return (
    <>
      <div>
        <img src={Logo} alt="" />
      </div>
      <div className="titleContainer" style={{ marginTop: 40 }}>
        <h2 className="title">
          Custom
          <span className="yellow-text"> Astrology Forecast</span>
          with Detailed Predictions
        </h2>
      </div>
      <p className="fRoboto font-s14 subtitle-text font-w400">
        Fill out a brief, 1-minute questionnaire for personalized astrological
        insights. Predictions may vary and are not guaranteed for every
        individual.
      </p>
      <div className="mt40">
        <p className="text-uppercase font-w600 fMontserrat font-s10 text-white">
          Select your gender to start
        </p>
        <div className="flex i-align-s gap12 mt16">
          {gendersList.map((gender: Gender) => (
            <GenderCard
              setGenderType={onSetGenderType}
              type={gender.type}
              icon={gender.icon}
              isSelected={userGender === gender.type}
              key={gender.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GenderSelect;
