import moment from "moment";
import { shortMonthMap } from "../constants/date";

export const createDateFormat = (month: string, day: string, year: string) => {
  month = month.toLowerCase();
  const date = moment([year, shortMonthMap[month], day]);

  return date.format("MM/DD/YYYY");
};

export const getYesterday = () => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - 1));
};
