import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "helpers/http";

export type TarotCardData = {
  card: string;
  cardName: string;
  cardDescription: string;
  isCardFlipped: boolean;
  cardTitle: string;
};

type TarotReadingState = {
  tarotReadingResult: TarotCardData[];
};

const initialState: TarotReadingState = {
  tarotReadingResult: [],
};

export const fetchTarotReading = createAsyncThunk<any>(
  "tarotReading/fetch",
  async (): Promise<any> => {
    const res = await http.get("/astro/tarot-reading");
    return res;
  },
);

export const tarotReadingSlice = createSlice({
  name: "tarotReading",
  initialState,
  reducers: {
    setTarotReadingResult: (state, action) => {
      state.tarotReadingResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTarotReading.fulfilled, (state, action) => {
      state.tarotReadingResult = [
        {
          card: action.payload.astrologicData.prediction.message.card1_image,
          cardName: action.payload.astrologicData.prediction.message.card1,
          cardDescription:
            action.payload.astrologicData.prediction.message.future,
          cardTitle: "Future",
          isCardFlipped: false,
        },
        {
          card: action.payload.astrologicData.prediction.message.card2_image,
          cardName: action.payload.astrologicData.prediction.message.card2,
          cardDescription:
            action.payload.astrologicData.prediction.message.past,
          cardTitle: "Past",
          isCardFlipped: false,
        },
        {
          card: action.payload.astrologicData.prediction.message.card3_image,
          cardName: action.payload.astrologicData.prediction.message.card3,
          cardDescription:
            action.payload.astrologicData.prediction.message.present,
          cardTitle: "Present",
          isCardFlipped: false,
        },
      ];
    });
  },
});

export const { setTarotReadingResult } = tarotReadingSlice.actions;

export default tarotReadingSlice.reducer;
