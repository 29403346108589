import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../store";
import { isAuth } from "../helpers/localStorageHelpers";
import moment from "moment";
import { setPaymentLocation } from "../helpers/sessionStorageHelpers";

type ProtectedRouteProps = {
  redirectTo: string;
  children: any;
};
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectTo = "/login",
  children,
}) => {
  const { userData } = useAppSelector((state) => state.user);
  const { expirationDate } = userData;

  const today = moment();

  if (isAuth() && today.isSameOrAfter(moment(expirationDate))) {
    setPaymentLocation("login");
    return <Navigate to="/payment" />;
  } else if (!isAuth()) {
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
