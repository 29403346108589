import React, { FC, ReactNode } from "react";
import AnimatedBg from "./AnimatedBg";

type LayoutProps = {
  children: ReactNode;
};
const Layout: FC<LayoutProps> = ({ children }) => (
  <>
    <AnimatedBg />
    {children}
  </>
);

export default Layout;
