import { createSlice } from "@reduxjs/toolkit";

type LoaderState = {
  isLoading: boolean;
};

const initialState: LoaderState = {
  isLoading: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    clearLoaderState: () => {
      return initialState;
    },
  },
});

export const { startLoading, stopLoading, clearLoaderState } =
  loaderSlice.actions;

export default loaderSlice.reducer;
