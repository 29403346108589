import React from "react";
import Lottie from "lottie-react";
import scrollDown from "./scroll-down.json";

const ScrollDown = () => (
  <Lottie
    animationData={scrollDown}
    loop
    style={{
      width: "200px",
      position: "absolute",
      margin: "auto",
      left: 0,
      right: 0,
      top: -24,
    }}
  />
);

export default ScrollDown;
