import React, { JSX } from "react";
import HeartIcon from "assets/svg/purchases/heart.svg";
import SunIcon from "assets/svg/purchases/sun.svg";
import PersonalConnectionIcon from "assets/svg/purchases/personal-connection.svg";

interface Purchase {
  iconUrl: string;
  text: () => JSX.Element;
  type: string;
}

export const purchasesData: Purchase[] = [
  {
    type: "romantic",
    iconUrl: HeartIcon,
    text: () => (
      <h3 className="font-s20">
        <span className="yellow-text">Romantic</span> Compatibility
      </h3>
    ),
  },
  {
    type: "friendship",
    iconUrl: SunIcon,
    text: () => (
      <h3 className="font-s20">
        <span className="yellow-text">Friendship</span> Compatibility
      </h3>
    ),
  },
  {
    type: "personalConnection",
    iconUrl: PersonalConnectionIcon,
    text: () => (
      <h3 className="font-s20">
        Personal <span className="yellow-text">Connection</span>
      </h3>
    ),
  },
];
