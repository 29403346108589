import React, { memo, useEffect } from "react";
import * as THREE from "three";

// eslint-disable-next-line react/display-name
const AnimatedBg = memo(() => {
  let scene;
  let camera;
  let renderer;
  let stars;
  let starGeo;

  renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(0x122132);

  useEffect(() => {
    const init = () => {
      // Scene
      scene = new THREE.Scene();

      // Camera
      camera = new THREE.PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      camera.position.z = 1;
      camera.rotation.x = Math.PI / 2;

      document
        .getElementById("three-container")
        .appendChild(renderer.domElement);
      // Stars
      starGeo = new THREE.BufferGeometry();
      const vertices = [];
      for (let i = 0; i < 6000; i++) {
        const star = new THREE.Vector3();
        star.x = THREE.MathUtils.randFloatSpread(1000);
        star.y = THREE.MathUtils.randFloatSpread(1000);
        star.z = THREE.MathUtils.randFloatSpread(1000);
        vertices.push(star);
      }
      starGeo.setFromPoints(vertices);

      const starMaterial = new THREE.PointsMaterial({
        color: 0xaaaaaa,
        size: 0.7,
        map: new THREE.TextureLoader().load(
          "https://uploads-ssl.webflow.com/64d49de0f7621e705758450e/658045c415365d4a4cac9e53_0_0%20(2)%20(1).webp",
        ),
        blending: THREE.AdditiveBlending,
        transparent: true,
      });

      stars = new THREE.Points(starGeo, starMaterial);
      scene.add(stars);

      animate();
    };

    const animate = () => {
      stars.rotation.y += 0.0001;
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", onWindowResize, false);

    init();

    // Clean up
    return () => {
      window.removeEventListener("resize", onWindowResize, false);
      // Additional clean-up if needed
    };
  }, []);

  return <div id="three-container" />;
});

export default AnimatedBg;
