import React, { useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import CoffeeCupIcon from "assets/svg/coffee-cup-reading";
import styles from "./CoffeeCupReading.module.scss";
import http from "../../helpers/http";
import { useAppDispatch } from "../../store";
import { setCoffeeReadingData } from "store/slices/coffeeReading";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
const CoffeeCupReading = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedCups, setSelectedCups] = useState(0);

  useEffect(() => {
    ReactPixel.track("Coffee Cup Reading Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/coffee-cup-reading",
      title: "Coffee Cup Reading Page",
    });
  }, []);

  const onSelectCoffeeCup = (event: React.MouseEvent<HTMLDivElement>) => {
    if (selectedCups < 3) {
      if (event.currentTarget) {
        event.currentTarget.classList.add(styles.selectedItem);
      }
      setSelectedCups((prev) => prev + 1);
    }
  };

  const onReadCoffeeCup = async () => {
    const res = await http.get("/astro/coffe-reading");
    dispatch(setCoffeeReadingData(res));
    navigate("/coffee-cup-reading-result");
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div>
            <h1 className={"t-align-l"}>Coffee Cup Reading</h1>
            <div
              className={classNames(
                "flex flex-wrap mt24",
                styles.itemsContainer,
              )}
            >
              {Array.from({ length: 6 }, (_, index) => (
                <div
                  key={index}
                  className={classNames(
                    styles.itemContainer,
                    "flex i-align-c border-radius12 default-border pointer",
                  )}
                  onClick={onSelectCoffeeCup}
                >
                  <CoffeeCupIcon />
                </div>
              ))}
            </div>
            <div className="mt32">
              <h2 style={{ textAlign: "center" }} className={"mt32"}>
                Choose <span className={"yellow-text"}>three cards</span>
              </h2>
              <h2 style={{ textAlign: "center" }} className={"mt16"}>
                {selectedCups}/3
              </h2>
            </div>
          </div>
          <div className={"text bottom-container-absolute"}>
            <DefaultButton
              text="Get reading"
              type="button"
              isDisabled={selectedCups !== 3}
              onClick={onReadCoffeeCup}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoffeeCupReading;
