export const animate = (
  elem: HTMLElement,
  animation: string,
): Promise<HTMLElement> => {
  return new Promise<HTMLElement>((resolve, reject) => {
    if (!(elem instanceof HTMLElement)) {
      reject(new TypeError("elem must be an HTMLElement"));
      return;
    }
    function handleAnimationEnd() {
      console.log("animation ended...");
      resolve(elem);
    }

    elem.addEventListener("animationend", handleAnimationEnd, { once: true });
    elem.classList.add(animation);
  });
};

export const animateElements = async (
  elements: { elem: HTMLElement; animation: string }[],
): Promise<void> => {
  try {
    const animationPromises = elements.map(({ elem, animation }) =>
      animate(elem, animation),
    );

    await Promise.all(animationPromises);
    console.log("Both animations finished!");
  } catch (error) {
    console.error("Animation error:", error);
  }
};
