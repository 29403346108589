import React from "react";
const InstagramIcon = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" fill="#122133" />
    <rect x="1" y="1.37195" width="31" height="31" rx="7.5" stroke="#27394B" />
    <path
      d="M21.75 7.87195H11.25C9.18 7.87195 7.5 9.51954 7.5 11.5496V21.847C7.5 23.8771 9.18 25.5247 11.25 25.5247H21.75C23.82 25.5247 25.5 23.8771 25.5 21.847V11.5496C25.5 9.51954 23.82 7.87195 21.75 7.87195ZM24 21.847C24 23.0606 22.9875 24.0536 21.75 24.0536H11.25C10.0125 24.0536 9 23.0606 9 21.847V11.5496C9 10.336 10.0125 9.34301 11.25 9.34301H21.75C22.9875 9.34301 24 10.336 24 11.5496V21.847Z"
      fill="#E5E5E5"
    />
    <path
      d="M16.5 11.5496C13.605 11.5496 11.25 13.8592 11.25 16.6983C11.25 19.5375 13.605 21.847 16.5 21.847C19.395 21.847 21.75 19.5375 21.75 16.6983C21.75 13.8592 19.395 11.5496 16.5 11.5496ZM16.5 20.376C14.43 20.376 12.75 18.7284 12.75 16.6983C12.75 14.6683 14.43 13.0207 16.5 13.0207C18.57 13.0207 20.25 14.6683 20.25 16.6983C20.25 18.7284 18.57 20.376 16.5 20.376Z"
      fill="#E5E5E5"
    />
    <path
      d="M22.5 11.5496C22.5 11.9558 22.1642 12.2851 21.75 12.2851C21.3358 12.2851 21 11.9558 21 11.5496C21 11.1433 21.3358 10.814 21.75 10.814C22.1642 10.814 22.5 11.1433 22.5 11.5496Z"
      fill="#E5E5E5"
    />
  </svg>
);

export default InstagramIcon;
