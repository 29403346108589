import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../views/Login/Login";
import ConfirmCode from "../views/ConfirmCode/ConfirmCode";
import Registration from "../views/Registration/Registration";
import LoadPage from "../views/LoadPage/LoadPage";
import PaymentPage from "../views/PaymentPage/PaymentPage";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy";
import DeletedAccount from "../views/DeletedAccount/DeletedAccount";
import HomePage from "../views/HomePage/HomePage";
import LuckyNumberPage from "../views/LuckyNumberPage/LuckyNumberPage";
import LuckyLettersPage from "../views/LuckyLettersPage/LuckyLettersPage";
import LuckyColorPage from "../views/LuckyColorPage/LuckyColorPage";
import BiorhythmsReportsPage from "../views/BiorhythmsReportsPage/BiorhythmsReportsPage";
import LoveCompatibilitySelectPage from "../views/LoveCompatibilitySelectPage/LoveCompatibilitySelectPage";
import LoveCompatibilityResultPage from "../views/LoveCompatibilityResultPage/LoveCompatibilityResultPage";
import TarotReadingPage from "../views/TarotReadingPage/TarotReadingPage";
import TarotReadingResultPage from "../views/TarotReadingResultPage/TarotReadingResultPage";
import CoffeeCupReadingResult from "../views/CoffeeCupReadingResult/CoffeeCupReadingResult";
import CoffeeCupReading from "../views/CoffeeCupReading/CoffeeCupReading";
import ReadingPage from "../views/ReadingPage/ReadingPage";
import FortuneCookiePage from "../views/FortuneCookiePage/FortuneCookiePage";
import YesOrNoPage from "../views/YesOrNoPage/YesOrNoPage";
import YesOrNoDetailPage from "../views/YesOrNoDetailPage/YesOrNoDetailPage";
import CompatibilityPage from "../views/CompatibilityPage/CompatibilityPage";
import TwelveSignsPage from "../views/TwelveSignsPage/TwelveSignsPage";
import BestMatchesReport from "../views/BestMatchesReport/BestMatchesReport";
import FullCompatibilityReport from "../views/FullCompatibilityReport/FullCompatibilityReport";
import Purchase from "../views/PurchasePage/Purchase";
import PartnerDetailsPage from "../views/PartnerDetailsPage/PartnerDetailsPage";
import RomanticDetailPage from "../views/RomanticDetailPage/RomanticDetailPage";
import FriendshipDetailPage from "../views/FriendshipDetailPage/FriendshipDetailPage";
import PersonalConnectionDetailPage from "../views/PersonalConnectionDetailPage/PersonalConnectionDetailPage";
import BirthCartPage from "../views/BirthCartPage/BirthCartPage";
import TwelveHoursPage from "../views/TwelveHoursPage/TwelveHoursPage";
import PalmReadingPage from "../views/PalmReadingPage/PalmReadingPage";
import Settings from "../views/Settings/Settings";
import ChangeSubscription from "../views/ChangeSubscription/ChangeSubscription";
import CancelSubscriptionTooExpensive from "../views/CancelSubscriptionTooExpensive/CancelSubscriptionTooExpensive";
import CanceledSubscription from "../views/CanceledSubscription/CanceledSubscription";
import CancelSubscriptionNotExcited from "../views/CancelSubscriptionNotExcited/CancelSubscriptionNotExcited";
import CancelSubscriptionTechnicalProblems from "../views/CancelSubscriptionTechnicalProblems/CancelSubscriptionTechnicalProblems";
import PalmScanInstructionPage from "../views/PalmScanInstructionPage/PalmScanInstructionPage";
import PalmPhotoAndAnalyzing from "../views/PalmPhotoAndAnalyzg/PalmPhotoAndAnalyzing";
import AppPaymentPage from "../views/AppPaymentPage/AppPaymentPage";
import ProfilePage from "../views/ProfilePage/ProfilePage";
import EditProfilePage from "../views/EditProfilePage/EditProfilePage";
import AnimationPage from "../views/AnimationPage/AnimationPage";

const privateRoutes = [
  {
    path: "/",
    redirectTo: "/login",
    element: <Navigate to="/home" replace />,
  },
  {
    path: "/home",
    redirectTo: "/login",
    element: <HomePage />,
  },
  {
    path: "/lucky-numbers",
    redirectTo: "/login",
    element: <LuckyNumberPage />,
  },
  {
    path: "/lucky-letters",
    redirectTo: "/login",
    element: <LuckyLettersPage />,
  },
  {
    path: "/lucky-color",
    redirectTo: "/login",
    element: <LuckyColorPage />,
  },
  {
    path: "/biorhythms-reports",
    redirectTo: "/login",
    element: <BiorhythmsReportsPage />,
  },
  {
    path: "/love-compatibility-select",
    redirectTo: "/login",
    element: <LoveCompatibilitySelectPage />,
  },
  {
    path: "/love-compatibility-result",
    redirectTo: "/login",
    element: <LoveCompatibilityResultPage />,
  },
  {
    path: "/tarot-reading",
    redirectTo: "/login",
    element: <TarotReadingPage />,
  },
  {
    path: "/tarot-reading-result",
    redirectTo: "/login",
    element: <TarotReadingResultPage />,
  },
  {
    path: "/coffee-cup-reading-result",
    redirectTo: "/login",
    element: <CoffeeCupReadingResult />,
  },
  {
    path: "/coffee-cup-reading",
    redirectTo: "/login",
    element: <CoffeeCupReading />,
  },
  {
    path: "/reading",
    redirectTo: "/login",
    element: <ReadingPage />,
  },
  {
    path: "/fortune-cookie",
    redirectTo: "/login",
    element: <FortuneCookiePage />,
  },
  {
    path: "/yes-or-no",
    redirectTo: "/login",
    element: <YesOrNoPage />,
  },
  {
    path: "/yes-or-no-details",
    redirectTo: "/login",
    element: <YesOrNoDetailPage />,
  },
  {
    path: "/compatibility",
    redirectTo: "/login",
    element: <CompatibilityPage />,
  },
  {
    path: "/twelve-signs",
    redirectTo: "/login",
    element: <TwelveSignsPage />,
  },
  {
    path: "/best-matches-report",
    redirectTo: "/login",
    element: <BestMatchesReport />,
  },
  {
    path: "/full-compatibility-report",
    redirectTo: "/login",
    element: <FullCompatibilityReport />,
  },
  {
    path: "/purchases",
    redirectTo: "/login",
    element: <Purchase />,
  },
  {
    path: "/partner-details",
    redirectTo: "/login",
    element: <PartnerDetailsPage />,
  },
  {
    path: "/romantic-detail",
    redirectTo: "/login",
    element: <RomanticDetailPage />,
  },
  {
    path: "/friendship-detail",
    redirectTo: "/login",
    element: <FriendshipDetailPage />,
  },
  {
    path: "/personal-connection-detail",
    redirectTo: "/login",
    element: <PersonalConnectionDetailPage />,
  },
  {
    path: "/birth-chart",
    redirectTo: "/login",
    element: <BirthCartPage />,
  },
  {
    path: "/twelve-houses",
    redirectTo: "/login",
    element: <TwelveHoursPage />,
  },
  {
    path: "/palm-reading",
    redirectTo: "/login",
    element: <PalmReadingPage />,
  },
  {
    path: "/settings",
    redirectTo: "/login",
    element: <Settings />,
  },
  {
    path: "/change-subscription",
    redirectTo: "/login",
    element: <ChangeSubscription />,
  },
  {
    path: "/cancel-subscription-discount",
    redirectTo: "/login",
    element: <CancelSubscriptionTooExpensive />,
  },
  {
    path: "/cancel-subscription-not-excited",
    redirectTo: "/login",
    element: <CancelSubscriptionNotExcited />,
  },
  {
    path: "/cancel-subscription-technical-problems",
    redirectTo: "/login",
    element: <CancelSubscriptionTechnicalProblems />,
  },
  {
    path: "/palm-scan-instruction",
    redirectTo: "/login",
    element: <PalmScanInstructionPage />,
  },
  {
    path: "/palm-scan-and-analyzing",
    redirectTo: "/login",
    element: <PalmPhotoAndAnalyzing />,
  },
  {
    path: "/app-payment",
    redirectTo: "/login",
    element: <AppPaymentPage />,
  },
  {
    path: "/profile",
    redirectTo: "/login",
    element: <ProfilePage />,
  },
  {
    path: "/edit-profile",
    redirectTo: "/login",
    element: <EditProfilePage />,
  },
];
const AppRoutes = () => {
  // const { isLoading } = useAppSelector((state) => state.loader);
  return (
    <>
      <BrowserRouter>
        <Routes>
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute redirectTo={route.redirectTo}>
                  {route.element}
                </ProtectedRoute>
              }
            />
          ))}
          <Route path="/login" element={<Login />} />
          <Route path="/confirm-email" element={<ConfirmCode />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/load" element={<LoadPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/account-deleted" element={<DeletedAccount />} />
          <Route path="/analysing" element={<AnimationPage />} />
          <Route
            path="/cancelled-subscription"
            element={<CanceledSubscription />}
          />
        </Routes>
      </BrowserRouter>
      {/*{!isLoading && <Loader />}*/}
    </>
  );
};

export default AppRoutes;
