import Layout from "components/Layout/Layout";
import React, { ChangeEvent, useEffect, useState } from "react";
import HeaderWithBack from "components/headers/HeaderWithBack";
import TarotCard from "assets/svg/plan-icons/tarot.svg";
import ZodiacLove from "assets/svg/plan-icons/zodiac-love.svg";
import PalmReading from "assets/svg/plan-icons/palm-reading.svg";
import ZodiacSun from "assets/svg/plan-icons/zodiac-sun.svg";
import PersonWithMoon from "assets/svg/plan-icons/person-with-moon.svg";

import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import "./styles.scss";
import { AutoPlay } from "@egjs/flicking-plugins";
import BigArrowRightIcon from "assets/svg/big-arrow-right";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import CloseBtnIcon from "assets/svg/close-btn";
import { addClassToHiddenModalDiv } from "utils/defaults";
import ArrowLeft from "assets/svg/arrow left.svg";
import ArrowRight from "assets/svg/arrow right.svg";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import http from "helpers/http";
import { useAppSelector } from "store";

const plansData = [
  {
    iconUrl: TarotCard,
    title: "Insight with Tarot",
    redirect: "/tarot-reading",
  },
  {
    iconUrl: ZodiacLove,
    title: "Zodiac Compatibility",
    redirect: "/compatibility",
  },
  {
    iconUrl: PalmReading,
    title: "Palmistry Insights",
    redirect: "/palm-reading",
  },
  {
    iconUrl: ZodiacSun,
    title: "Daily Astrological Forecasts",
    redirect: "/birth-chart",
  },
  {
    iconUrl: PersonWithMoon,
    title: "Natal Chart Analysis",
    redirect: "/",
  },
];

const ChangeSubscription = () => {
  const navigate = useNavigate();
  const [isCancelBottomModalOpen, toggleCancelBottomModal] = useState(false);
  const [reasonForCancellationType, setReasonType] = useState("");
  const { userData } = useAppSelector((state) => state.user);
  const [otherText, setOtherText] = useState("");

  const plugins = [
    new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false }),
  ];

  useEffect(() => {
    addClassToHiddenModalDiv();
    ReactPixel.track("Change Subscription Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/change-subscription",
      title: "Change Subscription Page",
    });
    window.scrollTo(0, 0);
  }, []);

  const onSelectReasonType = (reason: string) => {
    if (reason === "tooExpensive") {
      navigate("/cancel-subscription-discount", {
        state: { from: "subscription", deleteReason: "too expensive" },
      });
    } else if (reason === "notExcited") {
      navigate("/cancel-subscription-not-excited", {
        state: {
          from: "subscription",
          deleteReason: "Not excited with provided services",
        },
      });
    } else if (reason === "technicalProblems") {
      navigate("/cancel-subscription-technical-problems", {
        state: {
          from: "subscription",
          deleteReason: "I have technical problems",
        },
      });
    } else {
      setReasonType(reason);
    }
  };

  const onChangeOtherText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherText(event.target.value);
  };

  const onClickSubmit = async () => {
    await http.post("/payment/cancel-subscription", {
      email: userData.email,
      reason: otherText,
    });
    navigate("/cancelled-subscription");
  };

  const headerCancelBottomModal = () => {
    return (
      <div className="flex i-align-s justify-content-space-between mb24">
        {reasonForCancellationType === "other" ? (
          <h2>
            Please share your
            <br /> reason for cancellation
          </h2>
        ) : (
          <h2>
            Please select the
            <br /> reason for cancellation
          </h2>
        )}
        <div
          className="flex i-align-c pointer"
          style={{ paddingTop: 4 }}
          onClick={() => {
            setReasonType("");
            toggleCancelBottomModal((prev) => !prev);
          }}
        >
          <CloseBtnIcon />
        </div>
      </div>
    );
  };

  const contentCancelBottomModal = () => {
    return (
      <>
        {reasonForCancellationType === "other" ? (
          <>
            <textarea
              wrap={"hard"}
              placeholder="Share your cancellation reason..."
              onChange={onChangeOtherText}
            />
          </>
        ) : (
          <>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 pointer"
              onClick={() => onSelectReasonType("tooExpensive")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Too expensive
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("notExcited")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Not excited with provided services
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("technicalProblems")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                I have technical problems
              </span>
            </div>
            <div
              className="default-border border-radius8 padding-h16 padding-v12 mt8 pointer"
              onClick={() => onSelectReasonType("other")}
            >
              <span className="text-color-silver font-s18 fRoboto font-w700">
                Other
              </span>
            </div>
          </>
        )}
        {reasonForCancellationType && (
          <div
            className=" mt16 flex position-absolute"
            style={{ bottom: 32, left: 0 }}
          >
            <button
              className="linkBtn font-s13 fMontserrat font-w500"
              onClick={() => setReasonType("")}
            >
              <img src={ArrowLeft} alt="" /> Back
            </button>
          </div>
        )}
        <div className=" mt16 flex i-align-self-end">
          <button
            className="linkBtn font-s13 fMontserrat font-w500"
            onClick={onClickSubmit}
          >
            {reasonForCancellationType === "other" ? "Submit " : "Skip "}
            <img src={ArrowRight} alt="" />
          </button>
        </div>
      </>
    );
  };

  const onClickCancelSubscription = () =>
    toggleCancelBottomModal((prev) => !prev);

  const navigateToPayment = () => navigate("/app-payment");

  return (
    <Layout>
      <div className="app-container">
        <div className="padding-content-h mb24 fRoboto text-color-silver font-s18 font-w400 t-align-l">
          <HeaderWithBack />
          <h1 className="yellow-text mb24">Change Subscription</h1>
        </div>
        <div className="bg-el-transparent padding-v24 font-s18 font-w400 flex flex-column padding-content-h font-w400 fMontserrat text-white i-align-c">
          <h3 className="mb16">Your Current Plan</h3>
          <div
            style={{
              width: 223,
              borderBottom: "1px solid #27394B",
              padding: "6px 0",
            }}
            className="flex justify-content-space-between text-uppercase font-s10"
          >
            <span className="yellow-text">Your plan status:</span>
            <span>Active</span>
          </div>
          <div
            style={{
              width: 223,
              borderBottom: "1px solid #27394B",
              padding: "6px 0",
            }}
            className="flex justify-content-space-between text-uppercase font-s10"
          >
            <span className="yellow-text">Billing period:</span>
            <span>2 weeks</span>
          </div>
          <div
            style={{
              width: 223,
              borderBottom: "1px solid #27394B",
              padding: "6px 0",
            }}
            className="flex justify-content-space-between text-uppercase font-s10 mb16"
          >
            <span className="yellow-text">Price:</span>
            <span>$1.43/day</span>
          </div>
        </div>
        <div className="padding-content-h mt32 text-white font-s14 font-w600 overflow-hidden">
          <h3 className="mb24">
            What is included in your <br /> current plan?
          </h3>
          <div className="subscriptions-flicking">
            <Flicking
              circular
              defaultIndex={1}
              autoResize={true}
              plugins={plugins}
            >
              {plansData.map((item, index) => (
                <div
                  className="default-border border-radius12 padding-h18 padding-v16 primary-bg"
                  style={{
                    width: 163,
                    margin: "0 4px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  key={index}
                  onClick={() => navigate(item.redirect)}
                >
                  <div>
                    <img src={item.iconUrl} alt="" />
                    <p className="fMontserrat font-s14 text-color-silver mt8">
                      {item.title}
                    </p>
                  </div>
                  <div className="flex mt8 justify-content-end">
                    <button
                      className="linkBtn font-s13 fMontserrat font-w500"
                      onClick={() => console.log("click")}
                    >
                      Read <BigArrowRightIcon />
                    </button>
                  </div>
                </div>
              ))}
            </Flicking>
          </div>
          <DefaultButton
            text="Change the plan"
            customStyles="mt32"
            onClick={navigateToPayment}
          />
          <DefaultButton
            text="Cancel Subscription"
            customStyles="blue-border primary-bg text-color-silver mt24 mb40"
            onClick={onClickCancelSubscription}
          />
        </div>
        <CustomHeightBottomSheet
          header={headerCancelBottomModal}
          isModalOpen={isCancelBottomModalOpen}
          setOpen={() => console.log("open")}
          content={contentCancelBottomModal}
          modalHeight={400}
        />
      </div>
    </Layout>
  );
};

export default ChangeSubscription;
