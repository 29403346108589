import Layout from "components/Layout/Layout";
import React, { useEffect } from "react";
import DefaultButton from "components/buttons/DefaultButton";
import { useNavigate } from "react-router-dom";
import HeaderWithHome from "components/headers/HeaderWithHome";
import Woman from "assets/svg/delete acc.svg";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const DeletedAccount = () => {
  const navigate = useNavigate();

  const onClickSubscribe = () => {
    navigate("/settings");
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/account-deleted",
      title: "Account Deleted Page",
    });
    ReactPixel.track("Account Deleted Page");
  }, []);

  const onClickRestoreSubscription = () => {
    navigate("/");
  };

  return (
    <Layout>
      <div className="container">
        <HeaderWithHome />
        <div className="position-relative" style={{ height: "94%" }}>
          <h2 className="yellow-text">
            Your Account Has Been Successfully Deleted
          </h2>
          <p className="fRoboto text-white font-w400 font-s18 mt8">
            Would you like to stay updated on new features, updates, and special
            offers?
          </p>
          <img src={Woman} alt="" className="mt32" />
          <div className="bottom-container-absolute">
            <DefaultButton
              text="Subscribe For Updates"
              onClick={onClickSubscribe}
            />
            <DefaultButton
              text="Restore Account"
              customStyles="blue-border primary-bg text-color-silver mt24 mb40"
              onClick={onClickRestoreSubscription}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeletedAccount;
