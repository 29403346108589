import React from "react";

const Ellipse = ({ color }: { color: string }) => (
  <svg
    width="27"
    height="13"
    viewBox="0 0 27 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Ellipse 207"
      d="M26.6133 6.05597C26.6133 12.7046 20.793 12.0309 13.6133 12.0309C6.43358 12.0309 0.613278 12.7039 0.613281 6.05597C0.613284 -0.739988 6.43358 0.0810846 13.6133 0.0810846C20.793 0.0810846 26.6133 -0.739642 26.6133 6.05597Z"
      fill={color}
    />
  </svg>
);

export default Ellipse;
