import React from "react";
const ShieldIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.38693C11.3614 3.38693 9.32998 1.90946 8.09051 0.669995C8.06551 0.644995 8.03275 0.633225 7.99999 0.633745C7.96723 0.633224 7.93447 0.644995 7.90947 0.669995C6.66999 1.90948 4.63853 3.38693 2 3.38693C1.93099 3.38693 1.875 3.44292 1.875 3.51193V6.64446C1.875 9.061 2.89527 11.1822 4.82527 12.7787L7.91807 15.3375C7.9413 15.357 7.96958 15.3663 7.99755 15.3663C7.99827 15.3663 7.99906 15.3658 7.99978 15.3658C8.00051 15.3658 8.0013 15.3663 8.00202 15.3663C8.03005 15.3663 8.05828 15.3568 8.0815 15.3375L11.1746 12.7793C13.1048 11.1825 14.1251 9.061 14.1251 6.64446V3.51193C14.1251 3.44297 14.0691 3.38693 14.0001 3.38693H14ZM13 6.6444C13 8.7444 12.1447 10.5167 10.4577 11.9121L7.99998 13.9452L5.54224 11.9121C3.85531 10.5167 2.99998 8.7444 2.99998 6.6444V4.45093C5.09318 4.19369 6.78118 3.16317 7.99998 2.11773C9.21872 3.16325 10.9068 4.19346 13 4.45093V6.6444Z"
      fill="#7DF9FF"
    />
    <path
      d="M5.00004 8.24972C5.00004 9.90399 6.34578 11.2497 8.00004 11.2497C9.65431 11.2497 11 9.90399 11 8.24972C11 7.79071 10.8935 7.35748 10.7085 6.96822L8.096 9.65141C8.04449 9.70417 7.95903 9.70142 7.91127 9.64516L6.375 7.8377C6.33276 7.78822 6.33573 7.71447 6.38177 7.66843L6.68328 7.36693C6.72276 7.32745 6.78401 7.3189 6.83276 7.3462L8 7.99974L10.2483 6.27028C9.698 5.64626 8.8952 5.24976 8 5.24976C6.34573 5.24976 5 6.59549 5 8.24976L5.00004 8.24972Z"
      fill="#7DF9FF"
    />
  </svg>
);

export default ShieldIcon;
