import { createSlice } from "@reduxjs/toolkit";

type CoffeeReadingState = {
  savedReading: {
    distant_future_content: string;
    distant_future_image: string;
    distant_future_title: string;
    near_future_content: string;
    near_future_image: string;
    near_future_title: string;
    present_content: string;
    present_image: string;
    present_title: string;
  };
};

const initialState: CoffeeReadingState = {
  savedReading: {
    distant_future_content: "",
    distant_future_image: "",
    distant_future_title: "",
    near_future_content: "",
    near_future_image: "",
    near_future_title: "",
    present_content: "",
    present_image: "",
    present_title: "",
  },
};

export const coffeeReadingSlice = createSlice({
  name: "coffeeReading",
  initialState,
  reducers: {
    setCoffeeReadingData: (state, action) => {
      state.savedReading = {
        ...action.payload.astrologicData.prediction.message,
      };
    },
  },
});

export const { setCoffeeReadingData } = coffeeReadingSlice.actions;

export default coffeeReadingSlice.reducer;
