import React, { useEffect, useState } from "react";
import styles from "./TwelveSignsPage.module.scss";
import HeaderWithBack from "components/headers/HeaderWithBack";
import DefaultButton from "components/buttons/DefaultButton";
import Layout from "components/Layout/Layout";
import { useAppSelector } from "../../store";
import ZodiacCard from "./components/ZodiacCard/ZodiacCard";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Zodiac } from "../../store/slices/compatibility";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const TwelveSignsPage = () => {
  const { zodiacsArray } = useAppSelector((state) => state.compatibility);
  const navigate = useNavigate();

  const [signs, setSigns] = useState(
    zodiacsArray.map((zodiac) => ({ ...zodiac })),
  );

  useEffect(() => {
    ReactPixel.track("Twelve Signs Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/twelve-signs",
      title: "Twelve Signs Page",
    });
  }, []);

  const [selectedSign, setSelectedSign] = useState<Zodiac>({
    id: 0,
    title: "",
    icon: "",
    isSelected: false,
    isUserZodiac: false,
    iconUrl: "",
  });

  const toggleSigns = (chosenZodiacId: number) => {
    const newArray = [...signs];
    const selectedZodiac = newArray.find(
      (zodiac: any) => zodiac.id === chosenZodiacId,
    );
    if (selectedZodiac) {
      newArray.forEach((zodiac) => (zodiac.isSelected = false));
      selectedZodiac.isSelected = true;
      setSelectedSign({ ...selectedZodiac });
    }
    setSigns(newArray);
  };

  const goToReport = () =>
    navigate("/best-matches-report", { state: { sign: { ...selectedSign } } });

  return (
    <Layout>
      <div className="container padding-b40">
        <div className="position-relative height100">
          <HeaderWithBack />
          <h1 className="t-align-l">
            Best <span className="yellow-text">Matches</span>
          </h1>
          <div className="mt24">
            <h2>
              <span className="yellow-text">for</span> 12 Signs
            </h2>
            <div className={classNames(styles.iconsContainer, "mt24")}>
              {signs.map((item) => (
                <ZodiacCard
                  isUserZodiac={item.isUserZodiac}
                  onClick={toggleSigns}
                  isSelected={item.isSelected}
                  key={item.id}
                  title={item.title}
                  id={item.id}
                  iconUrl={item.iconUrl}
                />
              ))}
            </div>
          </div>
          <div className={"text bottom-container-absolute"}>
            <DefaultButton
              text="See Matches"
              type="button"
              onClick={goToReport}
              isDisabled={!selectedSign.title}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TwelveSignsPage;
