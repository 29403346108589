import { useState } from "react";

type CameraFacingMode = "environment" | "user";

export const useCamera = () => {
  const [videoDem, handleVideoDem] = useState<{ w: number; h: number }>({
    w: 0,
    h: 0,
  });
  const [cameraFacingMode, handleCameraFacingMode] =
    useState<CameraFacingMode>("environment");
  const [imageData, handleImageData] = useState("");
  let video: HTMLVideoElement;
  let canvas: HTMLCanvasElement | any;

  const startCamera = async () => {
    try {
      video = document.getElementsByTagName("video")[0];
      canvas = document.getElementById("camera-canvas");
      const constraint = {
        video: {
          width: { ideal: 4096 },
          height: { ideal: 2160 },
          facingMode: cameraFacingMode,
        },
        audio: false,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraint);

      video.setAttribute("playsinline", "true");
      video.srcObject = stream;

      video.onloadedmetadata = () => {
        const { clientLeft, clientTop, videoWidth, videoHeight } = video;
        handleVideoDem({ w: videoWidth, h: videoHeight });
        canvas.style.position = "absolute";
        canvas.style.left = clientLeft.toString();
        canvas.style.top = clientTop.toString();
        canvas.setAttribute("width", videoWidth.toString());
        canvas.setAttribute("height", videoHeight.toString());
        video.play();
      };
    } catch (e) {
      console.error("Error starting camera:", e);
    }
  };

  const switchCameraFacingMode = () => {
    handleCameraFacingMode((old) =>
      old === "environment" ? "user" : "environment",
    );
  };

  const captureImage = async (): Promise<void> => {
    try {
      const video: HTMLVideoElement = document.getElementsByTagName("video")[0];
      const canvas: HTMLCanvasElement | any =
        document.getElementById("camera-canvas");
      const context = canvas.getContext("2d");
      context?.drawImage(video, 0, 0, videoDem.w, videoDem.h);
      const imageData1 = canvas.toDataURL("image/png", 1.0);
      handleImageData(imageData1);
      if (video.srcObject)
        if ("getTracks" in video.srcObject) {
          video.srcObject.getTracks().forEach((track) => track.stop());
        }
    } catch (e) {
      console.log(e);
      alert("Error in Capturing Image: " + e);
    }
  };

  const stopCamera = () => {
    try {
      const video: HTMLVideoElement = document.getElementsByTagName("video")[0];
      video.pause();
      if (video.srcObject) {
        if ("getTracks" in video.srcObject) {
          video.srcObject.getTracks().forEach((track) => track.stop());
        }
        video.srcObject = null;
      }
      video.src = "";
    } catch (error) {
      console.error("Error stopping camera:", error);
    }
  };

  return {
    cameraFacingMode,
    switchCameraFacingMode,
    imageData,
    captureImage,
    startCamera,
    stopCamera,
  };
};
