import React from "react";
const Female = ({ isSelected }: { isSelected: boolean }) => (
  <svg
    width="27"
    height="38"
    viewBox="0 0 27 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85324 6.42038C3.02733 10.2463 3.02733 16.4496 6.85324 20.2758C10.6791 24.1017 16.8825 24.1017 20.7087 20.2758C24.5346 16.4496 24.5346 10.2466 20.7087 6.42038C16.8828 2.59419 10.6794 2.59447 6.85324 6.42038ZM5.78748 5.35462C1.37292 9.76917 1.37292 16.9269 5.78748 21.3415C7.80302 23.357 10.3903 24.4524 13.0272 24.6276V29.0136H8.13063C7.71441 29.0136 7.37696 29.351 7.37696 29.7673C7.37696 30.1834 7.71441 30.5208 8.13063 30.5208H13.0272V35.3103C13.0272 35.7265 13.3647 36.0639 13.7809 36.0639C14.1971 36.0639 14.5346 35.7265 14.5346 35.3103V30.5208H19.4349C19.8511 30.5208 20.1886 30.1834 20.1886 29.7673C20.1886 29.351 19.8511 29.0136 19.4349 29.0136H14.5346V24.6276C17.1715 24.4525 19.7587 23.3571 21.7743 21.3415C26.1889 16.9269 26.1889 9.76917 21.7743 5.35462C17.3598 0.939778 10.202 0.939778 5.78748 5.35462Z"
      fill={isSelected ? "#FFD700" : "#7DF9FF"}
    />
  </svg>
);

export default Female;
