import React from "react";

const LookingForLoveIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.782 13.8432C20.782 13.8432 18.5036 11.9232 16.814 14.5344C15.1244 11.8976 12.846 13.8432 12.846 13.8432C11.2589 15.2768 12.846 17.1968 12.846 17.1968C13.4349 18.2464 16.43 21.088 16.7884 21.4464L16.814 21.472C17.07 21.216 20.1676 18.2976 20.782 17.1968C20.782 17.1969 22.3692 15.2769 20.782 13.8432L20.782 13.8432Z"
      fill="#9B7BD0"
    />
    <path
      d="M16.8128 5.984C11.0016 5.984 6.26562 10.72 6.26562 16.5568C6.26562 22.368 11.0016 27.104 16.8128 27.104C18.7072 27.104 20.576 26.592 22.24 25.5936L22.624 25.3632L27.4624 30.2016C27.7696 30.5344 28.2048 30.688 28.6656 30.688C29.1264 30.688 29.536 30.5088 29.8687 30.2016C30.1759 29.8944 30.3552 29.4591 30.3552 28.9984C30.3552 28.5376 30.176 28.128 29.8687 27.7952L25.1327 23.0848L25.5423 22.4704C26.7199 20.7296 27.3599 18.6816 27.3599 16.5568C27.3855 10.72 22.6495 5.98402 16.8127 5.98402L16.8128 5.984ZM16.8128 24.2368C12.5632 24.2368 9.10722 20.7808 9.10722 16.5312C9.10722 12.2816 12.5632 8.8256 16.8128 8.8256C21.0624 8.8256 24.5184 12.2816 24.5184 16.5312C24.5184 20.8064 21.0624 24.2368 16.8128 24.2368Z"
      fill="#5A8C8D"
    />
  </svg>
);

export default LookingForLoveIcon;
