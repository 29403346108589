import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import Logo from "assets/svg/logoIcon.svg";

// eslint-disable-next-line react/display-name
const HeaderWithHome = memo(() => {
  const { openedTab } = useAppSelector((state) => state.tabNavigation);

  const navigate = useNavigate();
  const goHome = () => {
    if (openedTab === 1) {
      navigate("/home");
    }
    if (openedTab === 2) {
      navigate("/reading");
    }
    if (openedTab === 3) {
      navigate("/compatibility");
    }
    if (openedTab === 4) {
      navigate("/birth-chart");
    }
  };

  return (
    <div className="flex i-align-s justify-content-space-between position-relative mb24">
      <div />
      <div className="position-absolute absolute-left0 absolute-right0">
        <img src={Logo} alt="" />
      </div>
      <button
        className="linkBtn font-s16 fMontserrat font-w500 flex i-align-c"
        onClick={goHome}
      >
        Home
      </button>
    </div>
  );
});

export default HeaderWithHome;
