import React, { useEffect, useRef, useState } from "react";
import DefaultButton from "components/buttons/DefaultButton";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { dates, getZodiacSign } from "utils/defaults";
import { useAppDispatch } from "store";
import { createDateFormat } from "utils/dates";
import { shortMonthMap } from "constants/date";
import { setPartnerBirthday } from "store/slices/partnerRegistration";
import { isLeapYear } from "utils/validations";

const BirthDaySelect = () => {
  const flickingRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [daysCount, setDaysCount] = useState(31);
  const [dayIndex, setDayIndex] = useState(0);

  const onSelectBirthday = () => {
    const date = createDateFormat(month, day, year);

    const zodiac = getZodiacSign(shortMonthMap[month], +day);
    dispatch(
      setPartnerBirthday({
        birthday: date,
        zodiac: zodiac.data.zodiacIconUrl,
        zodiacName: zodiac.type,
      }),
    );
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (panel.element.textContent.length === 3) {
          setMonth(panel.element.textContent?.toLowerCase());
        } else if (panel.element.textContent.length <= 2) {
          setDay(panel.element.textContent);
          setDayIndex(0);
        } else {
          if (isLeapYear(panel.element.textContent) && month === "feb") {
            if (flickingRef.current) flickingRef.current.moveTo(0);
          }
          setYear(panel.element.textContent);
        }
      }
    });
  };

  useEffect(() => {
    if (
      ["apr", "jan", "mar", "may", "jul", "aug", "oct", "dec"].some(
        (el) => el === month,
      )
    ) {
      setDaysCount(31);
    } else if (month === "feb") {
      setDaysCount(isLeapYear(+year) ? 29 : 28);
    } else {
      setDaysCount(30);
    }
    if (flickingRef.current) flickingRef.current.moveTo(0);
  }, [month]);

  return (
    <>
      <h2>
        When’s your partner <span className="yellow-text">Birthday?</span>
      </h2>
      <p className="fRoboto font-s16 text-color-silver font-w400 mt8">
        Adding your partner birthday enriches astrological insights for accuracy
        and depth.
      </p>
      <div className="date-demo-wrapper has-text-white position-relative flex">
        <Flicking
          className="date-flicking"
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
          defaultIndex={4}
        >
          <div className="date-panel">JAN</div>
          <div className="date-panel">FEB</div>
          <div className="date-panel">MAR</div>
          <div className="date-panel">APR</div>
          <div className="date-panel">MAY</div>
          <div className="date-panel">JUN</div>
          <div className="date-panel">JUL</div>
          <div className="date-panel">AUG</div>
          <div className="date-panel">SEP</div>
          <div className="date-panel">OCT</div>
          <div className="date-panel">NVM</div>
          <div className="date-panel">DEC</div>
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <Flicking
          ref={flickingRef}
          defaultIndex={dayIndex}
          className="date-flicking"
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
          {dates(1, daysCount)}
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <Flicking
          defaultIndex={50}
          className="date-flicking"
          horizontal={false}
          onReady={updateTransform}
          onMove={updateTransform}
        >
          {dates(1950, 75)}
          <ViewportSlot>
            <div className="shadow-top"></div>
            <div className="shadow-bottom"></div>
          </ViewportSlot>
        </Flicking>
        <div className="date-panel-border" />
      </div>
      <div className="bottom-container-absolute">
        <DefaultButton text={"Next"} type="button" onClick={onSelectBirthday} />
      </div>
    </>
  );
};

export default BirthDaySelect;
