import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import animation from "./animation.json";

const HandAnimation = () => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Lottie
      animationData={animation}
      loop
      style={{ width: innerHeight + 100 < 730 ? "45%" : "60%", height: "45vh" }}
    />
  );
};

export default HandAnimation;
